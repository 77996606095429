import React from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs from './columnDefs'
import { setupGrid } from '../../utils/slice'


const Listing: React.FC<any> = ({
	onRowClick,
	rowData,
	name
}) => {
	const LocationColumn: any = {
		field: 'location_of_plant',
		headerName: `Location Of ${name}`,
		filter: 'agTextColumnFilter',
		tooltipValueGetter: (params: any) => params.data.location_of_plant,
	}

	const newColumnDefs = [
		...columnDefs.slice(0, 2),
		LocationColumn,
		...columnDefs.slice(2)
	];

	return (
		<>
			<AgGridWrapper
				type="serverSide"
				rowData={rowData}
				columnDefs={newColumnDefs}
				onRowClicked={onRowClick}
				onGridReadyWithDispatch={setupGrid}
				rowSelection={"single"}
			/>
		</>
	)
}

export default Listing
