import { RuleObject } from "antd/es/form";

const defaultLabel = `It`;

export const regexPatterns = {
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#!$%^&+=])(?=.*[0-9]).*$/,
	mobile: /^[0-9]+$/,
	twoDecimal: /^\d{1,4}(\.\d{0,2})?$/,
	twoDecimalWithTenDigit: /^\d{1,10}(\.\d{0,2})?$/,
	numeric: /^[0-9\b]+$/,
	phone: /^\d{4} \d{3} \d{3}$/,
	mobilevalidation: /^[0-9]{10}$/,
	firstName: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
	lastName: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
	PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
	AADHAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
	numeric_alpha:/^[0-9a-zA-Z]/,
	dateReg : /^\d{4}([./-])\d{2}\1\d{2}$/
};

export const validations = {
	required: {
		text: (field = defaultLabel): RuleObject => {
			return {
				required: true,
				whitespace: true,
				message: `${field} is required.`,
			};
		},
		select: (field = defaultLabel): RuleObject => {
			return { required: true, message: `Please select ${field}.` };
		},
		other: (field = defaultLabel): RuleObject => {
			return { required: true, message: `${field} is required.` };
		},
	},
	min: {
		text: (min = 1, field = defaultLabel): RuleObject => {
			return {
				min,
				message: `${field} should contain minimum ${min} characters.`,
			};
		},
		select: (field = defaultLabel, min: number): RuleObject => {
			return {
				min,
				message: `Please select minimum ${min} ${field}.`,
			};
		},
		number: (min: number, field = defaultLabel): RuleObject => {
			return {
				min,
				message: `${field} should contain minimum ${min} digits.`,
			};
		},
	},
	max: {
		text: (max = 6, field = defaultLabel): RuleObject => {
			return {
				max,
				message: `${field} should contain maximum ${max} characters.`,
			};
		},
		select: (field = defaultLabel, max: number): RuleObject => {
			return {
				max,
				message: `Please select maximum ${max} ${field}.`,
			};
		},
		number: (max = 20, field = defaultLabel): RuleObject => {
			return {
				max,
				message: `${field} should contain maximum ${max} digits.`,
			};
		},
	},
	pattern: {
		firstName: {
			pattern: regexPatterns.firstName,
			message: "First letter must be capital",
		},
		lastName: {
			pattern: regexPatterns.lastName,
			message: "First letter must be capital",
		},
		phone: {
			pattern: regexPatterns.mobilevalidation,
			message: "Phone should contain only 10 numbers.",
		},
		pan: {
			pattern: regexPatterns.PAN,
			message: "Invalid PAN characters"
		},
		aadhar: {
			pattern: regexPatterns.AADHAR,
			message: "Invalid aadhar characters"
		},
		password: (): RuleObject => {
			return {
				pattern: regexPatterns.password,
				message: `It should contain at least an uppercase letter, a lowercase letter, a number, and a special character.
				It should contain minimum 8 characters.`,
			};
		},
		numeric: (): RuleObject => {
			return {
				pattern: regexPatterns.numeric,
				message: "Allow numeric values only",
			};
		},
		decimal: (number: number): RuleObject => {
			return {
				pattern: regexPatterns.twoDecimal,
				message: `Maximum 4 digits & decimal value up to ${number} digits.`,
			};
		},
		tenDigitWithDecimal:(number: number):RuleObject=>{
			return{
				pattern:regexPatterns.twoDecimalWithTenDigit,
				message: `Maximum 10 digits & decimal value up to ${number} digits.`,
			}
		},
		other: (field = defaultLabel): RuleObject => {
			return {
				pattern: regexPatterns.firstName,
				message: `Please enter a valid ${field}.`,
			};
		},
	},
	email: (field = defaultLabel): RuleObject => {
		return {
			type: "email",
			message: `${field} is invalid email.`,
		};
	},
	minMax:(min=1,max=100): RuleObject => {
		return {
			min:min,
			max:max,
			message:`The input must be a minimum of ${min} and a maximum of ${max}.`,
		}
	}
};
