import { createSlice } from "@reduxjs/toolkit";
import type { Action, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../config/Config";
import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import { Dispatch } from "react";
interface InitialState {
	[x: string]: any;
	isLoading: boolean;
	rowData: any;
	read: any;
	dynamicFieldType: any;
	departmentList: any;
	selectedProjectData: any;
	departmentTypeList: any;
	update: any;
	perPage: any;
	agGrid: any;
	selected: any;
	userPlantList: any;
	userPaverList: any;
	roadPlanningWardList: any;
}

const initialState = {
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	isLoading: false,
	rowData: null,
	read: null,
	agGrid: null,
	dynamicFieldType: null,
	departmentList: null,
	selectedProjectData: null,
	update: null,
	departmentTypeList: null,
	selected: null,
	selectedData: null,
	userPlantList: null,
	userPaverList: null,
	roadPlanningWardList: null,
} as InitialState;

const ProjectSlice = createSlice({
	name: "PROJECT",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setDynamicFieldType: (state, action: PayloadAction<any>) => {
			state.dynamicFieldType = action?.payload;
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setUpdate: (state, action: PayloadAction<any>) => {
			state.update = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action.payload;
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload;
		},
		setDepartmentTypeList: (state, action: PayloadAction<any>) => {
			state.departmentTypeList = action?.payload;
		},
		setDepartmentList: (state, action: PayloadAction<any>) => {
			state.departmentList = action?.payload;
		},
		setSelectedProjectData: (state, action: PayloadAction<any>) => {
			state.selectedProjectData = action?.payload;
		},
		setSelectedData: (state, action: PayloadAction<any>) => {
			state.selectedData = action?.payload;
		},
		setUserPlanList: (state, action: PayloadAction<any>) => {
			state.userPlantList = action?.payload;
		},
		setUserPaverList: (state, action: PayloadAction<any>) => {
			state.userPaverList = action?.payload;
		},
		setRoadPlanningWardList: (state, action: PayloadAction<any>) => {
			state.roadPlanningWardList = action?.payload;
		},
	},
});

export const {
	start,
	success,
	failure,
	setDynamicFieldType,
	setRowData,
	setRead,
	setUpdate,
	setDepartmentList,
	setDepartmentTypeList,
	setGrid,
	setPerPage,
	setSelectedProjectData,
	setSelectedData,
	setUserPlanList,
	setUserPaverList,
	setRoadPlanningWardList
} = ProjectSlice.actions;

export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().PROJECT.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				dispatch(setPerPage(getState().PROJECT.perPage));
				const dataSource = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
			} catch (error: any) {
				//
			}
		};

const changeFilterAndSort = (params: any, dispatch?: any) => {
	dispatch(setSelectedData(null))
	params;
};

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
	return await api.post(apiUrls.list, payload).then(({ data }) => {
		return data;
	});
};

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch);
			const payload = getServerListPayload(params);
			dispatch(setSelectedData(null))
			fetchList(payload).then((data) => {
				params.success({ rowData: data?.rows, rowCount: data?.count });
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			});
		},
	};
};

export const getDynamicFieldType =
	(payload: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.dynamicFieldType, payload);
				dispatch(setDynamicFieldType(response.data.data));
				dispatch(success());
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure());
				return Promise.reject(error.data);
			}
		};

export const getDepartmentList = (): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.departmentList);
		dispatch(setDepartmentList(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const getDepartmentTypeList =
	(departmentId: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.departmentTypeList(departmentId));
				dispatch(setDepartmentTypeList(response.data.data));
				dispatch(success());
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure());
				return Promise.reject(error.data);
			}
		};


export const createRecord =
	(action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create, action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().PROJECT.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const createDynamicRecord =
	(action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.dynamicFieldSave, action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().PROJECT.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const updateRecord =
	(id: number, action: any, step: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(step == 0 ? apiUrls.update(id) : apiUrls.dynamicFieldUpdate, action);
				dispatch(setUpdate(response.data.data));
				dispatch(setupGrid(getState().PROJECT.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const deleteRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.delete(apiUrls.delete(id), action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().PROJECT.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const readRecord =
	(id: any, action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.read(id), action);
				dispatch(setRead(response.data.data));
				dispatch(setSelectedProjectData(response.data.data))
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const changeStatus =
	(id: number, status: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				const response = await api.post(apiUrls.changeStatus(id), {
					status,
				});
				dispatch(setupGrid(getState().PROJECT.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const imageDelete =
	(id?: any): AppThunk<any> =>
		async () => {
			try {
				const response = await api.post(
					apiUrls.removeImage, { "id": id }
				);
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const createPlanning =
	(action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.createPlanning, action);
				dispatch(success(response.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};
export const readPlanning =
	(id: any, action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.get(apiUrls.readPlanning(id), action);
				dispatch(setRead(response.data.data));
				dispatch(setSelectedProjectData(response.data.data))
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
export const getUserList = (): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.post(apiUrls.userList);
		dispatch(setUserPlanList(response.data.data));
		dispatch(setUserPaverList(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const updatePlanning =
	(id: number, data: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.updatePlanning(id), data);
				dispatch(setUpdate(response.data.data));
				dispatch(setupGrid(getState().PROJECT.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const getRoadPlanningWardList = (id: any): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.wardList(id));
		dispatch(setRoadPlanningWardList(response.data.wards));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
const ProjectSliceReducer = ProjectSlice.reducer;
export default ProjectSliceReducer;
