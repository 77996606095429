import React, { useEffect } from 'react'
import { FormInstance } from 'antd'
import { InputBox } from '../../../../../../../components/AntdAddons'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../../store/app'
import { getFineAggregateType } from '../utils/slice'
import { regexPatterns } from '../../../../../../../config/validations/validations'
import { blockInvalidChar } from '../../../../../../../config/Global'
interface TableComponentProps {
	form: FormInstance
}
const TableComponent: React.FC<TableComponentProps> = ({ form }) => {
	const dispatch: AppDispatch = useDispatch()
	const FINE_AGGREGATE = useSelector((state: RootState) => state.FINE_AGGREGATE)

	useEffect(() => {
		dispatch(getFineAggregateType(4))
	}, [])

	const validateValue = (rule?: any, value?: any, callback?: any) => {
		if (value == 0) {
			callback('Minimum 1 test required')
		} else if (value?.toString().length > 4) {
			callback('Maximum 4 digit allowed')
		} else {
			callback()
		}
	}
	useEffect(() => {
		location.pathname.includes("/add-fine-aggregate") && (
			setTimeout(() => {
				FINE_AGGREGATE?.testTypeList &&
					FINE_AGGREGATE?.testTypeList?.map((data?: any) => {
						form.getFieldsValue()
						form.setFieldValue(
							['test_types', data.id.toString(), 'mst_test_type_id'],
							data.id
						)
						form.setFieldValue(
							['test_types', data.id.toString(), 'test_type_value'],
							1
						)
					})
			}), 500)
	}, [FINE_AGGREGATE.testTypeList])

	return (
		<>
			<div className="testTable">
				<h2>No. of Test Required As Per QAP</h2>
				<div className="table-wrapper">
					<table>
						<thead>
							<tr>
								{FINE_AGGREGATE.testTypeList &&
									FINE_AGGREGATE.testTypeList.map((field: any) => {
										return (
											<td width={300} key={field.id}>
												{field.name}
											</td>
										)
									})}
							</tr>
						</thead>
						<tbody>
							<tr>
								{FINE_AGGREGATE?.testTypeList &&
									FINE_AGGREGATE?.testTypeList.map(
										(field: any) => {
											return (
												<td key={field.id}>
													<InputBox.Text
														name={[
															'test_types',
															field.id.toString(),
															'mst_test_type_id',
														]}
														initialValue={field.id}
														hidden
													/>
													<InputBox.Text
														min={1}
														type="number"
														onKeyDown={blockInvalidChar}
														name={[
															'test_types',
															field.id.toString(),
															'test_type_value',
														]}
														disabled={
															location.pathname.includes(
																'/view-fine-aggregate'
															)
																? true
																: false
														}
														rules={[
															{
																validator:
																	validateValue,
															},
															{
																required: true,
																message:
																	'It is required.',
															},
															{
																pattern:
																	regexPatterns.numeric,
																message:
																	'Decimal digits not allowed',
															},
														]}
													/>
												</td>
											)
										}
									)}
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
export default TableComponent
