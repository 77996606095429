import React from 'react'
import AgGridWrapper from '../../../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs from './columnDefs'
import { setupGrid } from '../utils/slice'

const Listing: React.FC<any> = ({
	onRowClick,
	rowData,
}) => {

	return (
		<>
			<AgGridWrapper
				type="serverSide"
				rowData={rowData}
				columnDefs={columnDefs}
				onRowClicked={onRowClick}
				onGridReadyWithDispatch={setupGrid}
				rowSelection={"single"}
			/>
		</>
	)
}

export default Listing
