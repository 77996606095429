import React, { useEffect, useState } from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs, { columnDefsHotMix, columnDefsHotMixPlanning } from './columnDefs'
import { ColDef } from 'ag-grid-community'
import { setGrid } from '../../utils/slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/app'

const Listing: React.FC<any> = ({
	rowData,
	netCount,
	reportId
}) => {

	const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any>([]);
	const { ROAD_REPORT } = useSelector((state: RootState) => state)
	useEffect(() => {
		// Set the pinned bottom row data
		setPinnedBottomRowData([
			{
				id:'',
				zone_id:'',
				ward_name:'',
				date:[],
				net_weight: netCount,
			},
		]);
	}, [netCount]);

	const paverPatchWorkNameColumn: ColDef = {
		field: 'net_weight',
		headerName: 'Total Production',
		filter: 'agTextColumnFilter',
		tooltipValueGetter: (params: any) => params.data.net_weight,
		cellRenderer: (params:any) => {
			// If this is the total row, display it as "Total: [value]"
			if (params?.node?.rowPinned) {
			  return <strong>Total : {params?.value?.toLocaleString()}</strong>;
			}
			// Otherwise, just display the number
			return params?.value?.toLocaleString();
		  },
	}
	const cols=reportId==2?columnDefsHotMix:columnDefs
	const final_column=[...cols,paverPatchWorkNameColumn]
	return (
		<>
			<AgGridWrapper
				rowData={rowData}
				columnDefs={reportId==3?columnDefsHotMixPlanning:final_column}
				onGridReadyWithDispatch={setGrid}
				rowSelection='single'
				pinnedBottomRowData={pinnedBottomRowData}
				paginationPageSize={ROAD_REPORT?.perPageSize}
				cacheBlockSize={ROAD_REPORT?.perPageSize}
			/>
		</>
	)
}

export default Listing
