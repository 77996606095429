import React, { useEffect, useState } from "react";
import ToolBar from "../../../../../../../../components/ToolBar";
import { ConcreteAdmixtureReportBreadcrumb } from "../../../../../../../../config/BreadcrumbConfig";
import ContentBox from "../../../../../../../../components/ContentBox/ContentBox";
import Listing from "./Listing/Listing";
import { Button } from "antd";
import { ReactComponent as PlusIcon } from "../../../../../../../../assets/images/icon/plusIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../../store/app";
import { deleteReport, getDateRangeData, setConcreteReport, setDateRange, setLocation, setPerPage, setProjectId, setReadReport, setReportId } from "../../utils/slice";
import RecordPerPage from "../../../../../../../../components/ToolBar/Dropdown/RecordPerPage";
import SVGIcon from "../../../../../../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../../../../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../../../../../../config/Global";
import PageSpinner from "../../../../../../../../components/PageSpinner/PageSpinner";
import DeleteModal from "../../../../../../../../components/Modals/DeleteModal";
import { InputBox } from "../../../../../../../../components/AntdAddons";
import { RangePickerProps } from "antd/es/date-picker";
import { CONSTANT } from "../../../../../../../../config/Constant";
import dayjs from "dayjs";
import { ReactComponent as RestoreIcon } from "../../../../../../../../assets/images/icon/refresh.svg";
import { setTab } from "../../../../../utils/slice";

const moduleName = "Report"

const ConcreteMixtureReportComponent: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const { CONCRETE_ADMIXTURE, AUTH } = useSelector((state: RootState) => state);
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate();
	const { concreteAdid }: { projectId?: any; concreteAdid?: any } =
		useParams();
	const [deleteUser, setDeleteUser] = useState(false)
	const [deleteData, setDeleteData] = useState()
	const [selectedDate, setSelectedDate] = useState(null)

	const handleViewClick = () => {
		dispatch(setReadReport(null))
		concreteAdid ? navigate(
			`/projects/raw-material/${concreteAdid}/${CONCRETE_ADMIXTURE?.concreteReport}/view-concrete-mixture-report`
		) : navigate(
			`/projects/raw-material/${CONCRETE_ADMIXTURE?.concreteReport}/view-concrete-mixture-report`
		);
	};
	const onRowClick = (data: any) => {
		dispatch(setConcreteReport(data?.data?.id))
		setDeleteData(data?.data)

	}
	const handleClick = () => {
		navigate(
			`/projects/raw-material/${concreteAdid}/add-concrete-mixture-report`
		);
	};
	useEffect(() => {
		dispatch(setConcreteReport(null))
		dispatch(setReportId(concreteAdid ? { id: concreteAdid } : { project_id: localStorage.getItem('projectId') }))
		dispatch(setLocation("/concrete-mixture-report"))
		dispatch(setProjectId(localStorage.getItem("projectId")));
		dispatch(setTab(4))
	}, []);

	const closeDeleteModal = () => {
		setDeleteUser(false)
		dispatch(setConcreteReport(null))
	}
	const onChangeDateRange: RangePickerProps["onChange"] = (date: any) => {
		setSelectedDate(date)
		date?dispatch(setDateRange({ start_date: dayjs(date[0]).format(CONSTANT.POST_DATE_FORMAT), end_date: dayjs(date[1]).format(CONSTANT.POST_DATE_FORMAT) })):
		dispatch(setDateRange({start_date:null,end_date:null}))
		dispatch(getDateRangeData())
	}
	const resetFilter = () => {
		dispatch(setDateRange(null))
		setSelectedDate(null)
		dispatch(getDateRangeData())
	}
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && <>
					<ToolBar
						breadcrumbs={ConcreteAdmixtureReportBreadcrumb(concreteAdid)}
					>
						<div className="listBtn">
							{concreteAdid && checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) && <Button className="secondaryBtn" onClick={handleClick}>
								Add
								<span className="btnIcon">
									<PlusIcon />
								</span>
							</Button>}
							<RecordPerPage
								defaultValue={CONCRETE_ADMIXTURE?.reportPerPageSize + " Per Page "}
								onChange={(perPageSize: number) =>
									dispatch(setPerPage(perPageSize))} />
						</div>
					</ToolBar>
					<ContentBox>
						<div className="listWrapper">
							<div className="auditListWrapper reportList">
								<div className="btnList">

									<div className="listRangePicker">
										<InputBox.DateRangePicker value={selectedDate} onChange={onChangeDateRange} />
									</div>
									<Button onClick={resetFilter}>
										<RestoreIcon height={22} width={22} />
									</Button>
								</div>
								<div className="auditList">

									<Listing
										rowData={CONCRETE_ADMIXTURE?.reportRowData}
										moduleName={moduleName}
										onRowClick={onRowClick}
									/>
								</div>

								<DeleteModal
									title={"concrete admixture report"}
									deleteValues={deleteData}
									callApi={deleteReport}
									close={closeDeleteModal}
									open={deleteUser}
								/>
							</div>
						</div>
						<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow borderActionRow" : ""}>
							<div className="actionBtn">
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`) &&
									<Button
										onClick={handleViewClick}
										disabled={CONCRETE_ADMIXTURE?.concreteReport === null}
										className={CONCRETE_ADMIXTURE?.concreteReport ? "activeBtn" : "disableBtn"}
									>
										View
										<SVGIcon icon="view" />
									</Button>}
								{
									checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
									<Button
										onClick={() => setDeleteUser(true)}
										disabled={CONCRETE_ADMIXTURE?.concreteReport == null ? true : false}
										className={CONCRETE_ADMIXTURE?.concreteReport ? "deleteBtn" : "disableDelBtn disableBtn"}
									>
										Delete
										<SVGIcon icon="delete" />
									</Button>}
							</div>
						</div>
					</ContentBox></>}
		</>
	);
};

export default ConcreteMixtureReportComponent;
