import { ColDef, ValueFormatterParams } from "ag-grid-community";
import {
	idColumn,
} from "../../../../../utils/commonColumns";
import { renderNA } from "../../../../../utils/commonFunctions";
import SwitchCellRenderer from "../../../../../components/Switch";

const emailColumn: ColDef = {
	field: "email",
	headerName: "Email",
	filter: "agTextColumnFilter",
	cellRenderer: (props: any) => {
		return renderNA(props.data.email);
	},
	tooltipValueGetter: (params: any) => params.data.email,
};

const mobileNumberColumn: ColDef = {
	field: "mobile_no",
	headerName: "Contact Number",
	filter: "agTextColumnFilter",
	cellRenderer: (props: any) => {
		return renderNA(props.data.mobile_no);
	},
	tooltipValueGetter: (params: any) => params.data.mobile_no,
};

const statusColumn: ColDef = {
	field: "status",
	headerName: "Status",
	sortable: false,
	width: 100,
	minWidth: 100,
	cellRenderer: SwitchCellRenderer,
	filter: "agSetColumnFilter",
	filterParams: {
		values: [0, 1],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Active" && value === 1) || (filter === "InActive" && value === 0)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Active" : params.value === 0 ? "InActive" : params.value;
		},
	},
	tooltipValueGetter: (params: any) => {
		// Add tooltip content for the status column
		return params?.data?.role_name ? params.data.status === 1 ? "Active" : "Inactive" : "Please assign role"
	},
};
const firstNameColumn: ColDef = {
	field: "first_name",
	headerName: "First Name",
	sortable: false,
	filter: "agTextColumnFilter",
	width: 100,
	minWidth: 100,
	resizable: true,
	tooltipValueGetter: (params: any) => params.data.first_name,
};
const LastNameColumn: ColDef = {
	field: "last_name",
	headerName: "Last Name",
	sortable: false,
	filter: "agTextColumnFilter",
	width: 100,
	minWidth: 100,
	tooltipValueGetter: (params: any) => params.data.last_name,
};
const RoleNameColumn: ColDef = {
	field: "role_name",
	headerName: "Role Name",
	sortable: false,
	filter: "agTextColumnFilter",
	width: 100,
	minWidth: 100,
	cellRenderer: (params: any) => {
		return params?.data?.role_name ? params?.data?.role_name : "N/A"
	},
	tooltipValueGetter: (params: any) => params.data.role_name,
};

const ProjectNameColumn: ColDef = {
	field: "project_list",
	headerName: "Project List",
	sortable: false,
	filter: "agTextColumnFilter",
	width: 100,
	minWidth: 100,
	cellRenderer: (params: any) => {
		const pro_name = params.data?.project_list?.map((data: any) => {
			return data?.project_name + ","
		})
		return pro_name
	},
	tooltipValueGetter: (params: any) => {
		const pro_name = params.data?.project_list?.map((data: any) => {
			return data?.project_name
		})
		return pro_name
	},
};


const columnDefs: ColDef[] = [
	idColumn,
	firstNameColumn,
	LastNameColumn,
	emailColumn,
	ProjectNameColumn,
	RoleNameColumn,
	mobileNumberColumn,
	statusColumn,
];

export const archiveColumnDefs: ColDef[] = [
	idColumn,
	firstNameColumn,
	LastNameColumn,
	emailColumn,
	RoleNameColumn,
	mobileNumberColumn,
];

export default columnDefs;
