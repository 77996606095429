import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "antd";
import { InputBox } from "../../../../../components/AntdAddons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { getRoadPlanningWardList } from "../../../Project/utils/slice";
import { CONSTANT } from "../../../../../config/Constant";
import { ReactComponent as DownloadIcon } from "../../../../../assets/images/icon/file-download-svgrepo-com.svg";
import { rules } from "./rules";
import { fetchExportData, fetchTypeOfReport, searchList, setNetCount, setRowData } from "../../utils/slice";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { setTab } from "../../../CreateProjectDashboard/utils/slice";
import { export_PDF } from "../../../../../config/Global";
import { getContractorList } from "../../../Contractor/utils/slice";


const FormComponent: React.FC<any> = ({ reportId, setReportId, data, setData }) => {
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate()
	const { PROJECT, ROAD_REPORT, CONTRACTOR } = useSelector((state: RootState) => state)
	const [form] = Form.useForm()
	const [isLoading, setIsLoading] = useState(false)


	useEffect(() => {
		dispatch(setRowData([]))
		dispatch(setNetCount(null))
	}, [])

	const handleSubmit = (data: any) => {
		data.from_date = dayjs(data.from_date).format("YYYY-MM-DD")
		data.to_date = dayjs(data.to_date).format("YYYY-MM-DD")
		dispatch(searchList(data))
		setData(data)

	}

	const handleCancel = () => {
		form.resetFields();
		navigate("/projects");
		dispatch(setTab(2))
		setData(null)
	}

	const handleExport = () => {
		form.validateFields().then(() => {
			setIsLoading(true)
			data && dispatch(fetchExportData(data)).then((data: any) => {
				export_PDF(data)
				setIsLoading(false)
			}).catch(() => {
				setIsLoading(false)
			})
		}).catch((e) => {
			console.log(e)
		})
	}

	const handleDisabledDate = (current: any) => {
		const currentDate = dayjs(current).valueOf();
		return currentDate < dayjs(form.getFieldValue("from_date")).valueOf();
	}
	return (
		<>
			<div className="roadReportFormWrapper">
				<Form
					form={form}
					onFinish={handleSubmit}
				>
					<div className="formHeading">
						<h3>View Road Report</h3>
					</div>
					<div className="addReportForm">
						<div className="mixBtn export-button">
							<Button
								className="secondaryBtn"
								onClick={handleExport}
								loading={isLoading}
							>
								Export<span className="btnIcon">
									<DownloadIcon />
								</span>
							</Button>
						</div><br />
						<Row gutter={40}>
							<Col span={12}>
								<InputBox.Select
									allowClear
									className="projectList"
									label="Type of Report"
									name="typeOfReport"
									options={{
										list: ROAD_REPORT.typeOfReport,
										valueKey: "id",
										textKey: "Name",
									}}
									onFocus={() => dispatch(fetchTypeOfReport())}
									rules={rules.selectFields()}
									onChange={(e) => {
										setReportId(e)
										dispatch(setRowData([]))
										dispatch(setNetCount(null))
										setData(null)
										form.setFieldsValue({
											zone_id: "",
											ward_id: "",
											name_of_agency: "",
											from_date: null,
											to_date: null
										})
									}}
								/>
							</Col>
							{
								reportId != 2 && <Col span={12}>
									<InputBox.Select
										allowClear
										className="projectList"
										label="Select Zone"
										name="zone_id"
										options={{
											list: [
												{
													id: 1,
													value: "nz",
													name: "NZ",
												},
												{
													id: 2,
													value: "wz",
													name: "WZ",
												},
												{
													id: 3,
													value: "sz",
													name: "SZ",
												},
												{
													id: 4,
													value: "ez",
													name: "EZ",
												},
												{
													id: 5,
													value: "nwz",
													name: "NWZ",
												},
												{
													id: 6,
													value: "swz",
													name: "SWZ",
												},
												{
													id: 7,
													value: "cz",
													name: "CZ",
												},
											],
										}}
										rules={rules.selectFields()}
										onChange={() => { form.setFieldValue("ward_id", []) }}
									/>
								</Col>
							}
							{
								reportId != 2 && <Col span={12}>
									<InputBox.Select
										allowClear
										className="projectList"
										label="Select Ward"
										name="ward_id"
										onFocus={() => dispatch(getRoadPlanningWardList(form.getFieldValue("zone_id")))}
										options={{
											list: PROJECT.roadPlanningWardList,
											valueKey: "id",
											textKey: "ward_name",
										}}
									/>
								</Col>
							}
							{
								reportId == 2 && <Col span={12}>
									<InputBox.Select
										allowClear
										className="projectList"
										label="Name of Agency"
										name="contractor_id"
										onFocus={() => dispatch(getContractorList())}
										options={{
											list: CONTRACTOR.contractorList,
											valueKey: "id",
											textKey: "name",
										}}
										rules={rules.selectFields()}
									/>
								</Col>
							}
							<Col span={12}>
								<InputBox.DatePicker
									format={CONSTANT.DATE_FORMAT}
									label="From Date"
									name="from_date"
									rules={rules.dateFields()}
									onChange={() => form.setFieldValue("to_date", null)}
								/>
							</Col>
							<Col span={12}>
								<InputBox.DatePicker
									format={CONSTANT.DATE_FORMAT}
									label="To Date"
									name="to_date"
									rules={rules.dateFields()}
									disabledDate={handleDisabledDate}
								/>
							</Col>
						</Row>
						<div className="button-part">
							<Button
								className="secondaryBtn"
								htmlType="submit"
							>
								Search
							</Button>
							<Button
								className="secondaryBtn"
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Form>
			</div>
		</>
	);
};

export default FormComponent;