import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { LaboratoryBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./Components/Listing/Listing";
import { Button } from "antd";
import { deleteRecord, fetchList, refreshGrid, setLabData, setPerPageSize, setRead } from "./utils/slice";
import AddComponent from "./Components/Forms/AddComponent";
import EditComponent from "./Components/Forms/EditComponent";
import DeleteModal from "../../../components/Modals/DeleteModal";
import SVGIcon from "../../../utils/SVGIcon";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import { checkPrivileges } from "../../../config/Global";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import CreateButton from "../../../components/ToolBar/Buttons/CreateButton";
import WarningModal from "../../../components/Modals/WarningModal";

const moduleName = "Laboratory";

const Laboratories: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { LABORATORIES, AUTH } = useSelector((state: RootState) => state);
	const [addForm, setAddForm] = useState<any>(false);
	const [editForm, setEditForm] = useState<any>(false);
	const [deleteModal, setDeleteModal] = useState<any>(false)
	const [disabled, setDisabled] = useState<any>();
	const [openModal, setOpenModal] = useState(false)

	const onRowClick = (data?: any) => {
		dispatch(setLabData(data.data));
		const count_keys = Object.entries(data?.data)?.filter((value: any) => {
			if (value[0].search("count") != -1) {
				return value
			}
		});
		const result = count_keys?.every((data: any) => {
			return data[1] == 0
		})

		if (result == true) {
			setDisabled(false)
		}
		else {
			setDisabled(true)
		}
	};

	useEffect(() => {
		dispatch(fetchList());
		dispatch(setLabData(null));
	}, []);

	const handleClick = () => {
		setAddForm(true);
	};

	const closeModal = () => {
		setAddForm(false);
		setEditForm(false);
		dispatch(refreshGrid)

	};

	const closeDeleteModal = () => {
		setDeleteModal(false)
		dispatch(setLabData(null))
		dispatch(refreshGrid)
	}

	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={LaboratoryBreadcrumb}>
						<>
							{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_CREATE`) &&
								<CreateButton
									action={() => handleClick()}
									permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
								/>}
							<RecordPerPage
								defaultValue={LABORATORIES.perPageSize + " per page"}
								onChange={(perPageSize: number) => {
									dispatch(setPerPageSize(perPageSize));
								}}
							/>{" "}
						</>
					</ToolBar>
					<ContentBox>
						<AddComponent
							titleName={moduleName}
							open={addForm}
							close={closeModal}
						/>

						<EditComponent
							titleName={moduleName}
							open={editForm}
							close={closeModal}
						/>
						{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&
							<DeleteModal
								title={moduleName.toLowerCase()}
								deleteValues={LABORATORIES?.labData}
								callApi={deleteRecord}
								close={() => closeDeleteModal()}
								open={deleteModal}
							/>}
						<WarningModal
							title={moduleName}
							close={() => { setOpenModal(false); dispatch(refreshGrid); dispatch(setLabData(null)) }}
							open={openModal} />
						<div className="listWrapper">
							<div className="list">
								<Listing
									moduleName={moduleName}
									handleEditClick={(data: any) => {
										dispatch(setRead(data));
										setEditForm(true);
									}}
									rowData={LABORATORIES.rowData}
									onRowClick={onRowClick}
								/>
							</div>
							<div className={checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) || checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) ? "actionRow" : ""}>
								<div className="actionBtn">
									{checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_UPDATE`) &&
										<Button
											onClick={() => {
												dispatch(setRead(LABORATORIES.labData));
												setEditForm(true);
											}}
											disabled={LABORATORIES.labData ? false : true}
											className={LABORATORIES.labData ? "activeBtn" : "disableBtn"}
										>
											Edit
											<SVGIcon icon="edit" />
										</Button>}
									{
										checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DELETE`) &&

										<Button
											disabled={LABORATORIES.labData ? false : true}
											onClick={() => {
												disabled == true ? setOpenModal(true) : setDeleteModal(true)
											}}
											className={LABORATORIES.labData ? "deleteBtn" : "disableDelBtn disableBtn"}
										>
											Delete
											<SVGIcon icon="delete" />
										</Button>
									}
								</div>
							</div>
						</div>
					</ContentBox></> : <RestrictedAccessPage />}
		</>
	);
};

export default Laboratories;
