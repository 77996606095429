import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'antd'
import { InputBox } from '../../../../components/AntdAddons'
import { CubeTesting } from '../../../../config/BreadcrumbConfig'
import ToolBar from '../../../../components/ToolBar'
import {
	dataToFormDataConverter,
	structureElementBreadcrumbName,
	structureElementBreadcrumbUrl,
	validateFields,
} from '../../../../config/Global'
import { AppDispatch, RootState } from '../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
	createRecord,
	getDynamicCubeTestingData,
	getGradeOfConcreteList,
	setDynamicCubeTestingData,
	setResetRead,
} from '../utils/slice'
import { assignErrorToInput } from '../../../../store/api'
import { rules } from './rules'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const AddCubeTesting: React.FC<any> = () => {
	const dispatch: AppDispatch = useDispatch()
	const { CUBE_TESTING } = useSelector((state: RootState) => state)
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const { mappingId, structureId, mName } = useParams()
	const pileNo = localStorage.getItem('pileNo')
	const [loading, setLoading] = useState(false)
	const [grade, setGrade] = useState<any>()
	const [date, setDate] = useState<any>()
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [, setState] = useState(false);
	const cubeTestingData = CUBE_TESTING?.dynamicCubeTestingData?.data
	const pro_id: any = localStorage.getItem('projectId')
	const name =structureElementBreadcrumbName(structureId)
	const endUrl =structureElementBreadcrumbUrl(structureId)

	useEffect(() => {
		!cubeTestingData && dispatch(getDynamicCubeTestingData(mappingId))
	}, [getDynamicCubeTestingData, mappingId])

	useEffect(() => {
		if (cubeTestingData) {
			dispatch(setResetRead())
			form.resetFields()
		}
	}, [])
	
	const handleSubmit = (data?: any) => {
		setLoading(true)
		setDisabled(true)
		const payload = {
			...data,
			project_id: pro_id,
			cube_casting_date: date,
			cement_grade_id: grade,
			qa_params_value_mapping_id: parseInt(mappingId ?? ''),
			qa_category_id: 502
		}
		const formData = dataToFormDataConverter(payload)
		dispatch(createRecord(payload.qa_params_value_mapping_id, formData))
			.then(() => {
				setDisabled(false)
				setLoading(false)
				form.resetFields();
				dispatch(getDynamicCubeTestingData(mappingId))
			})
			.catch((error: any) => {
				setLoading(false)
				setDisabled(false)
				assignErrorToInput(form, error?.STATUS)
			})
	}

	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	const handleTestClick = () => {
		dispatch(setDynamicCubeTestingData([]))
		navigate(
			`/projects/${structureId}/${mName}/${mappingId}/${cubeTestingData?.id}/test-report`,
			{
				state: {
					sevenDayReport: CUBE_TESTING?.dynamicCubeTestingData?.sevenDayReport,
					tweentyDayReport: CUBE_TESTING?.dynamicCubeTestingData?.tweentyDayReport,
					cube_casting_date: cubeTestingData?.cube_casting_date
				}
			}
		)
	}

	const handleCancel = () => {
		navigate(`/projects/${structureId}/${mName}`)
	}
	const onDateChange = (dates: any) => {
		const d = dayjs(dates).format('YYYY-MM-DD')
		setDate(d)
	}

	useEffect(() => {
		form.resetFields()
		setDisabled(true);
		const details = cubeTestingData
		if (details) {
			form.setFieldsValue({
				cement_grade_id: details?.name,
				planned_qty: details?.planned_qty,
				no_of_qube_testing_required:
					details?.no_of_qube_testing_required,
				slump_measured_on_site: details?.slump_measured_on_site,
				executed_qty: details?.executed_qty,
				cube_casting_date: dayjs(details?.cube_casting_date),
				attach_photo_of_slump: details?.project_document?.map(
					(image: { id: any; document: any }) => {
						return {
							//uid: image.id,
							name: image?.document,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
						}
					}
				)
			})
			setState(prevState => !prevState);
		}
	}, [CUBE_TESTING?.dynamicCubeTestingData, setState])

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname ==
						`/projects/${structureId}/${endUrl}`
						? CubeTesting(pro_id, structureId, name, endUrl)
						: CubeTesting(pro_id, structureId, name, endUrl)
				}
			></ToolBar>
			<div className="formHeading">
				<h3>Cube Testing</h3>
			</div>
			<Form
				form={form}
				onFinish={handleSubmit}
				onValuesChange={validateForm}
				className="addReportForm cubeForm"
			>
				<div>
					<Row>
						<Col span={12}>
							<span className="pileNumber">
								Pile No. - {`${pileNo}`}
							</span>
						</Col>
					</Row>
					<Row justify="end" className="btnRow">
						<Button
							onClick={handleTestClick}
							className="secondaryBtn"
							disabled={
								!cubeTestingData
									? true
									: false
							}
						>
							Test Report
						</Button>
					</Row>
					<Row className="concreteForm" gutter={40}>
						<Col span={12}>
							<InputBox.Select
								label="Grade Type"
								name="cement_grade_id"
								style={{ width: '100%' }}
								options={{
									list: CUBE_TESTING.gradeOfConcreteList,
									valueKey: 'id',
									textKey: 'department_name',
								}}
								disabled={
									cubeTestingData
										? true
										: false
								}
								onFocus={() =>
									dispatch(
										getGradeOfConcreteList(pro_id, {
											cube: true,
										})
									)
								}
								onChange={(e) => {
									setGrade(e)
								}}
								rules={rules.selectFields()}
								className="select"
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Planned Quantity"
								name="planned_qty"
								rules={rules.numberFields()}
								disabled={
									cubeTestingData
										? true
										: false
								}
							/>
						</Col>

						<Col span={12}>
							<InputBox.Text
								label="No. of Cube Casting Required As Per QAP:"
								name="no_of_qube_testing_required"
								rules={rules.numberFields()}
								disabled={
									cubeTestingData
										? true
										: false
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								name="slump_measured_on_site"
								label="Slump Measured On Site:"
								rules={rules.dynamicFields()}
								disabled={
									cubeTestingData
										? true
										: false
								}
							/>
						</Col>

						<Col span={12}>
							<InputBox.Text
								label="Executed Quantity:"
								name="executed_qty"
								rules={rules.numberFields()}
								disabled={
									cubeTestingData
										? true
										: false
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								label="Cube Set Casting Date:"
								name="cube_casting_date"
								onChange={onDateChange}
								rules={rules.dateFields()}
								disabled={
									cubeTestingData
										? true
										: false
								}
							/>
						</Col>

						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="attach_photo_of_slump"
								required={false}
								label="Attach Photo of Slump"
								fileList={form.getFieldValue('attach_photo_of_slump')}
								maxCount={10}
								disabled={
									cubeTestingData
										? true
										: false
								}
							/>
						</Col>
					</Row>
					<Row justify="end">
						<div
							className="ReportBtn"
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								className="secondaryBtn"
								htmlType="submit"
								disabled={disabled}
								loading={loading}
							>
								Submit
							</Button>
							<Button
								className="secondaryBtn"
								htmlType="submit"
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</div>
					</Row>
				</div>
			</Form>
		</>
	)
}

export default AddCubeTesting
