const baseUrl = "/v1/admin/roles";

export const apiUrls = {
	list: `${baseUrl}`,
	create: `${baseUrl}/create`,
	update: (id: number): string => `${baseUrl}/${id}/update`,
	delete: `${baseUrl}/delete`,
	details: (id: number): string => `${baseUrl}/${id}/details`,
	bulkUpdate: `${baseUrl}/bulk-update`,
	changeStatus: (id: number): string => `${baseUrl}/${id}/change-status`,
};
