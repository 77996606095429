import { createSlice } from "@reduxjs/toolkit";
import type { Action, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../config/Config";
import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import { Dispatch } from "react";

interface InitialState {
	categoriesList: { [key: string]: string | number | boolean; }[];
	isLoading: boolean;
	rowData: any;
	perPage: any;
	agGrid: any;
	details: any;
	projectId: any;
	selectedData: any;
	categoryData: any;
}

const initialState = {
	isLoading: false,
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	rowData: null,
	details: null,
	projectId: null,
	agGrid: null,
	selectedData: null,
	categoryData: null
} as InitialState;

const StructureSlice = createSlice({
	name: "STRUCTURE",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setDynamicFieldType: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action.payload
		},
		setDetails: (state, action: PayloadAction<any>) => {
			state.details = action?.payload;
		},
		setProjectId: (state, action: PayloadAction<any>) => {
			state.projectId = action?.payload;
		},
		setSelectedData: (state, action: PayloadAction<any>) => {
			state.selectedData = action?.payload;
		},
		setCategoryData: (state, action: PayloadAction<any>) => {
			state.categoryData = action?.payload;
		},
	},
});

export const { start, success, failure,
	setRowData, setDetails, setProjectId, setGrid,
	setPerPage, setSelectedData, setCategoryData/* resetAgGrid */ } =
	StructureSlice.actions;

export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().STRUCTURE.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				dispatch(setPerPage(getState().STRUCTURE.perPage));
				const dataSource = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
				var gridApi = params.api;
				gridApi.deselectAll();
			} catch (error: any) {
				//
			}
		};


const changeFilterAndSort = (params: any, dispatch?: any) => {
	dispatch(setSelectedData(null))
	params;
};
/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
	return await api.post(apiUrls.list, payload).then(({ data }) => {
		return data;
	});
};

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch);
			const payload = getServerListPayload(params);
			const data = {
				project_id: getState().STRUCTURE.projectId,
				...payload
			}
			dispatch(setSelectedData(null))
			fetchList(data).then((data) => {
				params.success({ rowData: data?.rows, rowCount: data?.count });
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			});
		},
	};
};


export const qacategory =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.qacategory(id));
				dispatch(success(response.data));
				dispatch(setCategoryData(response.data.data))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const createRecord =
	(data: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create, data);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().STRUCTURE.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};


export const updateRecord =
	(id: any, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.update(id), action)
				dispatch(success(response.data))
				dispatch(setupGrid(getState().STRUCTURE.agGrid));
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}

export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			const payload: any = { id: id, is_delete: true, project_id: getState().STRUCTURE.selectedData?.project_id, qa_categories_id: getState().STRUCTURE.selectedData?.qa_categories_id }
			try {
				dispatch(start());
				const response = await api.post(apiUrls.deleteElement, payload);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().STRUCTURE.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const refreshGrid = (): AppThunk<any> =>
	async (dispatch, getState) => {
		dispatch(setupGrid(getState().STRUCTURE.agGrid));
	}
const StructureSliceReducer = StructureSlice.reducer;
export default StructureSliceReducer;
