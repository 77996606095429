import { ColDef } from 'ag-grid-community'
import {
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const idColumn: ColDef = {
	field: 'id',
	headerName: '#ID',
	filter: 'agNumberColumnFilter',
	tooltipValueGetter: (params: any) => params.data.id,
}
const userTypeColumn: ColDef = {
	field: 'user_type',
	headerName: 'User Type',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.user_type,
}
const userNameColumn: ColDef = {
	field: 'first_name',
	headerName: 'User Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.first_name,
}
const eventColumn: ColDef = {
	field: 'event',
	headerName: 'Event',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.event,
}
const moduleNameColumn: ColDef = {
	field: 'auditable_type',
	headerName: 'Module',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.auditable_type,
}
const auditableIdColumn: ColDef = {
	field: 'auditable_id',
	headerName: 'Auditable ID',
	filter: 'agNumberColumnFilter',
	tooltipValueGetter: (params: any) => params.data.auditable_id,
}
const urlColumn: ColDef = {
	field: 'url',
	headerName: 'URL',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.url,
}
const ipAddressColumn: ColDef = {
	field: 'ip_address',
	headerName: 'IP Address',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.ip_address,
}
const userAgentColumn: ColDef = {
	field: 'user_agent',
	headerName: 'User Agent',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.user_agent,
}
const roleNameColumn: ColDef = {
	field: 'role_name',
	headerName: 'Role',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.role_name,
}
const platform: ColDef = {
	field: 'platform',
	headerName: 'Platform',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.platform,
}

const columnDefs: ColDef[] = [
	idColumn,
	platform,
	roleNameColumn,
	userTypeColumn,
	userNameColumn,
	eventColumn,
	moduleNameColumn,
	auditableIdColumn,
	urlColumn,
	ipAddressColumn,
	userAgentColumn,
	updatedAtColumn,

]
export default columnDefs
