import React from "react";
import { Col } from "antd";
import { InputBox } from "../../../../../../components/AntdAddons";
import { rules } from "../rules";

const DynamicField: React.FC<any> = ({ item, dynamicReinforcement, form }) => {
	const parseData = JSON.parse(item.data) ?? null;

	return (
		<Col span={12} className="commanCol">
			<div className="commanQue">

				{item.input_type === "Radio Button" && (
					<InputBox.Radio
						className="radioGap"
						label={`${item.params_label_full_text}`}
						name={[`${item.id}`, "value"]}
						rules={rules.dynamicFields()}
						options={{
							list: item.input_options ? JSON.parse(item.input_options) : [],
							valueKey: "id",
							textKey: "name",
						}}
						disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
					/>
				)}

				{item.input_type === "Drop Down" && (
					<InputBox.Select
						label={`${item.params_label_full_text}`}
						name={[`${item.id}`, "value"]}
						options={{
							list: item.input_options ? JSON.parse(item.input_options) : [],
							valueKey: "id",
							textKey: "name",
						}}
						rules={rules.dynamicFields()}
						disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
					/>
				)}
				{(item.input_type === "Date" || item.input_type === "Date Picker") && (
					<InputBox.DatePicker
						label={`${item.params_label_full_text}`}
						name={[`${item.id}`, "value"]}
						disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
					/>
				)}

				{(item.input_type === "Text Box" || item.input_type === "TextBox") && (
					<InputBox.Text
						name={[`${item.id}`, "value"]}
						label={`${item.params_label_full_text}`}
						maxLength={200}
						rules={rules.dynamicFields()}
						disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
					/>
				)}

				{parseData.remark_display == 1 &&
					(parseData.type == "number" ? (
						<InputBox.Text
							name={[`${item.id}`, "remark"]}
							className="textAreaResize"
							disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
							rules={rules.numberFields}
						/>
					) : (
						<InputBox.TextArea
							rows={2}
							name={[`${item.id}`, "remark"]}
							rules={rules.dynamicFields()}
							disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
						/>
					))}

				{parseData.photo_required == 1 && (
					<div>
						<InputBox.Upload
							name={[`${item.id}`, "file"]}
							required={true}
							label="Upload Document"
							fileList={form.getFieldValue([`${item.id}`, item.id])}
							maxCount={5}
							disabled={dynamicReinforcement?.reinforcement_data !== null && dynamicReinforcement?.reinforcement_data.length > 0}
						/>
					</div>
				)}
			</div>
		</Col>
	);
};

export default DynamicField;
