
import { validations } from '../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax()

	],
	numberFields: (field?: string) => [

		validations.required.other(field),
	],
	selectFields: (field?: any) => [
		validations.required.select(field),
	],
	radioButton: (field?: any) => [
		validations.required.other(field),
	],
	dateFields: (field?: string) => [
		validations.required.select(field),
	],
	FileUpload: (field?: any) => [
		validations.required.text(field),
	],
	grossWeightField: (field?: string) => [
		validations.required.other(field),
		validations.pattern.tenDigitWithDecimal(2),
	],
	tareWeightField: (field?: string) => [
		validations.required.other(field),
		validations.pattern.tenDigitWithDecimal(2),
		({ getFieldValue }: any) => ({
			validator(_: any, value: any) {
				const tareWeight = parseInt(value);
				const grossWeight = parseInt(getFieldValue('gross_weight'));
				if (tareWeight > grossWeight) {
					return Promise.reject(`It must be between 0 to ${grossWeight}`);
				} else {
					return Promise.resolve();
				}
			},
		})
	],
	netWeightField: () => [
		validations.pattern.tenDigitWithDecimal(2),
		() => ({
			validator(_: any, value: any) {
				const neWeight = parseInt(value);
				if (neWeight === 0) {
					return Promise.reject('Should be greater than 0');
				}
				else if (neWeight < 0) {
					return Promise.reject('It is not valid');
				}
				else {

					return Promise.resolve();
				}


			}
		})
	],

}
