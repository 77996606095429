
import { validations } from '../../../../../config/validations/validations'

export const rules = {
	selectFields: (field?: any) => [
		validations.required.select(field),
	],
	dateFields: (field?: string) => [
		validations.required.select(field),
	],

}
