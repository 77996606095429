import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../../../store/api'
import { AppThunk } from '../../../../store/app'
import { apiUrls } from './apiUrls'

interface InitialState {
	isLoading: boolean
	rowData: any
	read: any
	dynamicCubeTestingData: any
	cubeTestingList: any
	// departmentTypeList: any
	gradeOfConcreteList: any
	// cubeTestReport: any;
}

const initialState = {
	isLoading: false,
	rowData: null,
	read: null,
	dynamicCubeTestingData: null,
	cubeTestingList: null,
	// departmentTypeList: null,
	gradeOfConcreteList: null,
	// cubeTestReport: null,
} as InitialState

const CubeTestingSlice = createSlice({
	name: 'CUBE_TESTING',
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true
		},
		success: (state) => {
			state.isLoading = false
		},
		failure: (state) => {
			state.isLoading = false
		},
		setDynamicCubeTestingData: (state, action: PayloadAction<any>) => {
			state.dynamicCubeTestingData = action?.payload
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload
		},
		setGradeOfConcreteList: (state, action: PayloadAction<any>) => {
			state.gradeOfConcreteList = action?.payload
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload
		},
		setResetRead: (state) => {
			state.read = initialState
		},
		setCubeTestingList: (state, action: PayloadAction<any>) => {
			state.cubeTestingList = action?.payload
		},
		// setCubeTestReport: (state, action: PayloadAction<any>) => {
		//   state.cubeTestReport = action?.payload;
		// },
	},
})

export const {
	start,
	success,
	failure,
	setDynamicCubeTestingData,
	setRowData,
	setGradeOfConcreteList,
	setRead,
	setResetRead,
	setCubeTestingList,
	// setCubeTestReport,
} = CubeTestingSlice.actions


export const fetchList = (): AppThunk<any> => async (dispatch) => {
	dispatch(start())
	try {
		dispatch(start())
		const response = await api.post(apiUrls.list)
		dispatch(setRowData(response.data.rows))
		dispatch(success())
		return Promise.resolve(response.data)
	} catch (error: any) {
		dispatch(failure())
		return Promise.reject(error.data)
	}
}
export const getGradeOfConcreteList =
	(id?: any, action?: any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.post(
				apiUrls.gradeOfConcreteList(id),
				action
			)
			dispatch(setGradeOfConcreteList(response.data.data))
			dispatch(success())
			return Promise.resolve(response.data)
		} catch (error: any) {
			dispatch(failure())
			return Promise.reject(error.data)
		}
	}
export const getDynamicCubeTestingData =
	(mappingId: any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.post(apiUrls.cubeTestingData(mappingId))
			// dispatch(setDynamicConcreatData(response.data));
			dispatch(setDynamicCubeTestingData(response.data))
			dispatch(success())
			return Promise.resolve(response.data)
		} catch (error: any) {
			return Promise.reject(error.data)
		}
	}

export const createRecord =
	(id: number, action: any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.post(apiUrls.create(id), action)
			dispatch(success(response.data))
			// dispatch(fetchList())
			return Promise.resolve(response.data)
		} catch (error: any) {
			dispatch(failure(error.data))
			return Promise.reject(error.data)
		}
	}
export const createTestReport =
	(id: any, action: any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.post(
				apiUrls.createTestReport(id),
				action
			)
			dispatch(success(response.data))
			// dispatch(fetchList())
			return Promise.resolve(response.data)
		} catch (error: any) {
			dispatch(failure(error.data))
			return Promise.reject(error.data)
		}
	}

export const read =
	(id: number): AppThunk<any> =>
	async (dispatch) => {
		try {
			const response = await api.get(apiUrls.read(id))
			dispatch(setRead(response.data.data))
			return Promise.resolve(response.data)
		} catch (error: any) {
			return Promise.reject(error.data)
		}
	}

export const updateRecord =
	(id: number, action: any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.post(apiUrls.update(id), action)
			dispatch(success(response.data))
			dispatch(fetchList())
			return Promise.resolve(response.data)
		} catch (error: any) {
			dispatch(failure(error.data))
			return Promise.reject(error.data)
		}
	}

export const deleteRecord =
	(id: number, action: any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.get(apiUrls.delete(id), action)
			dispatch(success(response.data))
			dispatch(fetchList())
			return Promise.resolve(response.data)
		} catch (error: any) {
			dispatch(failure(error.data))
			return Promise.reject(error.data)
		}
	}

const CubeTestingSliceReducer = CubeTestingSlice.reducer
export default CubeTestingSliceReducer
