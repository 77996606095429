import React from 'react'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'

import columnDefs from './columnDefs'
import { setGrid } from '../../utils/slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/app'

const Listing: React.FC<any> = ({
	rowData,
	onRowClick
}) => {
	const { DESIGN_CODE } = useSelector((state : RootState) => state)
	return (
		<>
			<AgGridWrapper
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setGrid}
				onRowClicked={onRowClick}
				rowSelection='single'
				cacheBlockSize={DESIGN_CODE?.perPageSize}
				paginationPageSize={DESIGN_CODE?.perPageSize}
				pinnedBottomRowData={[]}
			/>
		</>
	)
}

export default Listing
