import { regexPatterns, validations } from '../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [
		validations.required.text(field),
		validations.minMax(),
		{
			message: 'please add valid text',
			pattern: regexPatterns.numeric_alpha,
		},

	]
}
