import { validations } from '../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [
		validations.required.text(field),
		validations.minMax()
	],
	dynamicNumericField: (field?: any) => [
		validations.required.text(field),
		validations.pattern.numeric(),
		{
			max: 10,
			message: "It must have 10 digits",
		}
	]

}
