import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Spin, Divider } from "antd";
import { InputBox } from "../../../../components/AntdAddons";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/app";
import { setAdvanceFilterValues, setupGrid } from "../utils/slice";
import { getDepartmentList } from "../../Project/utils/slice";
import { ReactComponent as Applied } from "../../../../assets/images/icon/checkMark.svg";
import { ReactComponent as Cancel } from "../../../../assets/images/icon/delete.svg";
import { ReactComponent as Reset } from "../../../../assets/images/icon/refresh.svg";

const FilterComponent = (props: any) => {
	const dispatch: AppDispatch = useDispatch();
	const [form] = Form.useForm();
	const { PROJECT, QUESTION_MASTER } = useSelector((state: RootState) => state);
	const [saving, setSaving] = useState<boolean>();
	const [btnDisabled, setDisabled] = useState(true);
	const [fetchState, setFetchState] = useState(true);

	useEffect(() => {
		if (QUESTION_MASTER.advance_filter?.department.id) {
			let data = PROJECT.departmentList;
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			data = [QUESTION_MASTER.advance_filter.department];
			form.setFieldsValue({
				department_id: QUESTION_MASTER.advance_filter.department.id,
			});
			setDisabled(false);
		}
	}, [QUESTION_MASTER, PROJECT.departmentList, form]);


	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then(() => {
				if (form.getFieldValue("department_id")) {
					setDisabled(false);
				} else {
					setDisabled(true);
					dispatch(setAdvanceFilterValues(null));
				}
			})
			.catch(() => {
				setDisabled(true);
			});
	}, 500);

	// // check for valid form values then accordingly make save button disable / enable filter button
	// const handleClear = (type:any) => {
	// 	if (form.getFieldsValue("state_id")) {
	// 		if (type === "state_id") {
	// 			form.resetFields(['state_id'])
	// 		}
	// 	}
	// }

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		const isDepartment = false;
		setSaving(true);
		Object.keys(data).forEach(
			(k) => (data[k] === null || data[k] === undefined) && delete data[k]
		);
		if ("department_id" in data) {
			data.department = { id: data.department_id };
			delete data.department_id;
		}
		dispatch(setAdvanceFilterValues(data));
		if (isDepartment) {
			form.setFieldsValue({
				department_id: data.department.id,
			});
		}
		dispatch(setupGrid(QUESTION_MASTER.agGrid));
		setSaving(false);
		close();
	};

	// reset form and close add form
	const close = () => {
		props.setFilterVisible(false);
		setFetchState(true);
	};

	//reset form and close add form
	const reset = () => {
		form.resetFields();
		setFetchState(true);
		setDisabled(true);
		dispatch(setAdvanceFilterValues(null));
		close();
	};

	return (
		<Form
			form={form}
			labelCol={{ span: 24 }}
			id="filterFrom"
			onFinish={handleSubmit}
			className="filterForm"
		>
			{
				<>
					<div className="advanceFilter">
						<div className="advanceFilterTitle">
							<h3>Advance Filter</h3>
							<Button
								key="2"
								form="filterFrom"
								htmlType="button"
								onClick={close}
								className="close"
							>
								<Cancel height={20} width={20} />
							</Button>
						</div>
						<Divider style={{ margin: "10px 0px" }} />
						<Row>
							<Col xs={{ span: 24 }}>
								<InputBox.Select
									// type="select"
									allowClear
									showSearch
									label="Department"
									name="department_id"
									className="select"
									// placeholder="Select Department"
									//  onClear={(e) => { handleClear("state_id") }}
									onFocus={() => dispatch(getDepartmentList())}
									onChange={handleChange}
									notFoundContent={
										fetchState ? <Spin size="small" /> : "No Record Found."
									}
									options={{
										list: PROJECT.departmentList,
										valueKey: "id",
										textKey: "department_name",
									}}
								/>
							</Col>
						</Row>
						<Row className="btnRow">
							<Button
								key="1"
								disabled={btnDisabled}
								form="filterFrom"
								loading={saving}
								htmlType="submit"
								className="applied"
							>
								<Applied />
							</Button>

							<Button
								key="3"
								disabled={btnDisabled}
								form="filterFrom"
								htmlType="submit"
								onClick={reset}
								className="reset"
							>
								<Reset height={22} width={22} />
							</Button>
						</Row>
					</div>
				</>
			}
		</Form>
	);
};

export default FilterComponent;
