import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import AuthSliceReducer from "./AuthSlice";
import CommonSliceReducer from "./CommonSlice";
import UserSliceReducer from "../views/modules/UserManagement/utils/slice";
import RoleSliceReducer from "../views/modules/RoleManagement/utils/slice";
import ProjectSliceReducer from "../views/modules/Project/utils/slice";
import StructureSliceReducer from "../views/modules/StructureElement/utils/slice";
import DepartmentSliceReducer from "../views/modules/Department/utils/slice";
import DepartmentTypeSliceReducer from "../views/modules/DepartmentType/utils/slice";
import ProjectAttributeGroupSliceReducer from "../views/modules/ProjectAttributeGroup/utils/slice";
import QAParamsGroupSliceReducer from "../views/modules/QAParamsGroup/utils/slice";
import QAElementSliceReducer from "../views/modules/QAElement/utils/slice";
import ConcretingSliceReducer from "../views/modules/Concreting/utils/slice";
import ReinforcementSliceReducer from "../views/modules/Reinforcement/utils/slice";
import PileCapSliceReducer from "../views/modules/PileCapElement/utils/slice";
import RawMateriaSliceReducer from "../views/modules/RawMaterial/utils/slice";
import SteelSliceReducer from "../views/modules/RawMaterial/components/TabElements/Steel/utils/slice";
import CubeTestingSliceReducer from "../views/modules/CubeTesting/utils/slice";
import PolymerSlurrySliceReducer from "../views/modules/PolymerSlurry/utils/slice";
import CementSliceReducer from "../views/modules/RawMaterial/components/TabElements/Cement/utils/slice";
import FineAggregateSliceReducer from "../views/modules/RawMaterial/components/TabElements/FineAggregate/utils/slice";
import ConcreteAdmixtureSliceReducer from "../views/modules/RawMaterial/components/TabElements/ConcreteAdmixture/utils/slice";
import BindingWireSliceReducer from "../views/modules/RawMaterial/components/TabElements/Binding Wire/utils/slice";
import CoarseAggregateSliceReducer from "../views/modules/RawMaterial/components/TabElements/CoarseAggregate/utils/slice";
import PolymerSlurryTabReducer from "../views/modules/RawMaterial/components/TabElements/PolymerSluryTab/utils/slice";
import CementBrandSliceReducer from "../views/modules/CementBrand/utils/slice";
import ConsolidatedSliceSliceReducer from "../views/modules/ConsolidatedReports/utils/slice";
import LaboratoriesSliceReducer from "../views/modules/Laboratories/utils/slice";
import CreateProjectDashboardSliceReducer from "../views/modules/CreateProjectDashboard/utils/slice";
import DesignConsultantSliceReducer from "../views/modules/DesignConsultant/utils/slice";
import PmcSliceReducer from "../views/modules/Pmc/utils/slice";
import ContractorSliceReducer from "../views/modules/Contractor/utils/slice";
import ProgressSliceReducer from "../views/modules/Progress/utils/slice";
import DesignCodeSliceReducer from "../views/modules/DesignCode/utils/slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import QuestionMasterSliceReducer from "../views/modules/AddQuestionPage/utils/slice";
import CategorySliceReducer from "../views/modules/Category/utils/slice";
import TestTypeSliceReducer from "../views/modules/TestType/utils/slice";
import AuditLogSliceReducer from "../views/modules/AuditLog/utils/slice";
import DutyAssignmentSliceReducer from "../views/modules/DutyAssignment/utils/slice";
import WorkCompleteSliceReducer from "../views/modules/WorkCompleted/utils/slice";
import RoadReportSliceReducer from "../views/modules/ViewRoadReport/utils/slice";

const store = configureStore({
	reducer: {
		AUTH: AuthSliceReducer,
		USER: UserSliceReducer,
		ROLE: RoleSliceReducer,
		COMMON: CommonSliceReducer,
		PROJECT: ProjectSliceReducer,
		PILE_CAP: PileCapSliceReducer,
		STRUCTURE: StructureSliceReducer,
		DEPARTMENT: DepartmentSliceReducer,
		CEMENT_BRAND: CementBrandSliceReducer,
		DEPARTMENT_TYPE: DepartmentTypeSliceReducer,
		PROJECT_ATTRIBUTE_GROUP: ProjectAttributeGroupSliceReducer,
		QA_PARAMS_GROUP: QAParamsGroupSliceReducer,
		QA_ELEMENT: QAElementSliceReducer,
		CONCRETING: ConcretingSliceReducer,
		REINFORCEMENT: ReinforcementSliceReducer,
		RAWMATERIAL: RawMateriaSliceReducer,
		STEELDIAMETER: SteelSliceReducer,
		CUBE_TESTING: CubeTestingSliceReducer,
		POLYMER_SLURRY: PolymerSlurrySliceReducer,
		CEMENTINVENTORY: CementSliceReducer,
		FINE_AGGREGATE: FineAggregateSliceReducer,
		CONCRETE_ADMIXTURE: ConcreteAdmixtureSliceReducer,
		BINDING_WIRE: BindingWireSliceReducer,
		COARSE_AGGREGATE: CoarseAggregateSliceReducer,
		POLYMER_SLURRY_TAB: PolymerSlurryTabReducer,
		CONSOLIDATED_REPORT: ConsolidatedSliceSliceReducer,
		LABORATORIES: LaboratoriesSliceReducer,
		CREATE_DASHBOARD_PROJECT: CreateProjectDashboardSliceReducer,
		DESIGN_CONSULTANT: DesignConsultantSliceReducer,
		PMC: PmcSliceReducer,
		CONTRACTOR: ContractorSliceReducer,
		PROGRESS: ProgressSliceReducer,
		DESIGN_CODE: DesignCodeSliceReducer,
		QUESTION_MASTER: QuestionMasterSliceReducer,
		CATEGORY: CategorySliceReducer,
		TEST_TYPE: TestTypeSliceReducer,
		AUDIT_LOG: AuditLogSliceReducer,
		DUTY_ASSIGNMENT: DutyAssignmentSliceReducer,
		WORK_COMPLETED: WorkCompleteSliceReducer,
		ROAD_REPORT: RoadReportSliceReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<any>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store; // Export the store as the default export
