import { ColDef, ValueFormatterParams } from 'ag-grid-community'
import { idColumn } from '../../../../../utils/commonColumns'


const NameOfAgencyColumn: ColDef = {
	field: 'contractor_name',
	headerName: 'Name Of Agency',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.contractor_name,
}

const VehicleNumberColumn: ColDef = {
	field: 'vehicle_number',
	headerName: 'Vehicle Number',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.vehicle_number,
}
const WorkCompletedColumn: ColDef = {
	field: 'work_completed_on',
	headerName: 'Work Completed On',
	filter: 'agSetColumnFilter',
	cellRenderer: (props: any) => {
		if (props.data.work_completed_on == 1) {
			return "Paver"
		} else if (props.data.work_completed_on == 2) {
			return "Patch"
		}
	},
	filterParams: {
		values: [1, 2],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Paver" && value === 1) || (filter === "Patch" && value === 2)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Paver" : params.value === 2 ? "Patch" : params.value;
		},
	},
}
const NetWeightColumn: ColDef = {
	field: 'net_weight',
	headerName: 'Net Weight (MT)',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.net_weight,
}


const columnDefs: ColDef[] = [
	idColumn,
	NameOfAgencyColumn,
	VehicleNumberColumn,
	WorkCompletedColumn,
	NetWeightColumn
]
export default columnDefs
