import React, { useEffect } from 'react'
import { FormInstance } from 'antd'
import { InputBox } from '../../../../../../../components/AntdAddons'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../../store/app'
import { getConcreteAdmixtureType } from '../utils/slice'
import { regexPatterns } from '../../../../../../../config/validations/validations'
import { blockInvalidChar } from '../../../../../../../config/Global'
import { useParams } from 'react-router-dom'
interface TableComponentProps {
	form: FormInstance
}
const TableComponent: React.FC<TableComponentProps> = ({ form }) => {
	const dispatch: AppDispatch = useDispatch()
	const { projectId } = useParams()

	const CONCRETE_ADMIXTURE = useSelector(
		(state: RootState) => state.CONCRETE_ADMIXTURE
	)
	useEffect(() => {
		dispatch(getConcreteAdmixtureType(5))
	}, [projectId])

	useEffect(() => {
		location.pathname.includes("/add-concrete-admixture") && (
		setTimeout(() => {
			CONCRETE_ADMIXTURE?.testTypeList &&
				CONCRETE_ADMIXTURE?.testTypeList?.map(
					(data?: any) => {
						
						form.setFieldValue(
							['test_types', data.id.toString(), 'mst_test_type_id'],
							data.id
						)
						form.setFieldValue(
							['test_types', data.id.toString(), 'test_type_value'],
							1
						)
					}
				)
		}), 500)
	}, [CONCRETE_ADMIXTURE.testTypeList])
	
	const validateValue = (rule?: any, value?: any, callback?: any) => {
		if (value == 0) {
			callback('Minimum 1 test required')
		} else if (value?.toString().length > 4) {
			callback('Maximum 4 digit allowed')
		} else {
			callback()
		}
	}

	return (
		<>
			<div className="testTable">
				<h2>No. Of Test required As Per QAP</h2>
				<table>
					<thead>
						<tr>
							{CONCRETE_ADMIXTURE.testTypeList &&
								CONCRETE_ADMIXTURE.testTypeList.map(
									(field: any) => {
										return (
											<td width={400} key={field.id}>
												{field.name}
											</td>
										)
									}
								)}
						</tr>
					</thead>
					<tbody>
						<tr>
							{CONCRETE_ADMIXTURE.testTypeList &&
								CONCRETE_ADMIXTURE.testTypeList.map(
									(field: any) => {
										return (
											<td key={field.id}>
												<InputBox.Text
													type="number"
													name={[
														'test_types',
														field.id.toString(),
														'mst_test_type_id',
													]}
													initialValue={field.id}
													hidden
												/>
												<InputBox.Text
													type="number"
													onKeyDown={blockInvalidChar}
													required
													min={1}
													name={[
														'test_types',
														field.id.toString(),
														'test_type_value',
													]}
													rules={[
														{
															validator:
																validateValue,
														},
														{
															required: true,
															message:
																'It is required.',
														},
														{
															pattern:
																regexPatterns.numeric,
															message:
																'Decimal digits not allowed',
														},
													]}
													disabled={
														location.pathname.includes(
															'/view-concrete-admixture'
														)
															? true
															: false
													}
												/>
											</td>
										)
									}
								)}
						</tr>
					</tbody>
				</table>
			</div>

		</>
	)
}
export default TableComponent
