import { ColDef } from 'ag-grid-community'
import dayjs from 'dayjs';
import { idColumn } from '../../../../../../../utils/commonColumns';
import { CONSTANT } from '../../../../../../../config/Constant';

const DataEntryColumn: ColDef = {
	field: 'created_at',
	headerName: 'Created At',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
		return dayjs(data.data.created_at).format(CONSTANT.DATE_TIME_FORMAT)
	},
	tooltipValueGetter: (params: any) => {
		return dayjs(params.data.created_at).format(CONSTANT.DATE_TIME_FORMAT)
	},
}
const SourceColumn: ColDef = {
	field: 'source',
	headerName: 'Source',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.source,
}
const TotalCMTColumn: ColDef = {
	field: 'total_cmt',
	headerName: 'Total CMT ',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.total_cmt,
}


const columnDefs: ColDef[] = [
	idColumn,
	SourceColumn,
	TotalCMTColumn,
	DataEntryColumn,

]
export default columnDefs
