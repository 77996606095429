import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import Config, { getServerListPayload } from "../../../../config/Config";
import { apiUrls } from "./apiUrls";

interface InitialState {
	isLoading: boolean;
	agGrid: any;
	perPage: any;
	details: any;
	isUser: any;
	userRoleData: any
	firmType: any
	firmName: any
	idProof: any
	isTerminated: any
	projects: any
}

const initialState = {
	isLoading: false,
	agGrid: null,
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	details: null,
	isUser: null,
	userRoleData: null,
	firmType: null,
	firmName: null,
	idProof: null,
	isTerminated: 0,
	projects: null
} as InitialState;

const UserSlice = createSlice({
	name: "USER",
	initialState,
	reducers: {
		start: state => {
			state.isLoading = true;
		},
		success: state => {
			state.isLoading = false;
		},
		failure: state => {
			state.isLoading = false;
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action?.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setDetails: (state, action: PayloadAction<any>) => {
			state.details = action?.payload;
		},
		setIsUser: (state, action: PayloadAction<any>) => {
			state.isUser = action?.payload;
		},
		setUserRoleData: (state, action: PayloadAction<any>) => {
			state.userRoleData = action?.payload;
		},
		setFirmType: (state, action: PayloadAction<any>) => {
			state.firmType = action?.payload;
		},
		setFirmName: (state, action: PayloadAction<any>) => {
			state.firmName = action?.payload;
		},
		setIdProof: (state, action: PayloadAction<any>) => {
			state.idProof = action?.payload;
		},
		setIsTerminated: (state, action: PayloadAction<any>) => {
			state.isTerminated = action?.payload
		},
		setProjects: (state, action: PayloadAction<any>) => {
			state.projects = action?.payload
		}
	},
});

export const { start, success, failure, setGrid, setPerPage, setDetails, setIsUser, setUserRoleData, setFirmType, setFirmName, setIdProof, setIsTerminated, setProjects } =
	UserSlice.actions;

/** For Listing:Start */
const fetchList = async (id?: any, payload?: any): Promise<any> => {
	const archive = { is_archive: id }
	const f_payload = { ...payload, ...archive }
	return await api.post(apiUrls.list, f_payload).then(({ data }) => {
		return data;
	});
};

export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().USER.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				dispatch(setPerPage(getState().USER.perPage))
				const dataSource = await createDataSource(
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
				var gridApi = params.api;
				gridApi.deselectAll();
			} catch (error: any) {
				//
			}
		};

const changeFilterAndSort = (params: any) => {
	params;
};

const createDataSource = (
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request);
			const payload = getServerListPayload(params);
			fetchList(getState().USER.isTerminated, payload).then(data => {
				params.success({ rowData: data?.rows, rowCount: data?.count });
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			});
		},
	};
};

/** For Listing:End */

export const createRecord =
	(action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create, action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().USER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};
export const setArchive =
	(): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				dispatch(setupGrid(getState().USER.agGrid));
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const restoreUser = (id: number): AppThunk<any> => async (dispatch, getState) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.restore, { id: id });
		dispatch(setupGrid(getState().USER.agGrid));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const updateRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.update(id), action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().USER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.delete, { id: id, is_delete: true });
				dispatch(success(response.data));
				dispatch(setupGrid(getState().USER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const details =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.get(apiUrls.details(id));
				dispatch(setDetails(response.data.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const changeStatus =
	(id: number, status: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				const response = await api.post(apiUrls.changeStatus(id), { status });
				dispatch(setupGrid(getState().USER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
export const fetchRoleList = (): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.role);
		dispatch(setUserRoleData(response.data.rows));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const fetchFormType = (): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.get(apiUrls.firm_type);
		dispatch(setFirmType(response.data?.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const fetchFormName = (id?: any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.get((id == 2 ? apiUrls.pmc : '') || (id == 3 ? apiUrls?.contractor : '') || (id == 4 ? apiUrls?.design : ''));
		dispatch(setFirmName(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
export const fetchIdProof = (): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.get(apiUrls.id_proof);
		dispatch(setIdProof(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const fetchProjects = (data?: any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.getProjects, data);
		dispatch(setProjects(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const refreshGrid = (): AppThunk<any> =>
	async (dispatch, getState) => {
		dispatch(setupGrid(getState().USER.agGrid));
	}
const UserSliceReducer = UserSlice.reducer;
export default UserSliceReducer;
