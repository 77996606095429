import { ColDef } from 'ag-grid-community'
import DisabledContext from 'antd/es/config-provider/DisabledContext'
import dayjs from 'dayjs'
import { CONSTANT } from '../../../../../config/Constant'
import dateComparator from '../../../../../utils/dateComparator'

const zoneNameColumn: ColDef = {
	field: 'zone_name',
	headerName: 'Zone',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.zone_name,
}
const wardNameColumn: ColDef = {
	field: 'ward_name',
	headerName: 'Ward',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.ward_name,
}
const workDoneDateColumn: ColDef = {
	field: 'date',
	headerName: 'Work Done Date',
	filter: 'agDateColumnFilter',
	cellRenderer: (props?: any) => {
		if (props.node.rowPinned) {
			return props.value; // or return whatever default value you want for pinned rows
		}
		return dayjs(props.data.date).format(CONSTANT.DATE_FORMAT)
	},
	tooltipValueGetter:(params:any)=>{
		return dayjs(params.data.date).format(CONSTANT.DATE_FORMAT)
	},
	filterParams: {
		buttons: ["apply", "reset"],
		inRangeInclusive: true,
		suppressAndOrCondition: true,
		comparator: dateComparator,
		browserDatePicker: true,
	},
}
const nameOfContractorColumn: ColDef = {
	field: 'agency_name',
	headerName: 'Name of Contractor',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.agency_name,
}
const nameOfLocationColumn: ColDef = {
	field: 'location_of_plant',
	headerName: 'Plant Location',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.location_of_plant,
}
const nameOfRoadColumn: ColDef = {
	field: 'name_of_road',
	headerName: 'Road Name',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.name_of_road,
}
const paverPatchWorkColumn: ColDef = {
	field: 'work_completed_on',
	headerName: 'Paver/Patch Work',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.work_completed_on,
}
const typeOfMaterialColumn: ColDef = {
	field: 'work_completed_type_of_material',
	headerName: 'Type Of Material',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.work_completed_type_of_material,
}
const nameOfAgencyColumn: ColDef = {
	field: 'contractor_name',
	headerName: 'Name Of Agency',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.contractor_name,
}
const nameOfPlantLocationColumn: ColDef = {
	field: 'plant_location',
	headerName: 'Plant Location',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => params.data.plant_location,
}

const percentageColumn: ColDef = {
	field: 'per',
	headerName: '(%)',
	filter: 'agTextColumnFilter',
	headerClass: "custom-group-header",
	tooltipValueGetter: (params: any) => {
		return (parseFloat(params.data.net_weights) / parseFloat(params.data.planning_planned_quantity) * 100).toFixed(2) + "%"
	},
	cellRenderer: (props?: any) => {
		if (props.node.rowPinned) {
			return props.value; // or return whatever default value you want for pinned rows
		}
		return (parseFloat(props.data.net_weights) / parseFloat(props.data.planning_planned_quantity) * 100).toFixed(2) + "%"
	},
}
const planningGroupColumn = {
	headerName: "Planning",
	headerClass: "custom-group-header",
	children: [
		{
			field: 'planning_planned_quantity',
			headerName: 'DBM',
			filter: 'agTextColumnFilter',
			tooltipValueGetter: (params: any) => {
				if (params.data.planning_type_of_material == "DBM") {
					return params.data.planning_planned_quantity
				} else {
					return "-"
				}
			},
			cellRenderer: (props?: any) => {
				if (props.node.rowPinned) {
					return props.value; // or return whatever default value you want for pinned rows
				}
				if (props.data.planning_type_of_material == "DBM") {
					return props.data.planning_planned_quantity
				} else {
					return "-"
				}
			},
		},
		{
			field: 'planning_planned_quantity',
			headerName: 'BC',
			filter: 'agTextColumnFilter',
			tooltipValueGetter: (params: any) => {
				if (params.data.planning_type_of_material == "BC") {
					return params.data.planning_planned_quantity
				} else {
					return "-"
				}
			},
			cellRenderer: (props?: any) => {
				if (props.node.rowPinned) {
					return props.value; // or return whatever default value you want for pinned rows
				}
				if (props.data.planning_type_of_material == "BC") {
					return props.data.planning_planned_quantity
				} else {
					return "-"
				}
			},
		}
	]
}
const workCompletedGroupColumn = {
	headerName: "Work Completed",
	headerClass: "custom-group-header",
	children: [
		{
			field: 'net_weights',
			headerName: 'DBM',
			filter: 'agTextColumnFilter',
			tooltipValueGetter: (params: any) => params.data.net_weights,
			cellRenderer: (props?: any) => {
				if (props.node.rowPinned) {
					return props.value; // or return whatever default value you want for pinned rows
				}
				if (props.data.work_completed_type_of_material == "DBM") {
					return props.data.net_weights
				} else {
					return "-"
				}
			},
		},
		{
			field: 'net_weights',
			headerName: 'BC',
			filter: 'agTextColumnFilter',
			tooltipValueGetter: (params: any) => params.data.net_weights,
			cellRenderer: (props?: any) => {
				if (props.node.rowPinned) {
					return props.value; // or return whatever default value you want for pinned rows
				}
				if (props.data.work_completed_type_of_material == "BC") {
					return props.data.net_weights
				} else {
					return "-"
				}
			},
		}
	]
}
const rowIndexColumn: ColDef = {
	field: "id",
	pinned: "left",
	maxWidth: 100,
	minWidth: 100,
	width: 100,
	headerName: 'Sr. No.',
	filter: DisabledContext,
	floatingFilterComponentParams: {
		suppressFilterButton: true,
	},
	suppressMenu: true,
	sortable: false,
	headerClass: "custom-group-header",
	cellRenderer: (params: any) => {
		// Check if the row is pinned
		if (params.node.rowPinned) {
			return ''; // Hide the index for pinned rows
		}
		return params.node.rowIndex + 1; // Display the 1-based index for non-pinned rows
	},
};
const columnDefs: ColDef[] = [
	//idColumn,
	rowIndexColumn,
	zoneNameColumn,
	wardNameColumn,
	workDoneDateColumn,
]

export const columnDefsHotMix: ColDef[] = [
	rowIndexColumn,
	nameOfContractorColumn,
	nameOfLocationColumn
]

export const columnDefsHotMixPlanning = [
	rowIndexColumn,
	zoneNameColumn,
	wardNameColumn,
	nameOfRoadColumn,
	paverPatchWorkColumn,
	typeOfMaterialColumn,
	nameOfAgencyColumn,
	nameOfPlantLocationColumn,
	// planningDBMColumn,
	// planningBCColumn,
	// workCompletedDBMColumn,
	// workCompletedBCColumn,
	planningGroupColumn,
	workCompletedGroupColumn,
	percentageColumn
]
export default columnDefs
