import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { InputBox } from "../../../../../../../components/AntdAddons";
import TableComponent from "./TableComponent";
import { rules } from "./rules";
import ToolBar from "../../../../../../../components/ToolBar";
import { AddCoarseBreadcrumb } from "../../../../../../../config/BreadcrumbConfig";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { AppDispatch, RootState } from "../../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { setTab } from "../../../../utils/slice";
import { createRecord, getCoarseTestTypes, getQualityList, read, setCoarseId } from "../utils/slice";
import { dataToFormDataConverter, validateFields } from "../../../../../../../config/Global";
import { assignErrorToInput } from "../../../../../../../store/api";

const AddCoarseAggregate: React.FC = () => {
	const { courseId }: { projectId?: any, courseId?: any } = useParams()
	const dispatch: AppDispatch = useDispatch();
	const { COARSE_AGGREGATE } = useSelector((state: RootState) => state);
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(true)
	const [, setState] = useState(false);
	const [form] = useForm();
	const navigate = useNavigate()
	const [diameterList, setDiameterList] = useState<any>([])

	const handleCancel = () => {
		dispatch(setTab(2));
		dispatch(setCoarseId(null))
		navigate(`/projects/raw-material`);
	};

	const onFinish = (data: any) => {
		setDisabled(true)
		setLoading(true);
		data.project_id = localStorage.getItem('projectId');
		data.quality_wise_receipt = JSON.stringify({ data: data.quality_wise_receipt });

		const formData = dataToFormDataConverter(data);

		dispatch(createRecord(data.project_id, formData)).then(() => {
			setLoading(false)
			form.resetFields();
			dispatch(setTab(2));
			dispatch(setCoarseId(null))
			return navigate(`/projects/raw-material`);

		})
			.catch((error: any) => {
				setDisabled(true)
				setLoading(false)
				assignErrorToInput(form, error?.STATUS);
			});
	};
	useEffect(() => {
		if (courseId) {
			dispatch(read(courseId)).then(() => {
				//
			});
		}
	}, []);
	useEffect(() => {
		if (COARSE_AGGREGATE.read && courseId && location.pathname.includes("/view-coarse-aggregate")) {
			const data = COARSE_AGGREGATE?.read?.first_section;
			const data1 = COARSE_AGGREGATE?.read?.second_section;
			if (data?.id == courseId) {
				form.setFieldsValue({
					source: data?.source,
					total_cmt: data?.total_cmt,
					document_name: data?.attached_documnent?.map(
						(image: { id: any; document_name: any }) => {
							return {
								// uid: image.id,
								name: image?.document_name,
								status: "done",
								url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
								thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							};
						}
					)
				});

				var quality_wise_receipt: any = [];
				var data_quality: any = [];
				data1?.map((item: any) => {
					quality_wise_receipt[item?.coarse_aggregate_cmt_id] = {};
					quality_wise_receipt[item?.coarse_aggregate_cmt_id]["test_types"] = [];

					quality_wise_receipt[item?.coarse_aggregate_cmt_id].received_weight =
						item.received_weight;
					quality_wise_receipt[item.coarse_aggregate_cmt_id]["test_types"] = item.test_types

					setDiameterList([])
					data_quality.push({ id: item?.coarse_aggregate_cmt_id, name: item?.coarse_aggregate_cmt_name, received_weight: item?.received_weight })

				});
				setDiameterList(data_quality)
				form.setFieldsValue({
					quality_wise_receipt,
				});
				setState(prevState => !prevState);
			}
		}
	}, [COARSE_AGGREGATE?.read, setState]);

	useEffect(() => {
		location.pathname.includes("/add-coarse-aggregate") && dispatch(getQualityList());
		dispatch(getCoarseTestTypes(3));
	}, [form])

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const checkValue = (rule?: any, value?: any, callback?: any) => {
		if (parseFloat(value) <= 0) {
			callback("Value should be more than 0.");
		} else {
			callback();
		}
	};
	return (
		<>
			<ToolBar breadcrumbs={location.pathname.includes("/add-coarse-aggregate") ? AddCoarseBreadcrumb("Add") : AddCoarseBreadcrumb("View")}>
				<div className="diableBtnWrap"></div>
			</ToolBar>
			<Form className="formLayout" form={form} id="addForm" onFinish={onFinish} onValuesChange={validateForm}>
				<div className="formHeading">
					<h3>{location.pathname.includes("/add-coarse-aggregate") ? "Add " : "View "}
						Coarse Aggregate</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}
						>
							<InputBox.Text
								label="Source"
								rules={rules.dynamicFields()}
								name="source"
								disabled={
									location.pathname.includes("/view-coarse-aggregate")
								}
							/>
						</Col>
						<Col span={12} >
							<InputBox.Text
								label="Total CMT"
								rules={rules.numericFields()}
								name="total_cmt"
								disabled={
									location.pathname.includes("/view-coarse-aggregate")
								}
							/>

						</Col>

						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={false}
								label="Royalty Pass "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-coarse-aggregate")
								}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								hidden
								label="Total Weight :"
								name="total_weight"
								disabled={true}
								rules={[{
									validator: checkValue
								},
								{
									required: true,
									message: "It is required."
								}
								]}
							/>
						</Col>
					</Row>
				</div>

				<TableComponent form={form}
					coarseQualityList={COARSE_AGGREGATE?.coarseQualityList}
					testType={COARSE_AGGREGATE?.coarseTestType}
					diameterList={diameterList}
					setDiameterList={setDiameterList}
					validateForm={validateForm}
				/>

				<div className="steelBtn">
					<Button className="secondaryBtn" htmlType="submit" form="addForm" loading={loading} disabled={disabled}>
						Submit
					</Button>
					<Button className="secondaryBtn cancelBtn" onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			</Form>
		</>
	);
};

export default AddCoarseAggregate;
