import React, { useEffect, useState } from 'react'
import ToolBar from '../../../components/ToolBar'
import { ProjectAttributeBreadcrumb } from '../../../config/BreadcrumbConfig'
import { AppDispatch, RootState } from '../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
// import { assignErrorToInput } from '../../../store/api'
import { setPerPageSize } from '../UserManagement/utils/slice'
import RecordPerPage from '../../../components/ToolBar/Dropdown/RecordPerPage'
import ContentBox from '../../../components/ContentBox/ContentBox'
import Listing from './components/Listing/Listing'
import { Button } from 'antd'
// import { useNavigate } from 'react-router-dom'
// import FormComponent from './components/Form/FormComponent'
import { deleteRecord, fetchList, refreshGrid, setRead } from './utils/slice'
import AddComponent from './components/Forms/AddComponent'
import EditComponent from './components/Forms/EditComponent'
import DeleteModal from '../../../components/Modals/DeleteModal'
import SVGIcon from '../../../utils/SVGIcon'
import CreateButton from '../../../components/ToolBar/Buttons/CreateButton'
import { ModuleInfoProps } from '../../../config/InterfacesAndTypes'


const moduleName = 'ProjectAttribute'

const Department: React.FC<ModuleInfoProps> = (moduleInfo) => {
    const dispatch: AppDispatch = useDispatch()
    const DEPARTMENT = useSelector((state: RootState) => state.DEPARTMENT)
    // const [formVisible, setFormVisible] = useState<any>(false)
    const [addForm, setAddForm] = useState<any>(false)
    const [editForm, setEditForm] = useState<any>(false)

    // const [disabled, setDisabled] = React.useState<boolean>(true)
    // const [isModalOpen, setIsModalOpen] = useState(false)

    const [deleteValue, setDeleteValue] = useState<any>();


    // const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchList())
    }, [])

    const handleClick = () => {
        setAddForm(true)
    }



    const closeModal = () => {
        setAddForm(false)
        setEditForm(false)
        dispatch(refreshGrid)
    }


    // const getDetails = () => {
    //     dispatch(fetchList())
    // }


    return (
        <>
            <ToolBar breadcrumbs={ProjectAttributeBreadcrumb}>

                <>
                <CreateButton
                    action={() => handleClick()}
                    permissionKey={`${moduleInfo.permissionPrefix}_CREATE`}
                  />
                    <RecordPerPage
                        onChange={(perPageSize: number) => {
                            dispatch(setPerPageSize(perPageSize))
                        }}
                    />{' '}
                </>

            </ToolBar>
            <ContentBox>
                <AddComponent
                    titleName={moduleName}
                    open={addForm}
                    close={closeModal}
                />

                <EditComponent
                    titleName={moduleName}
                    open={editForm}
                    close={closeModal}
                />
                <DeleteModal
                    title={moduleName}
                    deleteValues={deleteValue}
                    callApi={deleteRecord}
                    close={() => setDeleteValue(null)}
                />
                   <div className="listWrapper">
                <div className='list'>
                    <Listing
                        moduleName={moduleName}
                        handleEditClick={(data: any) => {
                            dispatch(setRead(data));
                            setEditForm(true)
                        }}
                        rowData={DEPARTMENT.rowData}
                        handleDeleteClick={setDeleteValue}
                    />
                </div>
                <div className="actionRow">
                    <div className="actionBtn">
                        <Button
                            //  onClick={() => {
                            //   dispatch(setRead(CEMENT_BRAND.cementEditData));
                            //   setEditForm(true);
                            // }}
                            //  disabled={CEMENT_BRAND.cementEditData?false:true}
                            //  className={CEMENT_BRAND.cementEditData  ? "activeBtn" : "disableBtn"}
                            className="activeBtn"
                        >
                            Edit
                            <SVGIcon icon="edit" />
                        </Button>
                        <Button
                            // disabled={CEMENT_BRAND.cementEditData?false:true}
                            // onClick={()=>{
                            //   dispatch(setCementDeleteData(CEMENT_BRAND.cementEditData))
                            // }}
                            className="activeBtn deleteBtn"
                        >
                            Delete
                            <SVGIcon icon="delete" />

                        </Button>
                    </div>
                </div>
</div>
            </ContentBox>
        </>
    )
}

export default Department
