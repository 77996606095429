import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const Name: ColDef = {
	field: 'name',
	headerName: 'Name',
	tooltipValueGetter: (params: any) => params.data.name,
}
const Order: ColDef = {
	field: 'order',
	headerName: 'Order',
	tooltipValueGetter: (params: any) => params.data.order,
}
const columnDefs: ColDef[] = [
	idColumn,
	Name,
	Order,
	createdAtColumn,
	updatedAtColumn,
]
export default columnDefs
