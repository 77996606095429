import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { blockInvalidChar, dataToFormDataConverter, validateFields } from "../../../../../config/Global";
import { InputBox } from "../../../../../components/AntdAddons";
import { WorkCompletedAddViewBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import ToolBar from "../../../../../components/ToolBar";
import { assignErrorToInput } from "../../../../../store/api";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { createRecord, read, setPlanningId, setWorkPlanId } from "../../utils/slice";
import dayjs from "dayjs";
import { rules } from "./rules";
import { CONSTANT } from "../../../../../config/Constant";
import { getContractorList, setContractorList } from "../../../Contractor/utils/slice";


const FormComponent: React.FC = () => {
	const [form] = useForm();
	const navigate = useNavigate();
	const { WORK_COMPLETED, CONTRACTOR } = useSelector((state: RootState) => state);
	const dispatch: AppDispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(true)
	const [netWeight, setNetWeight] = useState<number>(0)
	const [, setState] = useState(false);
	const plan_id: any = localStorage.getItem('projectId')
	const { workId }: { workId?: any, projectId?: any } = useParams();
	const workCompletedData: any = localStorage.getItem("WorkCompleted")

	const workData: any = workCompletedData ? JSON.parse(workCompletedData) : []

	useEffect(() => {
		if (workId) {
			form.resetFields()
			dispatch(read(workId)).then(() => {
				//
			});
		}
	}, []);


	useEffect(() => {
		if (WORK_COMPLETED.read && workId && location.pathname.includes('/view')) {
			const details = WORK_COMPLETED.read
			dispatch(setContractorList([details?.contractor_name]))
			form.setFieldsValue({
				contractor_id: details?.contractor_id,
				location_of_plant: details?.location_of_plant,
				pmc_name: details?.pmc_name,
				vehicle_number: details?.vehicle_number,
				temperature_of_material: details?.temperature_of_material,
				type_of_material: details?.type_of_material,
				work_completed_on: details?.work_completed_on,
				date: dayjs(details?.date),
				time: details?.time,
				gross_weight: details?.gross_weight,
				tare_weight: details?.tare_weight,
				net_weight: details?.net_weight,
				upload_document: details?.document?.map(
					(image: { id: any; document_name: any }) => {
						return {
							// uid: image.id,
							name: image?.document_name,
							status: "done",
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						};
					}
				),
			})
			setState(prevState => !prevState);
		}
	}, [WORK_COMPLETED.read, workId, setState])

	const handleSubmit = (data: any) => {
		setDisabled(true)
		setLoading(true);
		const payload = {
			...data,
			date: dayjs(data?.date).format("YYYY-MM-DD"),
			planning_id: plan_id,
			type: workData?.type,
			user_id: workData?.id
		}
		const formData = dataToFormDataConverter(payload);

		dispatch(createRecord(formData)).then(() => {
			setLoading(false)
			form.resetFields();
			dispatch(setWorkPlanId(null))
			return navigate(`/projects/work-completed`);
		})
			.catch((error: any) => {
				setDisabled(true)
				setLoading(false)
				assignErrorToInput(form, error?.STATUS);
			});
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	const handleCancel = () => {
		dispatch(setPlanningId(null))
		navigate(`/projects/work-completed`);
	};

	const calculateNetWeight = () => {
		const grossWeight = form.getFieldValue("gross_weight");
		const tareWeight = form.getFieldValue("tare_weight");
		if (!isNaN(grossWeight) && !isNaN(tareWeight)) {
			const totalWeight = grossWeight - tareWeight;
			setNetWeight(totalWeight);
			form.setFieldsValue({ net_weight: totalWeight.toFixed(2) });
		} else {
			setNetWeight(0);
			form.setFieldsValue({ net_weight: 0 });
		}
	};

	return (
		<>
			<ToolBar
				breadcrumbs={WorkCompletedAddViewBreadcrumb(location.pathname.includes("/add") ? "Add" : "View", workData?.name)
				}
			></ToolBar>
			<div className="planningForm">
				<div className="formHeading">
					<h3>
						{location.pathname.includes("/add") ? "Add" : "View"} {`Work completed at ${workData?.name}`}
					</h3>
				</div>
				<Form className="addReportForm" onFinish={handleSubmit} form={form} onValuesChange={validateForm}>
					<div>
						<Row gutter={40}>
							<Col span={12}>
								<InputBox.Select
									label="Name of Agency"
									name={"contractor_id"}
									required={true}
									onFocus={() => dispatch(getContractorList())}
									options={{
										list: CONTRACTOR.contractorList,
										valueKey: "id",
										textKey: "name",
									}}
									rules={rules.selectFields()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label={`Location of ${workData?.name}`}
									name={"location_of_plant"}
									required={true}
									rules={rules.dynamicFields()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="PMC Name"
									name={"pmc_name"}
									required={true}
									rules={rules.dynamicFields()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Vehicle Number"
									name={"vehicle_number"}
									required={true}
									rules={rules.dynamicFields()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Temperature of material(Celsius)"
									name={"temperature_of_material"}
									required={true}
									rules={rules.dynamicFields()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Select
									className="projectList"
									name="type_of_material"
									label="Type of material"
									required={true}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
									options={{
										list: [
											{
												id: 1,
												value: "subgrade",
												name: "Subgrade",
											},
											{
												id: 2,
												value: "gsb",
												name: "GSB",
											},
											{
												id: 3,
												value: "wmm",
												name: "WMM",
											},
											{
												id: 4,
												value: "dbm",
												name: "DBM",
											},
											{
												id: 5,
												value: "bc",
												name: "BC",
											},
										],
									}}
									rules={rules.selectFields()}
								/>
							</Col>
							<Col span={12} className="uploadRow">
								<InputBox.Upload
									name="upload_document"
									required={true}
									label="Upload Document "
									fileList={form.getFieldValue('upload_document')}
									maxCount={5}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Radio
									name="work_completed_on"
									label="Work Completed on"
									options={{
										list: [
											{
												id: 1,
												value: "paver",
												name: "Paver",
											},
											{
												id: 2,
												value: "patch",
												name: "Patch",
											},
										],
									}}
									required={true}
									rules={rules.radioButton()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.DatePicker
									format={CONSTANT.DATE_FORMAT}
									label="Date"
									name="date"
									disabled={
										location.pathname.includes("/view") ? true : false
									}
									required={true}
									rules={rules.dateFields()}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									label="Time"
									name={"time"}
									required={true}
									rules={rules.dynamicFields()}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									type="number"
									label="Gross Weight(MT)"
									name={"gross_weight"}
									required={true}
									rules={rules.grossWeightField()}
									onKeyDown={blockInvalidChar}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
									onChange={() => { calculateNetWeight() }}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									type="number"
									label="Tare Weight(MT)"
									name={"tare_weight"}
									required={true}
									rules={rules.tareWeightField()}
									onKeyDown={blockInvalidChar}
									disabled={
										location.pathname.includes("/view") ? true : false
									}
									onChange={() => { calculateNetWeight() }}
								/>
							</Col>
							<Col span={12}>
								<InputBox.Text
									type="number"
									label="Net Weight(MT)"
									name={"net_weight"}
									required={true}
									rules={rules.netWeightField()}
									disabled={true}
									value={netWeight}
								/>
							</Col>
						</Row>

						<Row>
							<div className="ReportBtn">
								<Button
									className="secondaryBtn"
									htmlType="submit"
									loading={loading}
									disabled={disabled}
								>
									Submit
								</Button>
								<Button className="cancelBtn"
									htmlType="submit"
									onClick={handleCancel}>
									Cancel
								</Button>
							</div>
						</Row>
					</div>
				</Form>
			</div>
		</>
	);
};

export default FormComponent;
