/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import columnDefs from "./columnDefs";
import { Tag } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeStatus, setupGrid } from "../../utils/slice";
import ApproveModal from "../../ApproveModal";
import { dateFormatter } from "../../../../../config/Global";
import dateComparator from "../../../../../utils/dateComparator";
import { ValueFormatterParams } from "ag-grid-community";
import { idColumn } from "../../../../../utils/commonColumns";

const Listing: React.FC<any> = ({
	rowData,
	onRowClick,
	moduleInfo,
}) => {
	const navigate = useNavigate();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [statusData, setStatusData] = useState<any>();
	useEffect(() => {
		setIsDropdownOpen(!isDropdownOpen);
	}, [setIsDropdownOpen]);

	const { projectId, structureId, mName } = useParams()

	useEffect(() => {
		localStorage.removeItem("mappingId");
		localStorage.removeItem("isTouched");
	});

	const statusRender = (props: any) => {
		return (
			<Tag color={props.data.is_submit === 1 ? "green" : "orange"}>
				{props.data.is_submit == 1 ? "Submitted" : "Pending"}
			</Tag>
		);
	};
	const dynamic_columnDefs = rowData?.dynamicColumns?.map((data: any) => {
		if (data?.input_type == "Radio Button") {
			const input_options = JSON.parse(data?.input_options)
			return {
				field: data?.params_key,
				headerName: data?.params_label_short_text,
				filter: "agSetColumnFilter",
				filterParams: {
					values: [1, 2],
					textCustomComparator: (filter: any, value: any) => {
						return (filter === "Yes" && value === 1) || (filter === "No" && value === 2)
					},
					valueFormatter: (params: ValueFormatterParams) => {
						return params.value === 1 ? "Yes" : params.value === 2 ? "No" : params.value;
					},
				},
				cellRenderer: (props: any) => {
					if (input_options[props.data?.[data?.params_key] - 1]) {
						return input_options[props.data?.[data?.params_key] - 1]?.name
					} else {
						return "No"
					}
				},
				// valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
				tooltipValueGetter: (params: any) => {
					params.data.input_value
				}

			}
		} else if (data?.input_type == "Single") {
			const input_value = JSON.parse(data?.data)
			const radioObject = input_value?.find((item: any) => item?.type === "Radio Button");
			const textObject = input_value?.find((item: any) => item?.type === "Text");
			const dropDownObject = input_value?.find((item: any) => item?.type === "Drop Down");
			if(radioObject){
				return {
					field: data?.params_key,
					headerName: data?.params_label_short_text,
					filter: "agSetColumnFilter",
					filterParams: {
						values: [1, 2],
						textCustomComparator: (filter: any, value: any) => {
							return (filter === "Yes" && value === 1) || (filter === "No" && value === 2)
						},
						valueFormatter: (params: ValueFormatterParams) => {
							return params.value === 1 ? "Yes" : params.value === 2 ? "No" : params.value;
						},
					},
					cellRenderer: (props: any) => {
						if (radioObject?.type == "Radio Button") {
							const val = JSON?.parse(props?.data[data?.params_key])?.[radioObject?.id]
							if (JSON?.parse(props?.data[data?.params_key])?.[radioObject?.id]) {
								return radioObject?.options[val - 1]?.name
							} else {
								return "No"
							}
						}
					},
					// valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
					// tooltipValueGetter: (params: any) => {
					// 	params.data.input_value
					// }
	
				}
			}else if(textObject){
				return {
					field: data?.params_key,
					headerName: data?.params_label_short_text,
					filter: "agSetColumnFilter",
					cellRenderer: (props: any) => {
						if (textObject?.type == "Text") {
							const val = JSON?.parse(props?.data[data?.params_key])?.[textObject?.id]
							if (JSON?.parse(props?.data[data?.params_key])?.[textObject?.id]) {
								return JSON?.parse(props?.data[data?.params_key])?.[textObject?.id]
							} else {
								return ""
							}
						}
					},
					// valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
					//tooltipValueGetter: (params: any) => {
						// params.data.input_value
					// }
	
				}
			}else if(dropDownObject){
				return {
					field: data?.params_key,
					headerName: data?.params_label_short_text,
					filter: "agSetColumnFilter",
					cellRenderer: (props: any) => {
						if (dropDownObject?.type == "Drop Down") {
							const val = JSON?.parse(props?.data[data?.params_key])?.[dropDownObject?.id]
							if (JSON?.parse(props?.data[data?.params_key])?.[dropDownObject?.id]) {
								return dropDownObject?.options[val - 1]?.name
							} else {
								return ""
							}
						}
					},
					// valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
					// tooltipValueGetter: (params: any) => {
					// 	params.data.input_value
					// }
	
				}
			}
		} else if (data?.input_type == "Date") {
			return {
				field: data?.params_key,
				headerName: data?.params_label_short_text,
				filter: "agDateColumnFilter",
				cellRenderer: (props: any) => {
					return props.data?.[data?.params_key] ? dateFormatter(props.data?.[data?.params_key].toLocaleString()) : "N/A";
				},
				filterParams: {
					buttons: ["apply", "reset"],
					inRangeInclusive: true,
					suppressAndOrCondition: true,
					comparator: dateComparator,
					browserDatePicker: true,
				},

			}
		} else {
			return {
				field: data?.params_key,
				headerName: data?.params_label_short_text,
				filter: "agSetColumnFilter",
				// valueGetter: (props: any) => props.data.input_value ?? renderNA(props.data.input_value),
				// tooltipValueGetter: (params: any) => params.data.input_value,
			}
		}

	})
	const final_column = dynamic_columnDefs && [idColumn, ...dynamic_columnDefs, ...columnDefs]
	return (
		<>
			<ApproveModal
				titleName={moduleInfo}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={ChangeStatus}
			/>
			<AgGridWrapper
				type="serverSide"
				components={{
					statusRender,
				}}
				rowData={rowData}
				columnDefs={final_column}
				onGridReadyWithDispatch={setupGrid}
				onRowClicked={onRowClick}
				rowSelection={"single"}
				context={{
					setStatusData,

				}}
			/>
		</>
	);
};

export default Listing;
