import React, { useEffect } from "react";
import ToolBar from "../../../components/ToolBar";
import { ConsolidatedReportsBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import TableDataComponent from "./Components/TableDataComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import { fetchList } from "./utils/slice";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";

const ConsolidatedReports: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { CONSOLIDATED_REPORT, AUTH } = useSelector((state: RootState) => state);
	const pro_id: any = localStorage.getItem('projectId')
	useEffect(() => {
		dispatch(fetchList(pro_id))
	}, [])
	return (
		<>
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) && (
					<>
						<ToolBar breadcrumbs={ConsolidatedReportsBreadcrumb()}>
						</ToolBar>
						<ContentBox>
							<TableDataComponent rowData={CONSOLIDATED_REPORT.rowData} />
						</ContentBox></>)}
		</>
	);
};

export default ConsolidatedReports;
