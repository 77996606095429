const baseUrl = "/v1/admin/mixdesign"
const cement = "/v1/admin/cement"
const lab = "/v1/admin/laboratory"
export const apiUrls = {
	list: (id: number): string => `${baseUrl}/${id}/list`,
	create: (id: number): string => `${baseUrl}/${id}/create`,
	update: (id: number): string => `${baseUrl}/${id}/edit`,
	delete: `${baseUrl}/delete`,
	read: (id: number): string => `${baseUrl}/${id}/read`,
	gradeOfconcreteList: (id: number) => `${baseUrl}/${id}/cementgrade-list`,
	editGradeOfconcreteList: (id: number, mix_design_id: number) => `${baseUrl}/${id}/${mix_design_id}/cementgrade-list-id`,
	approval: (id: number) => `${baseUrl}/${id}/approval-add`,
	cementBrand: `${cement}/cementlist`,
	addCement: `${cement}/create`,
	laboratory: `${lab}/laboratorylist`,
	qaCategory: `${baseUrl}/qa-category`,
	removeImage: `${baseUrl}/remove-document`
}
