import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Divider, Form, Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";
import { fetchPrivilegesList, fetchRolesList, setRolesList } from "../../../../../store/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/app";
import { fetchFormName, fetchFormType, fetchIdProof, fetchProjects, setFirmName, setFirmType, setIdProof, setProjects } from "../../utils/slice";

const FormComponent: React.FC<any> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {
	const dispatch: AppDispatch = useDispatch();
	const commonStore = useSelector((state: RootState) => state.COMMON);
	const { USER } = useSelector((state: RootState) => state);
	const [hidePermissionTable, setHidePermissionTable] = useState<any>(false)
	const [disablePermission, setDisablePermission] = useState<boolean>(true)
	const [privilegesList, setPrivilegesList] = useState<any[]>([]);
	const [userPrivilege, setUserPrivilege] = useState<any>()
	const [disabledName, setDisabledName] = useState(true)
	const [firmTypeId, setFirmTypeId] = useState<number | null>(null)
	const [proofId, setProofId] = useState<number | null>(1)
	const [disabledNumber, setDisabledNumber] = useState(true)
	const [isCustom, setIsCustom] = useState<any>(false)
	const [projectDisabled, setProjectDisabled] = useState<boolean>(false)

	const include = ["INVENTORY", "MIXDESIGN", "STRUCTURE_ELEMENT", "CONSOLIDATE_REPORT", "PROGRESS_REPORT"]
	const childInclude = ["PROJECTS", "INVENTORY", "REPORT", "MIXDESIGN", "STRUCTURE_ELEMENT", "CONSOLIDATE_REPORT", "PROGRESS_REPORT"]
	const report = ["REPORT", "INVENTORY"]

	useEffect(() => {
		if (editValues) {
			dispatch(setProjects(editValues?.project_list))
			dispatch(setFirmType([{ id: editValues?.firm_type, name: editValues?.firm_type_original_name }]))
			dispatch(setFirmName([{ id: editValues?.firm_name, name: editValues?.firm_original_name }]))
			dispatch(setIdProof([{ id: editValues?.id_proof, name: editValues?.id_proof_original_name }]))
			dispatch(setRolesList([{ id: editValues?.role_id, name: editValues?.role_name }]))
			form.setFieldsValue({
				first_name: editValues?.first_name, last_name: editValues?.last_name, email: editValues?.email, parent_company: editValues?.parent_company, mobile_no: editValues?.mobile_no, role_id: editValues?.role_id,
				id_number: editValues?.id_number, firm_name: editValues?.firm_name, firm_type: editValues?.firm_type, id_proof: editValues?.id_proof, project_id: editValues?.project_id
			})
			setDisablePermission(false)
			const data = { ...editValues, privileges: editValues?.privileges !== null ? editValues?.privileges : editValues?.role_privileges }
			setUserPrivilege(data)
			setDisabledName(editValues?.firm_type != 1 ? false : true)
			setDisabledNumber(false)
			setFirmTypeId(editValues?.firm_type)
			setProofId(editValues?.id_proof)
			setProjectDisabled(true)
		}
	}, [])
	const onChangeContent = (
		checked: boolean,
		id: number,
		parentId: number,
		type: string
	) => {
		setIsCustom(true)
		const newPrivilegesList: any[] = privilegesList.map((item: any) => {
			let isSelected = item.selected;
			form.setFieldValue("checkParent", true)
			const child = item.child.map((childItem: any) => {
				let childSelected = childItem.selected;
				if (parentId && id == childItem.id) {
					childSelected = checked;
				}
				if (type == "parentId" && childItem.parent_id == id) {
					childSelected = checked;
				}
				if (isSelected === false && childSelected) {
					isSelected = childSelected;
				}

				return { ...childItem, selected: childSelected };
			});


			if (item.id === id) {
				isSelected = checked;
			}

			const d1 = { ...item, selected: isSelected, child: child }

			d1?.child?.map((d: any) => {
				if (parentId == 10001 && id == d?.id && d?.name == "List") {
					if ((d?.selected || d1?.selected) && checked) {
						form.setFieldValue("showChild", false)
					} else if (!checked) {
						form.setFieldValue("showChild", true)
						form.setFieldValue("showReport", true)

					}
					return d
				} else {
					return d
				}
			})

			if (d1?.selected && id == d1?.id && d1?.permission_key == "PROJECTS") {
				form.setFieldValue("showChild", false)
			} else if (!d1?.selected && id == d1?.id && d1?.permission_key == "PROJECTS") {
				form.setFieldValue("showChild", true)
				form.setFieldValue("showReport", true)
			}

			const f_child = d1?.child?.map((d: any) => {
				if (form.getFieldValue("showChild") == true && childInclude?.includes(d1?.permission_key)) {
					return ({ ...d, selected: false })
				} else {
					return d
				}
			})


			d1?.child?.map((d: any) => {
				if (parentId == 10006 && id == d?.id && d?.name == "List") {
					if ((d?.selected || d1?.selected) && checked) {
						form.setFieldValue("showReport", false)
					} else if (!checked) {
						form.setFieldValue("showReport", true)
					}
					return d
				} else {
					return d
				}
			})
			if (d1?.selected && id == d1?.id && d1?.permission_key == "INVENTORY") {
				form.setFieldValue("showReport", false)
			} else if (!d1?.selected && id == d1?.id && d1?.permission_key == "INVENTORY") {
				form.setFieldValue("showReport", true)
			}

			const final_child = f_child?.map((d: any) => {
				if (form.getFieldValue("showReport") == true && report?.includes(d1?.permission_key)) {
					return ({ ...d, selected: false })
				} else {
					return d
				}
			})

			const final_item = final_child?.map((d: any) => {
				if (d?.name == "List" && !d?.selected && item?.id == parentId) {
					form.setFieldValue("checkParent", false)
				}
				if (form.getFieldValue("checkParent") == false) {
					return { ...d, selected: false }
				} else {
					return d
				}
			})

			return { ...item, selected: form.getFieldValue("showChild") == true ? false : isSelected, child: final_item }
		});
		onValuesChange && onValuesChange();
		setPrivilegesList(newPrivilegesList);
	};


	useEffect(() => {
		dispatch(fetchPrivilegesList()).then((data: any) => {
			const oldPrivileges = ((userPrivilege?.privileges) && (userPrivilege?.privileges?.split("#"))) ?? []
			const privileges = data && data?.data?.map((item: any) => {

				let childIsSelected = false;
				let selected = false;
				const child = item.child.map((childItem: any) => {
					let childSelected = false;
					if (!childIsSelected) {
						childIsSelected = oldPrivileges.includes(childItem.id.toString());
					}
					childSelected = oldPrivileges.includes(childItem.id.toString());

					return { ...childItem, selected: childSelected };
				});

				if (
					childIsSelected ||
					(item.child.length == 0 &&
						oldPrivileges.includes(item.id.toString()))
				) {
					selected = true;
				} else {
					selected = false;
				}

				const d1 = { ...item, selected: selected, child: child };

				if (d1?.id == 10001) {
					if (d1?.selected) { form.setFieldValue("showChild", false) } else if (d1?.id == 10001 && !d1?.selected) {
						form.setFieldValue("showChild", true)
					}
					d1?.child?.map((d: any) => {
						if (d?.name == "List" && d?.selected) {
							form.setFieldValue("showChild", false)
						} else if (d?.name == "List" && !d?.selected) {
							form.setFieldValue("showChild", true)
						}
					})
				}
				if (d1?.id == 10006) {
					if (d1?.selected) { form.setFieldValue("showReport", false) } else if (d1?.id == 10001 && !d1?.selected) {
						form.setFieldValue("showReport", true)
					}
					d1?.child?.map((d: any) => {
						if (d?.name == "List" && d?.selected) {
							form.setFieldValue("showReport", false)
						} else if (d?.name == "List" && !d?.selected) {
							form.setFieldValue("showReport", true)
						}
					})
				}

				return { ...item, selected: selected, child: child };
			});

			setPrivilegesList(privileges);
		});
	}, [userPrivilege, setUserPrivilege]);
	useEffect(() => {
		dispatch(fetchRolesList());
	}, []);

	useEffect(() => {
		if (userPrivilege) {
			form.setFieldsValue(userPrivilege);
		}
	}, [userPrivilege, form]);

	const onFinish = (data: any) => {
		const selectedPrivileges: any = [];
		var selected: any = [];
		const f_privilegesList = privilegesList?.map((data?: any) => {
			let selected: any = data?.selected
			data?.child?.map((child?: any) => {
				if (child?.selected == true && child?.name == "List") {
					selected = true
				} else if (child?.selected == false && child?.name == "List") {
					selected = false
				}
				return { ...child }
			})
			return { ...data, selected: selected }
		})
		f_privilegesList.map((privilege: any) => {
			//privilege.selected && selectedPrivileges.push(privilege.id);
			privilege.child.map((childPrivilege: any) => {
				selected?.push(childPrivilege?.selected)
				!privilege.selected && childPrivilege.selected && childPrivilege?.name == "List" && selectedPrivileges.push(privilege.id);
				childPrivilege.selected && selectedPrivileges.push(childPrivilege.id);
				childPrivilege?.actions?.map((act: any) => {
					act?.selected && selectedPrivileges?.push(act?.id)
				})
			});
			selected?.includes(true) && privilege.selected && selectedPrivileges.push(privilege.id);
			selected = []
		});


		isCustom ? data.privileges =
			selectedPrivileges.length > 0
				? "#" + selectedPrivileges.join("#") + "#"
				: null : delete data.privileges
		handleSubmit(data);
	};
	const checkConfirmPassword = (rule?: any, value?: any, callback?: any) => {
		const pattern = form.getFieldValue("password");
		if (pattern != value) {
			callback('Password and Confirm password should be same.');
		} else {
			callback();
		}
	};

	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
				className="userManagmentForm"
			>
				<Row gutter={15}>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Text
							name="first_name"
							label="First Name"
							rules={rules.dynamicFields()}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Text
							name="last_name"
							label="Last Name"
							rules={rules.dynamicFields()}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Text
							name="email"
							label="Email"
							rules={rules.email()}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Text
							label="Contact Number"
							name="mobile_no"
							rules={rules.mobileNumber()}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Select
							label="Firm Type"
							name="firm_type"
							className="userSelect select"
							rules={rules.roleId()}
							onFocus={() => dispatch(fetchFormType())}
							onChange={(data) => {
								form.setFieldsValue({ project_id: null })
								setDisabledName(data && data != 1 ? false : true),
									setFirmTypeId(data),
									setProjectDisabled(data == 1 ? true : false)
								form.setFieldsValue({ firm_name: "" })
								data == 1 && form.setFields([
									{
										name: 'firm_name',
										value: "",
										errors: [],
									},
								]);
							}
							}
							options={{
								list: USER.firmType,
								valueKey: "id",
								textKey: "name",
							}}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Select
							disabled={disabledName}
							label="Firm Name"
							name="firm_name"
							className="userSelect select"
							onFocus={() => dispatch(fetchFormName(firmTypeId))}
							rules={firmTypeId != 1 && !disabledName ? rules.roleId() : []}
							options={{
								list: USER?.firmName,
								valueKey: "id",
								textKey: "name",
							}}
							onChange={() => {
								setProjectDisabled(true)
								form.setFieldsValue({ project_id: null })
							}}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Select
							mode="multiple"
							disabled={!projectDisabled}
							label="Select Project"
							name="project_id"
							className="userSelect select selectedFirm"
							onFocus={() => dispatch(fetchProjects({ firm_type: form.getFieldValue("firm_type"), firm_name: form.getFieldValue("firm_name") }))}
							//rules={firmTypeId != 1 && !disabledName ? rules.roleId() : []}
							options={{
								list: USER?.projects,
								valueKey: "id",
								textKey: "project_name",
							}}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Select
							label="Id Proof"
							name="id_proof"
							className="userSelect select"
							rules={rules.roleId()}
							onFocus={() => dispatch(fetchIdProof())}
							onChange={(data) => {
								setProofId(data)
								setDisabledNumber(data ? false : true)
								form.setFieldsValue({ id_number: "" })
							}}
							options={{
								list: USER?.idProof,
								valueKey: "id",
								textKey: "name",
							}}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						{
							proofId == 1 && <InputBox.Text
								disabled={disabledNumber}
								label="ID Number"
								name="id_number"
								rules={rules.aadharCard()}
							/>
						}
						{
							proofId == 2 && <InputBox.Text
								disabled={disabledNumber}
								label="ID Number"
								name="id_number"
								rules={rules.panCard()}
								onChange={(e) => {
									form.setFieldsValue({
										id_number: e.target.value.toUpperCase(),
									})
								}}
							/>
						}
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Select
							label="Role"
							name="role_id"
							className="userSelect select"
							rules={rules.roleId()}
							onChange={(e: any) => {
								setDisablePermission(false)
								setHidePermissionTable(false)
								USER?.userRoleData?.map((data: any) => {
									if (e == data?.id) {
										setUserPrivilege(data)
									}
								})
							}}
							options={{
								list: commonStore.rolesList,
								valueKey: "id",
								textKey: "name",
							}}
						/>
					</Col>
					{
						id !== "addForm" && <Col className="notCol"><span className="passwordNote">Note:</span>
							<span className="passwordNoteMsg">To update the password, please enter the new password. Otherwise, leave it blank to keep the old password as it is.</span></Col>
					}
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Password
							required={id == "addForm"}
							name="password"
							label="Password"
							autoComplete="new-password"
							rules={rules.password()}
						/>
					</Col>
					<Col xs={{ span: 24 }} md={{ span: 8 }}>
						<InputBox.Password
							required={id == "addForm"}
							name="confirm_password"
							label="Confirm Password"
							autoComplete="new-password"
							rules={[
								{
									validator: checkConfirmPassword,
								},
								rules.password()
							]}
						/>
					</Col>
					<Col xs={{ span: 24 }} className="permissionCol">
						<label></label>
						<Button className={disablePermission ? `disablePermissionBtn` : "modifyPermissionBtn"}
							disabled={disablePermission} onClick={() => { setHidePermissionTable(!hidePermissionTable) }}>
							Modify Permissions</Button>
					</Col>
					{(hidePermissionTable) ? (
						<Col span={24}>
							<Form.Item name={"privileges"} className="permissionForm">
								<>
									{hidePermissionTable && <Row gutter={15}>
										<Col xs={{ span: 4 }}><span className="permissionTitle">Features</span></Col>
										<Col xs={{ span: 20 }}><span className="permissionTitle">Actions</span></Col>
									</Row>}
									<Col xs={{ span: 24 }}>
										{privilegesList &&
											privilegesList.map((item: any) => {
												let isDisabled = false
												item?.child.map((action: any) => {
													if (action.permission_key === `${item.permission_key}_INDEX` && action.selected === false) {
														isDisabled = true

													}
												})

												let allChecked = 0
												item?.child.map((action: any) => {
													if (action.selected) {
														allChecked++
													}
												})

												return (
													<Row gutter={15} key={item.id}>
														<Card className="mt-10" style={{ width: "100%" }}>
															<Row gutter={15} key={item.id}>
																<Col
																	xs={{ span: 4 }}
																	style={{ borderRight: "1px solid #f0f0f0" }}
																>
																	<b>{item.name == "Dashboard" ? "Dashboard / Projects" : item?.name}</b>
																</Col>
																<Col xs={{ span: 20 }}>
																	<div style={{ display: "inline" }}>
																		{
																			item?.permission_key !== "REPORT" && <Checkbox
																				className="commanCheckbox"
																				checked={allChecked === item?.child?.length}
																				value={item.id}
																				onChange={e =>
																					onChangeContent(
																						e.target.checked,
																						item.id,
																						0,
																						"parentId"
																					)
																				}
																				disabled={(form.getFieldValue("showChild") && include?.includes(item?.permission_key))}
																			>
																				Check All
																			</Checkbox>
																		}
																		{
																			item?.permission_key == "REPORT" && <Checkbox
																				className="commanCheckbox"
																				checked={allChecked === item?.child?.length}
																				value={item.id}
																				onChange={e =>
																					onChangeContent(
																						e.target.checked,
																						item.id,
																						0,
																						"parentId"
																					)
																				}
																				disabled={form.getFieldValue("showReport")}
																			>
																				Check All
																			</Checkbox>
																		}

																	</div>
																	<Divider type="vertical" />
																	{item?.child.map(
																		(subPrivilege: any, index: number) => {
																			return (
																				<>
																					<div
																						key={index}
																						style={{ display: "inline" }}
																					>
																						{item?.permission_key !== "REPORT" && <Checkbox
																							className="commanCheckbox"
																							checked={subPrivilege.selected}
																							onChange={e =>
																								onChangeContent(
																									e.target.checked,
																									subPrivilege.id,
																									subPrivilege.parent_id,
																									"childId"
																								)
																							}
																							disabled={include?.includes(item?.permission_key) && subPrivilege?.permission_key?.includes("_INDEX") ? form.getFieldValue("showChild") : isDisabled && !subPrivilege.permission_key.includes("_INDEX")}
																						>
																							{subPrivilege.name}
																						</Checkbox>}
																						{
																							item?.permission_key == "REPORT" && <Checkbox
																								className="commanCheckbox"
																								checked={subPrivilege?.selected}
																								onChange={e =>
																									onChangeContent(
																										e.target.checked,
																										subPrivilege.id,
																										subPrivilege.parent_id,
																										"childId"
																									)
																								}
																								disabled={item?.permission_key == "REPORT" && subPrivilege?.permission_key?.includes("_INDEX") ? form.getFieldValue("showReport") : isDisabled && !subPrivilege.permission_key.includes("_INDEX")}
																							>
																								{subPrivilege.name}
																							</Checkbox>
																						}
																					</div>
																					<Divider type="vertical" />
																				</>
																			);
																		}
																	)}
																</Col>
															</Row>
														</Card>
														<InputBox.Text hidden name={"showChild"} />
														<InputBox.Text hidden name={"showReport"} />
														<InputBox.Text hidden name={"checkParent"} />
													</Row>
												);
											})}
									</Col>
								</>
							</Form.Item>
						</Col>) : null}
				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;
