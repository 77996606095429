/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Button,
	Col,
	FormInstance,
	Row,
} from "antd";
import React, { useState } from "react";
import { InputBox } from "../../../../../../components/AntdAddons";
import { rules } from "../rules";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/app";
import { imageDelete } from "../../../utils/slice";
import { ReactComponent as PlusIcon } from "../../../../../../assets/images/icon/plusIcon.svg";
import { ReactComponent as RemoveClick } from "../../../../../../assets/images/icon/remove-minus.svg";
import { nodeDelete } from "../../../../PileCapElement/utils/slice";
interface ComponentProps {
	dynamicFieldType: any;
	onChange: any;
	form: FormInstance;
	imageData: any;
	multipleSection?: any;
	setMultipleSection?: any
	isMultiple?: any;
	formId?: any;
}
const DynamicForm: React.FC<ComponentProps> = ({
	dynamicFieldType,
	onChange,
	form,
	multipleSection, setMultipleSection,
	formId
}: ComponentProps) => {
	let form_att;
	const [data, setData] = useState<any>(false);
	const [selctedData, setSelctedData] = useState<any>();
	const [radioSet, setRadioSet] = useState<any>("2");
	const [radioSet1, setRadioSet1] = useState<any>();
	const depId: any = localStorage.getItem('depId');
	const depType: any = localStorage.getItem('depType');
	const dispatch: AppDispatch = useDispatch();
	const pro_id: any = localStorage.getItem("projectId");

	const handleAddMultiple = (data?: any) => {
		const newId = multipleSection?.[data?.qa_param_id]?.length > 0
			? multipleSection[data?.qa_param_id][multipleSection[data?.qa_param_id]?.length - 1]?.id + 1
			: 1;

		const updatedMultipleSection: any = {
			...multipleSection,
			[data.qa_param_id]: [
				...(multipleSection?.[data?.qa_param_id] ?? []),
				{ ...data, id: newId }
			]
		};
		if (data) {
			formId == "ThirdStep" && setMultipleSection(updatedMultipleSection);
		}
		onChange()
	}

	const handleRemoveMultiple = (id: any, itemId?: any) => {
		const data = multipleSection?.[itemId]?.filter((d?: any) => d?.id != id)
		const list: any = []
		const node_data: any = {}
		multipleSection?.[itemId]?.map((d?: any) => {
			if (id == d?.id) {
				d?.att_json?.map((data?: any) => {
					form.getFieldValue([`${itemId}`, "file", `${d?.id}`, `${data.id}`]) != undefined && list.push(form.getFieldValue([`${itemId}`, "file", `${d?.id}`, `${data.id}`]))
				})
				node_data.node_id = d?.node_id
			}
		})

		const images = list[0]?.map((d?: any) => d?.uid)
		node_data.images_id = images
		node_data.project_id = pro_id
		node_data.type = 1
		node_data.node_id && dispatch(nodeDelete(node_data))


		multipleSection?.[itemId]?.map((d: any) => {
			if (id == d?.id) {
				d?.att_json?.map((data: any) => {
					form.setFieldValue([`${itemId}`, "value", `${d?.id}`, `${data.id}`], "")
					form.setFieldValue([`${itemId}`, "file", `${d?.id}`, `${data.id}`], [])
				})
			}
		})

		setMultipleSection({
			...multipleSection, [`${itemId}`]: [
				...data
			]
		});
		onChange()
	}

	return (
		<>
			{dynamicFieldType &&
				dynamicFieldType.map((item: any, index: number) => {
					form_att = JSON.parse(item.attribute_json);

					if (item.attribute_type === "Drop Down") {
						return (
							<Col span={6} key={`${item.attribute_key}-${index}`}>
								<InputBox.Select
									label={`${item.attribute_name}`}
									name={[`${item.id}`, "value"]}
									options={{
										list: item.data ? JSON.parse(item.data) : [],
										valueKey: "id",
										textKey: "name",
									}}
									rules={
										rules.selectFields(
											undefined,
											dynamicFieldType,
											item,
											form
										)
									}
									onChange={(e) => {
										setSelctedData(e);
										onChange();
									}}
								/>
							</Col>
						);
					}

					if (item.attribute_type === "Text Box") {
						if (item.attribute_key == 'total_length_of_construction' && depId == 5 && (depType == 11 || depType == 10)) {
							return ''
						}
						else {
							return (<Col span={12} key={`${item.attribute_key}-${index}`}>
								<InputBox.Text
									type={`${form_att.type}`}
									label={`${item.attribute_name}`}
									name={[`${item.id}`, "value"]}
									rules={
										form.getFieldValue([`${27}`, "value"]) == 2 && item?.attribute_key == "soil_testing_agency" ? rules.minMaxValidation() :
											rules.dynamicFields(
												undefined,
												dynamicFieldType,
												item,
												form
											)
									}
									onChange={(e) => {
										if (form.getFieldValue("21") === "") {
											form.setFieldsValue({
												"22": null,
											});
										}
										if (form.getFieldValue("19") === "") {
											form.setFieldsValue({
												"20": null,
											});
										}
										setData(e.target.value);
										onChange();
									}}
								/>
							</Col>);
						}
					}
					if (item.attribute_type === "Radio Button") {
						return (
							<>
								<Col span={12} key={`${item.attribute_key}-${index}`}>
									<InputBox.Radio
										label={`${item.attribute_name}`}
										name={[`${item.id}`, "value"]}
										//disabled={location.pathname.includes("/read")}
										options={{
											list: item.data ? JSON.parse(item.data) : [],
											valueKey: "id",
											textKey: "name",
										}}
										rules={
											// location.pathname.includes("/read")
											// 	? [{ required: false }]
											form.getFieldValue([`${27}`, "value"]) == 2 && item?.attribute_key == "soil_testing_agency" ? [] :
												rules.dynamicFields(
													undefined,
													dynamicFieldType,
													item,
													form
												)
										}
										onChange={(e) => {
											form.setFieldValue([`${item.id}`, "value"], e.target.value)
											setRadioSet(e.target.value);
											if (item.id == 27) {
												setRadioSet1(e.target.value == 2 ? false : true);
												if (e.target.value == 2) {
													form.getFieldValue([`${item.id}`, "file"])?.map((e: any) => {
														e && !e.size && e?.uid && dispatch(imageDelete(e?.uid))
													})
													form.setFieldValue([`${item.id}`, "file"], [])
													form.setFieldValue([`${28}`, "value"], "")
													form.setFields([
														{
															name: [`${item.id}`, "file"],
															errors: [],
														},
													])
													form.setFields([
														{
															name: [`${28}`, "value"],
															errors: [],
														},
													])
												}
											}
											onChange();
										}}
									/>
									{form_att.photo_required ? (
										<>
											<InputBox.Upload
												name={[`${item.id}`, "file"]}
												fileList={form.getFieldValue([`${item.id}`, "file"])}
												//maxCount={10}
												//disabled={form.getFieldValue("27") == 2}
												rules={[
													{
														required: form.getFieldValue([`${item.id}`, "value"]) != 2,
														//required: true,
														message: "Please upload a file",
													},
												]}
												onRemove={(e) => { e && !e.size && e?.uid && dispatch(imageDelete(e?.uid)) }}
											/>
										</>
									) : null}
								</Col>
							</>
						);
					}
					if (item?.attribute_type == "File" && !form_att?.required_if) {
						const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
						return (
							<Col span={12} key={`${item.attribute_key}-${index}`}>
								<InputBox.Upload
									label={`${item.attribute_name}`}
									name={[`${item.id}`, "file"]}
									fileList={form.getFieldValue([`${item.id}`, "file"])}
									//maxCount={10}
									rules={
										attributeJson.required && rules.fileUploadFields(
											undefined,
											dynamicFieldType,
											item,
											form
										)
									}
									onRemove={(e) => { e && !e.size && e?.uid && dispatch(imageDelete(e?.uid)) }}
								/>
								<InputBox.Text
									hidden
									name={[`${item.id}`, "value"]}
									initialValue="file"
								/>
							</Col>
						)
					}
					if (item.attribute_type === "Checkbox") {
						return (
							<Col span={12} key={`${item.attribute_key}-${index}`}>
								<InputBox.Checkbox
									label={`${item.attribute_name}`}
									//disabled={location.pathname.includes("/read")}
									name={[`${item.id}`, "value"]}
									options={{
										list: item.data ? JSON.parse(item.data) : [],
										valueKey: "id",
										textKey: "name",
									}}
									onChange={() => {
										onChange();
									}}
								/>
							</Col>
						);
					}
					if (item?.attribute_type === "Multiple") {
						const attr = JSON.parse(item?.data)
						const cnt = 0;
						if ((multipleSection?.[`${item.id}`]?.length <= 0 || multipleSection?.[`${item.id}`]?.length == undefined)) {
							handleAddMultiple({ qa_param_id: item?.id, id: parseInt(multipleSection?.[`${item.id}`] ? multipleSection?.[`${item.id}`]?.length : cnt), att_json: attr, type: "add" })
						}
						const remove_count = multipleSection?.[`${item.id}`]?.map((itm: any) => {
							if (itm?.qa_param_id == item?.id) {
								return itm?.qa_param_id
							}
						})?.filter((data: any) => data != undefined)
						return <>
							{
								<Col span={24} key={`${item.attribute_key}-${index}`}
								>
									<div className="plusIconWrap">
										<label>{item?.attribute_name}
											&nbsp;
											<Button disabled={multipleSection?.[`${item.id}`]?.length >= 5} className={multipleSection?.[`${item.id}`]?.length >= 5 ? "disabledAddIcon" : "addIcon"} onClick={() => { handleAddMultiple({ qa_param_id: item?.id, id: parseInt(multipleSection?.[`${item.id}`] ? multipleSection?.[`${item.id}`]?.length : cnt), att_json: attr, type: "add" }) }}><PlusIcon /></Button>
										</label>
									</div>
									{
										multipleSection?.[`${item.id}`]?.map((d: any, indx: any) => {
											if (d?.qa_param_id == item?.id) {
												return (
													<>
														<Row className="costSection" gutter={10} key={d?.id}>
															{
																attr?.map((att: any, indexInput?: any) => {

																	if (att?.type == "Number") {
																		return (
																			<>
																				<Col key={`${att.name}-${indexInput}-${index}`} span={4}>
																					<InputBox.Text
																						maxLength={40}
																						label={`${att.name}`}
																						name={[`${item.id}`, "value", `${d?.id}`, `${att.id}`]}
																						required={att?.required}
																						rules={
																							rules.multipleNumberField(
																								undefined,
																								dynamicFieldType,
																								att,
																								form
																							)
																						}
																						onChange={() => {
																							onChange()
																						}}
																					/>
																				</Col>
																			</>
																		);
																	}
																	else if (att?.type == "Text") {
																		return (
																			<>
																				<Col key={`${att.name}-${index}`} span={4}>
																					<InputBox.Text
																						maxLength={40}
																						label={`${att.name}`}
																						name={[`${item.id}`, "value", `${d?.id}`, `${att.id}`]}
																						required={att?.required}
																						rules={
																							rules.multipleField(
																								undefined,
																								dynamicFieldType,
																								att,
																								form
																							)
																						}
																						onChange={() => {
																							onChange()
																						}}
																					/>
																				</Col>
																			</>
																		);
																	} else if (att?.type == "File") {
																		return (
																			<Col key={`${att.name}-${index}`} span={10}>
																				<InputBox.Upload
																					maxCount={2}
																					label={`${att.name}`}
																					name={[`${item.id}`, "file", `${d?.id}`, `${att.id}`]}
																					fileList={form.getFieldValue([`${item.id}`, "file", `${d?.id}`, `${att.id}`])}
																					required={att?.required}
																					onRemove={(e) => { e && !e.size && e?.uid && dispatch(imageDelete(e?.uid)) }}
																				/>
																			</Col>
																		)
																	}
																})
															}
															<Col style={{ alignSelf: "center" }}>
																<Button className={remove_count?.length == 1 || multipleSection?.[`${item.id}`]?.length < 0 ? "disableRemove" : "removeIcon"} onClick={() =>
																	handleRemoveMultiple(d?.id, item.id)
																} disabled={remove_count?.length == 1 || multipleSection?.[`${item.id}`]?.length < 0}>
																	<RemoveClick />
																</Button>
															</Col>
														</Row>
													</>
												)
											}
										})
									}
								</Col>
							}</>
					}
				})}
		</>
	);
};
export default DynamicForm;
