import React, { useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { TYPE_DEPT_DASHBOARD } from '../../../../../config/Constant';

interface DataType {
	[key: string]: any
}
const RoadDepartment: React.FC<any> = ({ 
	//loading,
	 perPage, currentPage, handlePageChange, columns1, transformedData, finalData }) => {
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
	const tblRef: Parameters<typeof Table>[0]['ref'] = React.useRef(null);
	const [finalCountRoad, setFinalCountRoad,] = useState<any>([])

	useEffect(() => {
		const countRoad: any = localStorage.getItem(`count${TYPE_DEPT_DASHBOARD.Road}`);
		const finalCount: any = countRoad ? JSON.parse(countRoad) : [];
		setFinalCountRoad(finalCount)
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const isSingleProject = finalData?.length === 1;
	const tableScroll = isSingleProject
		? { x: 'max-content', y: 'auto' }
		: windowWidth <= 991
			? { x: '100%', y: '100%' }
			: { x: 1440, y: '100%' };
	return (
		<>
			<div className="test">
				<div className='verticalPagination'>
					<Pagination
						current={currentPage}
						total={finalCountRoad}
						onChange={handlePageChange}
						pageSize={perPage}
						showSizeChanger={false}
						showLessItems={true}
					/>
				</div>
				<Table
					columns={finalData?.length > 0 ? columns1 : []}
					dataSource={finalData?.length > 0 ? transformedData : ''}
					scroll={tableScroll}
					ref={tblRef}
					pagination={false}
					style={{ tableLayout: 'fixed' }}
					rowClassName={(record: DataType) => {
						if (record.first_col == 'Pile' || record.first_col === 'Pile Cap' || record.first_col === 'Pier' || record.first_col === 'Pier Cap') {
							return 'titleRow';
						} else {
							return '';
						}
					}}
					className={finalData?.length > 3 ? "departmentTable" : "moreDepartmentTable"}
				/>
			</div>
		</>
	);
};

export default RoadDepartment;