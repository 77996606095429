import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config from "../../../../config/Config";

interface InitialState {
	isLoading: boolean;
	rowData: any;
	typeOfReport: any;
	netCount: any;
	perPageSize: any;
	grid: any;
}

const initialState = {
	isLoading: false,
	rowData: null,
	typeOfReport: null,
	netCount: null,
	perPageSize: Config.grid.local.gridOptions?.paginationPageSize,
	grid: null,
} as InitialState;

const RoadReportSlice = createSlice({
	name: "ROAD_REPORT",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setPerPageSize: (state, action: PayloadAction<any>) => {
			state.perPageSize = action.payload;
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.perPageSize));
				state.grid.api.setCacheBlockSize(state.perPageSize);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.grid = action.payload;
		},
		setTypeOfReport: (state, action: PayloadAction<any>) => {
			state.typeOfReport = action?.payload;
		},
		setNetCount: (state, action: PayloadAction<any>) => {
			state.netCount = action?.payload;
		},
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
	setTypeOfReport,
	setNetCount,
	setPerPageSize,
	setGrid
} = RoadReportSlice.actions;

/** For Listing:Start */
export const searchList = (payload: any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.report, payload);
		dispatch(setRowData(response.data.data));
		dispatch(setNetCount(response.data.netcount))
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const fetchTypeOfReport = (): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.typeOfReport);
		dispatch(setTypeOfReport(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const fetchExportData = (payload:any): AppThunk<any> => async (dispatch) => {
	dispatch(start());
	try {
		dispatch(start());
		const response = await api.post(apiUrls.exportData,payload);
		// exportPDF(response.data.pdfBlob)
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
const RoadReportSliceReducer = RoadReportSlice.reducer;
export default RoadReportSliceReducer;