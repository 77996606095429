import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Form, InputNumber, Row } from "antd";
import { InputBox } from "../../../../components/AntdAddons";
import { updateRecord, setDetails } from "../utils/slice";
import { AppDispatch, RootState } from "../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { rules } from "./rules";
import { validateFields } from "../../../../utils/commonFunctions";
import { CONSTANT } from "../../../../config/Constant";
import dayjs from "dayjs";
import { blockInvalidChar } from "../../../../config/Global";

interface EditProps {
	close: () => void;
	visible?: boolean;
}

const EditComponent: React.FC<EditProps> = ({ visible, close }) => {
	const dispatch: AppDispatch = useDispatch();
	const STRUCTURE = useSelector((state: RootState) => state.STRUCTURE);
	const details = useSelector((state: RootState) => state.STRUCTURE.details);
	const [totalNoValue, setTotalNoValue] = useState<any>(details?.total_no);
	const [submittedNo, setSubmittedNo] = useState<any>(
		details?.submitted_in_state_rmb_design_circle_no
	);
	const [approvedNo, setApprovedNo] = useState<number>(
		details?.approved_by_state_rmb_design_circle_no
	);
	const [form] = Form.useForm();
	const [disabled, setDisabled] = React.useState<boolean>(true);

	// State variables to store previous values
	const [previousTotalNo, setPreviousTotalNo] = useState<any>(undefined);
	const [previousSubmittedNo, setPreviousSubmittedNo] =
		useState<any>(undefined);
	const [previousApprovedNo, setPreviousApprovedNo] = useState<any>(undefined);
	const [saving, setSaving] = useState(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const dep_id: any = localStorage.getItem("department_id");

	const handleStartDateChange = (date: any) => {
		setStartDate(date);
	};
	const handleEndDateChange = (date: any) => {
		setEndDate(date);
	};

	useEffect(() => {
		// Update the previous values whenever the corresponding values change
		setPreviousTotalNo(details?.total_no);
		setPreviousSubmittedNo(details?.submitted_in_state_rmb_design_circle_no);
		setPreviousApprovedNo(details?.approved_by_state_rmb_design_circle_no);
	}, [
		details?.total_no,
		details?.submitted_in_state_rmb_design_circle_no,
		details?.approved_by_state_rmb_design_circle_no,
	]);

	const handleClose = () => {
		dispatch(setDetails(null));
		form.resetFields();
		close();
		setTotalNoValue(0);
		setSubmittedNo(0);
		setApprovedNo(0);
	};

	const validateForm = () => {
		validateFields(form, setDisabled);
	};

	useEffect(() => {
		// STRUCTURE.projectId && dispatch(qacategory(STRUCTURE.projectId));
		form.setFieldsValue({
			startDate: details?.category_start_date ? dayjs(details.category_start_date) : null,
			endDate: details?.category_end_date ? dayjs(details.category_end_date) : null,
		});
		setStartDate(details?.category_start_date);
		setEndDate(details?.category_end_date);
	}, [STRUCTURE.projectId]);

	useEffect(() => {
		form.setFieldsValue({
			approval_pending_by_state_rmb_design_circle_no:
				submittedNo && approvedNo ? submittedNo - approvedNo : "",
			submission_pending_by_design_consultant_no:
				totalNoValue && submittedNo ? totalNoValue - submittedNo : "",
		});
	}, [submittedNo, approvedNo, totalNoValue]);

	const handleSave = async (data: any) => {
		const updatedData = {
			...data,
			category_start_date: dayjs(startDate)?.format("YYYY-MM-DD"),
			category_end_date: dayjs(endDate)?.format("YYYY-MM-DD"),
		};

		setSaving(true);
		try {
			dispatch(updateRecord(details?.id, updatedData))
				.then(() => {
					setSaving(false);
					handleClose();
				})
				.catch(() => {
					setSaving(false);
					// Handle error
				});
		} catch (error) {
			setSaving(false);
			console.error("Error submitting data:", error);
		}
	};

	return (
		<>
			<Drawer
				title="Edit Structural Element"
				placement="right"
				open={visible}
				footer={[
					<div key={1}>
						<Button
							className="secondaryBtn"
							htmlType="submit"
							form="UserEdit"
							disabled={disabled}
							loading={saving}
						>
							Update
						</Button>
						<Button className="cancelBtn" onClick={handleClose}>
							Cancel
						</Button>
					</div>,
				]}
				rootClassName="structureDrawer"
				width="50%"
				onClose={() => {
					handleClose();
				}}
			>
				<Form
					form={form}
					onFinish={handleSave}
					id="UserEdit"
					onValuesChange={validateForm}
					initialValues={details}
				>
					<Row gutter={25}>
						<Col span={12}>
							<InputBox.Text
								required
								label="Elements"
								disabled
								initialValue={details?.name}
								name="name"
							/>
						</Col>
						{dep_id != 5 ?
							<>
								<Col span={12}>
									<InputBox.Text
										type="number"
										label="Total Nos"
										name="total_no"
										rules={[
											{
												validator: (rule, value) => {
													if (value < previousTotalNo) {
														return Promise.reject(
															`The value cannot be less than the previous Total No. ${previousTotalNo}`
														);
													}
													if (value > totalNoValue) {
														return Promise.reject(
															`The value cannot be greater than Total. ${totalNoValue}`
														);
													}
													if (value.length < 1 || value.length > 10) {
														return Promise.reject("The input must be a minimum of 1 and a maximum of 10.");
													}
													return Promise.resolve();
												},
											},
											...rules.dynamicNumericField(),
										]}
										onChange={(e) => setTotalNoValue(Number(e.target.value))}
										onKeyDown={blockInvalidChar}
									/>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Submitted in State R&B Design circle (No.)"
										name="submitted_in_state_rmb_design_circle_no"
										rules={[
											{
												validator: (rule, value) => {
													if (value < previousSubmittedNo) {
														return Promise.reject(
															`The value cannot be less than the previous Submitted No. ${previousSubmittedNo}`
														);
													}
													if (value > totalNoValue) {
														return Promise.reject(
															`The value cannot be greater than Total. ${totalNoValue}`
														);
													}
													return Promise.resolve();
												},
											},
											...rules.dynamicNumericField1(),
										]}
									>
										<InputNumber
											onChange={(value) => setSubmittedNo(Number(value))}
											onKeyDown={(event) => {
												const isNumericKey = /^[0-9]+$/.test(event.key);
												const isAllowedKey =
													event.key === "Backspace" ||
													event.key === "Tab" ||
													event.key === "Delete";

												if (!isNumericKey && !isAllowedKey) {
													event.preventDefault();
												}
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Approved by State R&B Design circle (No.)"
										name="approved_by_state_rmb_design_circle_no"
										rules={[
											{
												validator: (rule, value) => {
													if (value < previousApprovedNo) {
														return Promise.reject(
															`The value cannot be less than the previous Approved No. ${previousApprovedNo}`
														);
													}
													if (value > submittedNo) {
														return Promise.reject(
															`The value cannot be greater than Submitted No. ${submittedNo}`
														);
													}
													return Promise.resolve();
												},
											},
											...rules.dynamicNumericField1(),
										]}
									>
										<InputNumber
											onChange={(value) => setApprovedNo(Number(value))}
											onKeyDown={(event) => {
												const isNumericKey = /^[0-9]+$/.test(event.key);
												const isAllowedKey =
													event.key === "Backspace" ||
													event.key === "Tab" ||
													event.key === "Delete";

												if (!isNumericKey && !isAllowedKey) {
													event.preventDefault();
												}
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<InputBox.Text
										disabled
										label="Approval Pending by State R&B Design Circle (No.)"
										name="approval_pending_by_state_rmb_design_circle_no"
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										disabled
										label="Submission Pending by State R&B Design Consultant (No.)"
										name="submission_pending_by_design_consultant_no"
									/>
								</Col>
							</> : ''}
						<Col span={12}>
							<Form.Item
								style={{ marginTop: "30px" }}
								// label="Start Date:"
								className="dateInStructure"
								name="startDate"
								rules={[
									{
										required: true,
										message: "Please select start date.",
									},
								]}
							>
								<InputBox.DatePicker
									label="Start Date:"
									onChange={handleStartDateChange}
									format={CONSTANT.DATE_FORMAT}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								style={{ marginTop: "30px" }}
								// label="End Date:"
								className="dateInStructure"
								name="endDate"
								rules={[
									{
										required: true,
										message: "Please select end date.",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || dayjs(getFieldValue("startDate")).format("YYYY-MM-DD") <= dayjs(value).format("YYYY-MM-DD")) {
												return Promise.resolve();
											}
											return Promise.reject(
												"End date must be equal to or after start date."
											);
										},
									}),
								]}
							>
								<InputBox.DatePicker
									label="End Date:"
									onChange={handleEndDateChange}
									format={CONSTANT.DATE_FORMAT}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Drawer>
		</>
	);
};

export default EditComponent;
