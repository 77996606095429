
import { ColDef } from 'ag-grid-community'
import { idColumn } from '../../../../../../utils/commonColumns'

const departmentNameColumn: ColDef = {
	field: 'department_name',
	headerName: 'Department Name',
	filter: 'agTextColumnFilter',
}

const QuestionColumn: ColDef = {
	field: 'params_label_short_text',
	headerName: 'Question',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.params_label_short_text,
}
const inputTypeColumn: ColDef = {
	field: 'input_type',
	headerName: 'Input Type',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.input_type,
}
const OrderColumn: ColDef = {
	field: 'sequence',
	headerName: 'Order',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.sequence,
	width:100,
	maxWidth:100,
	minWidth:100
}

const columnDefs: ColDef[] = [
	idColumn,
	departmentNameColumn,
	QuestionColumn,
	inputTypeColumn,
	OrderColumn,
]

export const statusColumnDefs: ColDef[] = [
	idColumn,
	departmentNameColumn,
	QuestionColumn,
	inputTypeColumn,
	OrderColumn,
]
export default columnDefs
