import { createSlice } from "@reduxjs/toolkit";
import type { Action, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../../../../store/api";
import { AppThunk, RootState } from "../../../../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../../../../config/Config";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { Dispatch } from "react";


interface InitialState {
	isLoading: boolean;
	rowData: any;
	reportRowData: any;
	read: any;
	readReportData: any
	steelList: any;
	perPage: any;
	agGrid: any;
	id: any
	steelDiameterList: any;
	testTypes: any
	reportId: any
	location: any
	steelId: any
	steelReportId: any
	laboratoryName: any
	reportPerPage: any
	dateRange: any
}

const initialState = {
	isLoading: false,
	rowData: null,
	read: null,
	steelList: null,
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	reportPerPage: Config.grid.server.gridOptions?.paginationPageSize,
	agGrid: null,
	steelDiameterList: null,
	reportRowData: null,
	testTypes: null,
	id: null,
	reportId: null,
	location: null,
	readReportData: null,
	steelId: null,
	steelReportId: null,
	laboratoryName: null,
	dateRange: null
} as InitialState;

const SteelSlice = createSlice({
	name: "Steel Inventory",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},

		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setReportPerPage: (state, action: PayloadAction<any>) => {
			state.reportPerPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.reportPerPage));
				state.agGrid.api.setCacheBlockSize(state.reportPerPage);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action.payload;
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload;
		},
		setSteelDiameterList: (state, action: PayloadAction<any>) => {
			state.steelDiameterList = action?.payload;
		},
		setReportRowData: (state, action: PayloadAction<any>) => {
			state.reportRowData = action?.payload;
		},
		setTestTypes: (state, action: PayloadAction<any>) => {
			state.testTypes = action?.payload;
		},
		setId: (state, action: PayloadAction<any>) => {
			state.id = action?.payload;
		},
		setReportId: (state, action: PayloadAction<any>) => {
			state.reportId = action?.payload;
		},
		setLocation: (state, action: PayloadAction<any>) => {
			state.location = action?.payload;
		},
		setReadReportData: (state, action: PayloadAction<any>) => {
			state.readReportData = action?.payload;
		},
		setSteelId: (state, action: PayloadAction<any>) => {
			state.steelId = action?.payload;
		},
		setSteelReportId: (state, action: PayloadAction<any>) => {
			state.steelReportId = action?.payload;
		},
		setLaboratoryName: (state, action: PayloadAction<any>) => {
			state.laboratoryName = action?.payload
		},
		setDateRange: (state, action: PayloadAction<any>) => {
			state.dateRange = action?.payload
		}
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
	setRead,
	setPerPage,
	setGrid,
	setSteelDiameterList,
	setReportRowData,
	setTestTypes,
	setId,
	setReportId,
	setLocation,
	setReadReportData,
	setSteelId,
	setSteelReportId,
	setLaboratoryName,
	setReportPerPage,
	setDateRange,
} = SteelSlice.actions;


export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			getState().STEELDIAMETER.location != "/steel-report" ?
				dispatch(setPerPage(size)) : dispatch(setReportPerPage(size))
			dispatch(setupGrid(getState().STEELDIAMETER.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				getState().STEELDIAMETER.location != "/steel-report" ?
					dispatch(setPerPage(getState().STEELDIAMETER.perPage)) : dispatch(setReportPerPage(getState().STEELDIAMETER.reportPerPage))
				const dataSource = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
			} catch (error: any) {
				//
			}
		};

const changeFilterAndSort = (params: any, dispatch?: any) => {
	dispatch(setSteelId(null))
	dispatch(setSteelReportId(null))
	params;
};

/** For Listing:Start */
const fetchList = async (id: any, payload?: any): Promise<any> => {
	return await api.post(apiUrls.list(id), payload).then(({ data }) => {
		return data;
	});
};

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch);
			const payload = getServerListPayload(params);
			const reportPayload = {
				...payload,
				start_date: getState().STEELDIAMETER.dateRange?.start_date,
				end_date:  getState().STEELDIAMETER.dateRange?.end_date ,
			}
			getState().STEELDIAMETER.location != "/steel-report" ?
				fetchList(getState().STEELDIAMETER.id, payload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				}) :
				fetchReportList(getState().STEELDIAMETER.reportId, reportPayload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
		},
	};
};

export const createRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {

			try {
				dispatch(start());
				const response = await api.post(apiUrls.create(id), action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().STEELDIAMETER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}

		};
const fetchReportList = async (id: any, payload?: any): Promise<any> => {
	const f_payload = { ...payload, ...id }
	return await api.post(apiUrls.reportList, f_payload).then(({ data }) => {
		return data;
	});
};

export const createReport =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {

			try {
				dispatch(start());
				const response = await api.post(apiUrls.createReport(id), action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().STEELDIAMETER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}

		};

export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.read(id));
				dispatch(setRead(response.data.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
export const readReport =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.readReport(id));
				dispatch(setReadReportData(response.data.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const getSteelDiameterList = (): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.getSteelDiameter);
		dispatch(setSteelDiameterList(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
export const getTestTypes = (id: number): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.post(apiUrls.testType(id));
		dispatch(setTestTypes(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const getLaboratoryName = (labId: any): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.laboratory);
		const laboratoryName = response.data.data?.filter((item?: any) => {
			if (item?.laboratory_type == labId) {
				return item
			}
		});
		dispatch(setLaboratoryName(laboratoryName));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const resetRead = (): AppThunk<any> => (dispatch) => {
	try {
		dispatch(start());
		dispatch(setRead(null));
		dispatch(success());
		return Promise.resolve();
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.delete, { id: id, project_id: getState().STEELDIAMETER.id, type: parseInt(getState().RAWMATERIAL.tab) + 1 });
				dispatch(success(response.data));
				dispatch(setupGrid(getState().STEELDIAMETER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const deleteReport =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.deleteReport, { id: id, project_id: getState().STEELDIAMETER.id, type: parseInt(getState().RAWMATERIAL.tab) + 1 });
				dispatch(success(response.data));
				dispatch(setupGrid(getState().STEELDIAMETER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const getDateRangeData = (): AppThunk<any> =>
	async (dispatch, getState) => {

		dispatch(setupGrid(getState().STEELDIAMETER.agGrid))
	}

const SteelSliceReducer = SteelSlice.reducer;
export default SteelSliceReducer;
