import React from "react";
import FirstStep from "./elements/FirstStep";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/app";

const FormComponent: React.FC<any> = () => {
	const REINFORCEMENT = useSelector((state: RootState) => state.REINFORCEMENT);
	return (
		<>
			<FirstStep
				dynamicReinforcement={REINFORCEMENT.dynamicReinforcement}
				onChange={undefined}
				handleSubmit={undefined} form={undefined} />
		</>
	);
};
export default FormComponent;
