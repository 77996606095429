import { validations } from '../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	radioButton: (field?: any) => [
		validations.required.other(field),
	],
	labAddress: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
}
