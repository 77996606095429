import { BreadcrumbConfigProps } from './InterfacesAndTypes'

const defaultBreadcrumbPath = [{ name: 'Dashboard', link: '/projects' }]

export const UserBreadcrumb = (name?: string | undefined): BreadcrumbConfigProps => ({
	title: 'User Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: name, link: '/users' },
	],
})
export const RoleBreadcrumb: BreadcrumbConfigProps = {
	title: 'Roles Management',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Roles', link: '/roles' }
	],
}
export const ProjectBreadcrumb = (
	Id?: string | undefined,
	title?: string | undefined,
): BreadcrumbConfigProps => ({
	title: `${title}`,
	path: [
		...defaultBreadcrumbPath,
		{
			name: `${title}`
		},
	],
})

export const ProjectDashboardBreadcrumb = (
	projectName: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Project',

	path: [
		...defaultBreadcrumbPath,
		{ name: projectName?`Project Name - ${projectName}`:`Project Name` },
	],
})

export const ViewProjectBreadcrumb = (
	title?: string | undefined,
): BreadcrumbConfigProps => ({
	title: `${title}`,
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{ name: `${title}` },
	],
})
export const ViewRoadReportBreadcrumb = (
	title?: string | undefined,
): BreadcrumbConfigProps => ({
	title: `${title}`,
	path: [
		...defaultBreadcrumbPath,
		{ name: `${title}` },
	],
})
export const RawMaterial = (
): BreadcrumbConfigProps => ({
	title: ' Raw Material Testing',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{ name: ' Raw Material Testing' },
	],
})
export const Report = (
	heading: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Consolidate Report',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: heading,
			link: heading == "Consolidate Report" ? `/projects/consolidate-report` : `/projects/raw-material`,
		},
		{ name: heading == "Consolidate Report" ? 'Steel Reports' : 'Reports' },
	],
})

export const MixDesign = (
): BreadcrumbConfigProps => ({
	title: 'Mix Design',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{ name: ' Mix Design' },
	],
})
export const AddMixDesign = (
): BreadcrumbConfigProps => ({
	title: 'Add Mix Design',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Mix Design',
			link: `/projects/mix-design`,
		},
		{
			name: `Add Mix Design`,
		},
	],
})
export const ViewMixDesign = (
): BreadcrumbConfigProps => ({
	title: 'View Mix Design',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Mix Design',
			link: `/projects/mix-design`,
		},
		{
			name: `View Mix Design`,
		},
	],
})
export const EditMixDesign = (
): BreadcrumbConfigProps => ({
	title: 'Edit Mix Design',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Mix Design',
			link: `/projects/mix-design`,
		},
		{
			name: `Edit Mix Design`,
		},
	],
})

export const StructureElementBreadcrumb = (
): BreadcrumbConfigProps => ({
	title: 'Structural Elements',
	path: [
		...defaultBreadcrumbPath,
		{
			name: 'Project Dashboard',
			link: `/projects/project-dashboard`,
		},
		{ name: 'Structural Elements' },
	],
})

export const PileElementBreadcrumb = (
): BreadcrumbConfigProps => ({
	title: 'Piles',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{ name: 'Piles' },
	],
})

export const AddPileElementBreadcrumb = (
	structureId: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Piles',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{
			name: 'Piles',
			link: `/projects/${structureId}/pile`,
		},
		{
			name: 'Add Pile',
		},
	],
})
export const EditPileElementBreadcrumb = (
	structureId: string | undefined,
	mappingId: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Piles',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{
			name: 'Piles',
			link: `/projects/${structureId}/pile`,
		},
		{
			name: `Edit Pile-${mappingId}`,
		},
	],
})

export const ViewPileElementBreadcrumb = (
	structureId: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Piles',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{
			name: 'Piles',
			link: `/projects/${structureId}/pile`,
		},
		{
			name: 'View Pile',
		},
	],
})

export const PileCapElementBreadcrumb = (
	title: string | undefined
): BreadcrumbConfigProps => ({
	title: `${title}`,
	path: [
		...defaultBreadcrumbPath,
		{
			name: 'Project Dashboard',
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{ name: `${title}` },
	],
})

export const AddPileCapElementBreadcrumb = (
	structureId: string | undefined,
	name: string | undefined,
	title: string | undefined,
	path: string | undefined
): BreadcrumbConfigProps => ({
	title: `${title}`,
	path: [
		...defaultBreadcrumbPath,
		{
			name: 'Project Dashboard',
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{
			name: `${title}`,
			link: `/projects/${structureId}/${path}`,
		},
		{
			name: `${name}`,
		},
	],
})

export const AddCheckList = (
	name: string | undefined,
): BreadcrumbConfigProps => ({
	title: `Check List`,
	path: [
		...defaultBreadcrumbPath,
		{
			name: 'Project Dashboard',
			link: `/projects/project-dashboard`,
		},
		{
			name: `Check List - ${name}`,
		},
	],
})

export const ConcretingBreadcrumb = (
	structureId: string | undefined,
	title: string | undefined,
	path: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Concreting',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{
			name: `${title}`,
			link: `/projects/${structureId}/${path}`,
		},
		{ name: 'Concreting' },
	],
})

export const ReinforcementBreadcrumb = (
	structureId: string | undefined,
	title: string | undefined,
	path: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Reinforcement',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: 'Structural Elements',
			link: `/projects/structure`,
		},
		{
			name: `${title}`,
			link: `/projects/${structureId}/${path}`,
		},
		{ name: 'Reinforcement' },
	],
})

export const DepartmentBreadcrumb: BreadcrumbConfigProps = {
	title: 'Department',
	path: [...defaultBreadcrumbPath, { name: 'Department' }],
}
export const DutyAssignmentBreadcrumb: BreadcrumbConfigProps = {
	title: 'Duty Assignment',
	path: [...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},

		{ name: ' Duty Assignment' },
	],
}
export const WorkCompletedBreadcrumb=(name:string | any): BreadcrumbConfigProps =>( {
	title: 'Duty Assignment',
	path: [...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},

		{ name:  `Work Completed ${name}` },
	],
})
export const WorkCompletedAddViewBreadcrumb=(title: string | undefined,name:string | any): BreadcrumbConfigProps =>( {
	title: 'Work Completed',
	path: [...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: `Work Completed ${name}`,
			link: `/projects/work-completed`,
		},

		{ name: `${title} Work Completed ${name}` },
	],
})
export const AuditLogBreadcrumb: BreadcrumbConfigProps = {
	title: 'Audit Logs',
	path: [...defaultBreadcrumbPath, { name: 'Audit Logs' }],
}
export const ProjectManagementBreadcrumb: BreadcrumbConfigProps = {
	title: 'Project Management',
	path: [...defaultBreadcrumbPath, { name: 'Project Management' }],
}
export const CementBrandBreadcrumb: BreadcrumbConfigProps = {
	title: 'Cement Brand',
	path: [...defaultBreadcrumbPath, { name: 'Cement Brand' }],
}

export const DepartmentTypeBreadcrumb: BreadcrumbConfigProps = {
	title: 'Department Type',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Department Type' },
	],
}

export const ProjectAttributeBreadcrumb: BreadcrumbConfigProps = {
	title: 'Project Attribute',
	path: [
		...defaultBreadcrumbPath,
		{ name: 'Project Attribute', link: '/master-data/project-attribute' },
	],
}
export const ProjectAttributeGroupBreadcrumb: BreadcrumbConfigProps = {
	title: 'Project Attribute Group',
	path: [...defaultBreadcrumbPath, { name: 'Project Attribute Group' }],
}

export const QAParamsGroupBreadcrumb: BreadcrumbConfigProps = {
	title: 'Structural Element Group',
	path: [...defaultBreadcrumbPath, { name: 'Structural Element Group' }],
}

export const QAElementBreadcrumb: BreadcrumbConfigProps = {
	title: 'Structural Element',
	path: [...defaultBreadcrumbPath, { name: 'Structural Element' }],
}

export const CubeTesting = (
	projectId: string | undefined,
	structureId: string | undefined,
	title: string | undefined,
	path: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Add Cube Testing',

	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: `Structural Elements`,
			link: `/projects/${structureId}/${path}`,
		},
		{
			name: `${title}`,
			link: `/projects/${structureId}/${path}`,
		},
		{ name: ' Cube Testing' },
	],
})
export const TestReportBreadcrumb = (
	projectId: string | undefined,
	structureId: string | undefined,
	mappingId: string | undefined,
	title: string | undefined,
	path: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Test Report',

	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: `Structural Elements`,
			link: `/projects/${structureId}/${path}`,
		},
		{
			name: `${title}`,
			link: `/projects/${structureId}/${path}`,
		},
		{
			name: `Cube Testing`,
			link: `/projects/${structureId}/${path}/${mappingId}/add-cubetesting`,
		},
		{ name: 'Test Report' },
	],
})

export const AddSteelBreadcrumb = (
	title: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Add Steel',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material Testing - Steel',
			link: `/projects/raw-material`,
		},
		{ name: `${title} Steel` },
	],
})
export const AddReportBreadcrumb = (
	steelId: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Add Report',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material Testing - Steel',
			link: `/projects/raw-material`,
		},
		{
			name: ' Reports',
			link: `/projects/raw-material/${steelId}/steel-report`,
		},
		{ name: 'Add Report' },
	],
})
export const ViewReportBreadcrumb = (
	steelId: string | undefined,
	heading: string | undefined,
): BreadcrumbConfigProps => ({
	title: 'View Report',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: heading,
			link: heading == "Consolidate Report" ? `/projects/consolidate-report` : `/projects/raw-material`,
		},
		{
			name: heading == "Consolidate Report" ? 'Steel Reports' : ' Reports',
			link: heading == "Consolidate Report" ? `/projects/raw-material/steel-report` : `/projects/raw-material/${steelId}/steel-report`,
		},
		{ name: `View Report` },
	],
})
export const AddCementBreadcrumb = (
	title: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Add Cement',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material  Testing - Cement',
			link: `/projects/raw-material`,
		},
		{ name: `${title} Cement` },
	],
})
export const AddCementReportBreadcrumb = (
	cementId: string | undefined,
	title: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Add Cement',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: !cementId && title == "View" ? 'Consolidated Report' : ' Raw Material  Testing - Cement',
			link: !cementId && title == "View" ? `/projects/consolidate-report` : `/projects/raw-material`,
		},
		{
			name: !cementId && title == "View" ? 'Cement Reports' : ' Reports',
			link: !cementId && title == "View" ? `/projects/raw-material/cement-report` : `/projects/raw-material/${cementId}/cement-report`,
		},
		{ name: `${title} Report` },
	],
})
export const CementReportBreadcrumb = (
	heading: string | undefined,
): BreadcrumbConfigProps => ({
	title: ' Add Cement',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: heading,
			link: heading == "Consolidate Report" ? `/projects/consolidate-report` : `/projects/raw-material`,
		},

		{ name: heading == "Consolidate Report" ? 'Cement Reports' : 'Reports' },
	],
})

export const AddFineAggregateBreadcrumb = (
	name: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Add Fine Aggregate',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material  Testing - Fine Aggregate',
			link: `/projects/raw-material`,
		},
		{ name: `${name}` }
	],
})

export const FineAggregateReportBreadcrumb = (
	id: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: id ? ' Raw Material  Testing - Fine Aggregate' : "Consolidate Report",
			link: id ? `/projects/raw-material` : `/projects/consolidate-report`,
		},
		{ name: id ? ' Reports' : 'Fine Aggregate Reports' },
	],
})
export const AddFineAggregateReportBreadcrumb = (
	fineAgid: string | undefined,
	name: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Add Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: fineAgid ? ' Raw Material  Testing - Fine Aggregate' : "Consolidate Report",
			link: fineAgid ? `/projects/raw-material` : `/projects/consolidate-report`,
		},
		{
			name: fineAgid ? 'Reports' : 'Fine Aggregate Reports',
			link: fineAgid ? `/projects/raw-material/${fineAgid}/fine-aggregate-report` : `/projects/raw-material/fine-aggregate-report`,
		},
		{ name: `${name}` },
	],
})
export const AddCoarseBreadcrumb = (
	title: string | undefined,
): BreadcrumbConfigProps => ({
	title: ' Add Coarse Aggregate',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material Testing - Coarse Aggregate',
			link: `/projects/raw-material`,
		},
		{ name: `${title} Coarse Aggregate` },
	],
})
export const CoarseReportBreadcrumb = (
	heading: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: heading,
			link: heading == "Consolidate Report" ? `/projects/consolidate-report` : `/projects/raw-material`,
		},
		{ name: heading == "Consolidate Report" ? 'Coarse Aggregate Reports' : ' Reports' },
	],
})
export const AddCoarseReportBreadcrumb = (
	coarseId: string | undefined,
	title: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Add Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: !coarseId && title == "View" ? 'Consolidated Report' : 'Raw Material  Testing - Coarse Aggregate',
			link: !coarseId && title == "View" ? `/projects/consolidate-report` : `/projects/raw-material`,
		},
		{
			name: !coarseId && title == "View" ? 'Coarse Aggregate Reports' : 'Reports',
			link: !coarseId && title == "View" ? `/projects/raw-material/coarse-aggregate-report` : `/projects/raw-material/${coarseId}/coarse-aggregate-report`,
		},
		{ name: `${title} Report` },
	],
})

export const AddConcreteAdmixtureBreadcrumb = (
	name: string | undefined
): BreadcrumbConfigProps => ({
	title: ' Add Concrete Admixture',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material  Testing - Concrete Admixture',
			link: `/projects/raw-material`,
		},
		{ name: `${name}` },
	],
})
export const ConcreteAdmixtureReportBreadcrumb = (
	id: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: id ? ' Raw Material  Testing - Concrete Admixture' : "Consolidate Report",
			link: id ? `/projects/raw-material` : `/projects/consolidate-report`,
		},
		{ name: id ? ' Reports' : 'Concrete Admixture Reports' },
	],
})
export const AddConcreteAdmixtureReportBreadcrumb = (
	concreteAddId: string | undefined,
	name: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Add Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: concreteAddId ? ' Raw Material  Testing - Concrete Admixture' : "Consolidate Report",
			link: concreteAddId ? `/projects/raw-material` : `/projects/consolidate-report`,
		},
		{
			name: concreteAddId ? 'Reports' : 'Concrete Admixture Reports',
			link: concreteAddId ? `/projects/raw-material/${concreteAddId}/concrete-mixture-report` : `/projects/raw-material/concrete-mixture-report`,
		},
		{ name: `${name}` },
	],
})

export const AddBindingWireBreadcrumb = (
	title: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Binding Wire',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material  Testing - Binding Wire',
			link: `/projects/raw-material`,
		},
		{ name: `${title} Binding Wire` },
	],
})
export const BindingWireReportBreadcrumb = (
	id: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: id ? ' Raw Material  Testing - Binding Wire' : "Consolidate Report",
			link: id ? `/projects/raw-material` : `/projects/consolidate-report`,
		},
		{ name: id ? ' Reports' : "Binding Wire Reports" },
	],
})
export const AddBindingWireReportBreadcrumb = (
	bindWiid: string | undefined,
	name: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Add Reports',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: bindWiid ? ' Raw Material  Testing - Binding Wire' : "Consolidate Report",
			link: bindWiid ? `/projects/raw-material` : `/projects/consolidate-report`,
		},
		{
			name: bindWiid ? 'Reports' : "Binding Wire Reports",
			link: bindWiid ? `/projects/raw-material/${bindWiid}/binding-wire-report` : `/projects/raw-material/binding-wire-report`,
		},
		{ name: `${name}` },
	],
})
export const PolymerSlurry = (
	projectId: string | undefined,
	structureId: string | undefined,
	title: string | undefined,
	path: string | undefined
): BreadcrumbConfigProps => ({
	title: 'Polymer Slurry',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: `Structural Elements`,
			link: `/projects/${structureId}/${path}`,
		},
		{ name: `${title}`, link: `/projects/${structureId}/${path}` },
		{ name: 'Polymer Slurry' },
	],
})

export const AddPolymerslurryTab = (
	name: string | undefined,
): BreadcrumbConfigProps => ({
	title: ' Add Polymer Slurry',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{
			name: ' Raw Material  Testing - Polymer Slury',
			link: `/projects/raw-material`,
		},
		{ name: `${name}` },
	],

})

export const ConsolidatedReportsBreadcrumb = (
): BreadcrumbConfigProps => ({
	title: 'Consolidated Report',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{ name: `Consolidated Report` },
	],
})
export const ProgressBreadcrumb = (
): BreadcrumbConfigProps => ({
	title: 'Progress',
	path: [
		...defaultBreadcrumbPath,
		{
			name: `Project Dashboard`,
			link: `/projects/project-dashboard`,
		},
		{ name: `Progress` },
	],
})
export const QuestionMasterBreadcrumb = (
): BreadcrumbConfigProps => ({
	title: 'Question Master',
	path: [
		...defaultBreadcrumbPath,
		{ name: `Question Master` },
	],
})

export const AddQuestionMasterBreadcrumb = (
	name: string | undefined,
): BreadcrumbConfigProps => ({
	title: `Add Question`,
	path: [
		...defaultBreadcrumbPath,
		{ name: `Question Master`,link:"/master-data/question-master" },
		{
			name: `${name}`,
		},

	],
})


export const LaboratoryBreadcrumb: BreadcrumbConfigProps = {
	title: 'Laboratory',
	path: [...defaultBreadcrumbPath, { name: 'Laboratory' }],
}

// create project dashboard
export const CreateProjectDashboardBreadcrumb: BreadcrumbConfigProps = {
	title: '',
	path: [{ name: 'Dashboard' }],
}

export const DesignConsultantBreadcrumb: BreadcrumbConfigProps = {
	title: 'Design Consultant',
	path: [...defaultBreadcrumbPath, { name: 'Design Consultant' }],
}

export const PmcBreadcrumb: BreadcrumbConfigProps = {
	title: 'PMC',
	path: [...defaultBreadcrumbPath, { name: 'PMC' }],
}

export const ContractorBreadcrumb: BreadcrumbConfigProps = {
	title: 'Contractor',
	path: [...defaultBreadcrumbPath, { name: 'Contractor' }],
}

export const DesignCodeBreadcrumb: BreadcrumbConfigProps = {
	title: 'Design Code',
	path: [...defaultBreadcrumbPath, { name: 'Design Code' }],
}

export const CategoryBreadcrumb: BreadcrumbConfigProps = {
	title: 'Category',
	path: [...defaultBreadcrumbPath, { name: 'Category' }]
}

export const TestTypeBreadcrumb: BreadcrumbConfigProps = {
	title: 'Test Type',
	path: [...defaultBreadcrumbPath, { name: 'Test Type' }]
}