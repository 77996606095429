import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { AuditLogBreadcrumb } from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Listing from "./components/Listing/Listing";
import { Button, DatePicker, Form } from "antd";
import { getUserList, setAuditData, setFilterByDate, setFilterData, setPerPageSize, setRead, setSelectedUserId } from "./utils/slice";
import { ModuleInfoProps } from "../../../config/InterfacesAndTypes";
import { checkPrivileges } from "../../../config/Global";
import RestrictedAccessPage from "../../errors/RestrictedAccessPage";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import ViewComponent from "./components/Forms/ViewComponent";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import dayjs from "dayjs";
import { CONSTANT } from "../../../config/Constant";

const moduleName = "Audit Log";

const AuditLog: React.FC<ModuleInfoProps> = (moduleInfo) => {
	const dispatch: AppDispatch = useDispatch();
	const { AUDIT_LOG, AUTH } = useSelector((state: RootState) => state)
	const [viewForm, setViewForm] = useState(false)
	const [form] = Form.useForm();

	//select row from ag-grid
	const onRowClick = (data?: any) => {
		dispatch(setAuditData(data.data));
		if (checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_DETAILS`)) {
			dispatch(setRead(data.data));
			setViewForm(true)
		}
	};

	//close view modal
	const closeModal = () => {
		setViewForm(false)
		//dispatch(refreshGrid())
	};

	const onChange = (dates: any) => {
		const d = dayjs(dates)?.format("YYYY-MM-DD");
		dispatch(setFilterByDate(d))
		dispatch(setFilterData())
	};

	//can't remove this code
	// const resetFilter = () => {
	//   setSelectedDate(null)
	//   setSelectedUser([])
	//   form.setFieldValue("selected_user", [])
	//   dispatch(setSelectedUser(null))
	//   dispatch(setFilterByDate(null))
	//   dispatch(setFilterData())
	//   dispatch(refreshGrid())
	// }

	const onChangeUser = (data: any) => {
		dispatch(setSelectedUserId(data))
		dispatch(setFilterData())

	}


	//export or download data as cvs
	const onDownloadCsv = () => {
		const params = {
			fileName: 'auditLogData.csv',
			allColumns: true,
		};

		if (AUDIT_LOG.gridApi) {
			AUDIT_LOG.gridApi.exportDataAsCsv(params);
		} else {
			//console.error('Grid API is not available.');
		}
	};

	useEffect(() => {
		dispatch(setAuditData(null))
	}, [])
	return (
		<div className="mainLayoutAuditWrapper">
			{
				!AUTH?.userDetail ? <PageSpinner /> : checkPrivileges(AUTH.userDetail, `${moduleInfo.permissionPrefix}_INDEX`) ? <>
					<ToolBar breadcrumbs={AuditLogBreadcrumb}>
						<div className="btnLists">
							<Button className="secondaryBtn" onClick={onDownloadCsv}>Export CSV</Button>
							<FormBox form={form}>
								<InputBox.Select
									showSearch
									allowClear
									name={"selected_user"}
									placeholder="Choose User"
									style={{ margin: "0 5px" }}
									onFocus={() => dispatch(getUserList())}
									options={{
										list: AUDIT_LOG.selectedUser,
										valueKey: "id",
										textKey: "full_name",
									}}
									onChange={onChangeUser}
									className="select auditSelect"
								/>
							</FormBox>
							<DatePicker className="dateUserPicker" onChange={onChange} format={CONSTANT.DATE_FORMAT} />
							<div className="auditBtn">
								<RecordPerPage
									defaultValue={AUDIT_LOG.perPageSize + " per page"}
									onChange={(perPageSize: number) => {
										dispatch(setPerPageSize(perPageSize));
									}}
								/>
							</div>
						</div>
					</ToolBar>
					<ContentBox>
						<ViewComponent
							open={viewForm}
							close={closeModal}
						/>
						<div className="listWrapper">
							<div className="auditListing"><div className="auditModuleList">
								<Listing
									moduleName={moduleName}
									rowData={AUDIT_LOG.rowData}
									onRowClick={onRowClick}
								/>
							</div>
							</div>
						</div>
					</ContentBox></> : <RestrictedAccessPage />
			}
		</div>
	);
};

export default AuditLog;
