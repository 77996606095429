import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import { ValueFormatterParams } from "ag-grid-community";
import { createdAtColumn, idColumn } from "../../../../../../../../../utils/commonColumns";

const ReportTypeColumn: ColDef = {
	field: "mst_test_type_name",
	headerName: "Report Type",
	filter: "agTextColumnFilter",
	tooltipValueGetter: (params: any) => params.data.mst_test_type_name,
};
const DateOfTestingColumn: ColDef = {
	field: "date_of_testing",
	headerName: "Date of Testing ",
	filter: "agDateColumnFilter",
	cellRenderer: (data?: any) => {
		return dayjs(data.data.date_of_testing).format("DD-MM-YYYY");
	},
	tooltipValueGetter: (params: any) => {
		return dayjs(params.data.date_of_testing).format("DD-MM-YYYY");
	},
};
const TestPassedColumn: ColDef = {
	field: "test_passed",
	headerName: "Test Passed",
	filter: "agSetColumnFilter",
	filterParams: {
		values: [1, 2],
		textCustomComparator: (filter: any, value: any) => {
			return (filter === "Yes" && value === 1) || (filter === "No" && value === 2)
		},
		valueFormatter: (params: ValueFormatterParams) => {
			return params.value === 1 ? "Yes" : params.value === 2 ? "No" : params.value;
		},
	},
	cellRenderer: (data: any) => {
		if (data.data.test_passed === 1) {
			return "Yes";
		} else if (data.data.test_passed === 2) {
			return "No";
		}
	},
	tooltipValueGetter: (params: any) => {
		if (params.data.test_passed === 1) {
			return "Yes";
		} else if (params.data.test_passed === 2) {
			return "No";
		}
	},
};

const columnDefs: ColDef[] = [
	idColumn,
	ReportTypeColumn,
	DateOfTestingColumn,
	TestPassedColumn,
	createdAtColumn
];
export default columnDefs;
