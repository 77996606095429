import { ColDef } from 'ag-grid-community'
import dayjs from 'dayjs'
import { idColumn } from '../../../../../../../utils/commonColumns'
import { CONSTANT } from '../../../../../../../config/Constant'

const DataEntryColumn: ColDef = {
	field: 'created_at',
	headerName: 'Created At',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
		return dayjs(data.data.created_at).format(CONSTANT.DATE_TIME_FORMAT)
	},
	tooltipValueGetter: (params: any) => {
		return dayjs(params.data.created_at).format(CONSTANT.DATE_TIME_FORMAT)
	},
}
const SupplierColumn: ColDef = {
	field: 'supplier_name',
	headerName: 'Name of Supplier',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.supplier_name,
}
const InvoiceNumberColumn: ColDef = {
	field: 'invoice_number',
	headerName: 'Invoice Number ',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.invoice_number,
}
const InvoiceDateColumn: ColDef = {
	field: 'invoice_date',
	headerName: 'Invoice Date',
	filter: 'agDateColumnFilter',
	cellRenderer: (data?: any) => {
		return dayjs(data.data.invoice_date).format(CONSTANT.DATE_FORMAT)
	},
	tooltipValueGetter: (params: any) => {
		return dayjs(params.data.invoice_date).format(CONSTANT.DATE_FORMAT)
	},
}


const columnDefs: ColDef[] = [
	idColumn,
	SupplierColumn,
	InvoiceNumberColumn,
	InvoiceDateColumn,
	DataEntryColumn,

]
export default columnDefs
