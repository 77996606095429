/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Row, UploadFile } from 'antd'
import React, { useEffect, useState } from 'react'
import DynamicForm from './DynamicForm'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../store/app'
import { getDynamicFieldType, readRecord } from '../../../utils/slice'

interface ComponentProps {
	onSubmit: any
	form: any
	formId: string
	onChange: any
	multipleSection?: any;
	setMultipleSection?: any
	multipleCostSharing?: any;
	setMultipleCostSharing?: any;
	isMultiple?: any;
	setIsMultiple?: any;
}
const ThirdStep: React.FC<ComponentProps> = ({
	onSubmit,
	form,
	formId,
	onChange,
	multipleSection, setMultipleSection,
	isMultiple, setIsMultiple
}: ComponentProps) => {
	const PROJECT = useSelector((state: RootState) => state.PROJECT)
	const dispatch: AppDispatch = useDispatch()
	const projectAttributesGroupId = 2
	const [fileListImage, setFileListImage] = useState<UploadFile[]>([]);

	const id = localStorage.getItem("projectId")

	const onFinish = (data: any) => {
		onSubmit({
			...data,
			project_attributes_group_id: projectAttributesGroupId,
			project_id: id,
		})
	}
	const setDynamicField = () => {

		dispatch(
			getDynamicFieldType({
				project_id: id,
				project_attributes_group_id: projectAttributesGroupId,
			})
		)
			.then(() => {
				if (location.pathname.includes('/read')) {
					dispatch(
						readRecord(id, {
							project_attributes_group_id:
								projectAttributesGroupId,
						})
					)
						.then((readData: any) => {
							const setData: any = [];
							readData?.data?.project_attr_value?.length == 0 && setIsMultiple(true)
							readData?.data?.project_attr_value?.map(
								(item: any) => {
									//const data = JSON.parse(item?.attribute_json)
									//if (data?.photo_required == true) {
									// form.setFieldValue(
									// 	readData?.data?.image_31[0]?.qa_params_value_id,
									// 	list
									// )
									//}
									form.setFieldValue(
										[item.project_attributes_id, "value"],
										item.attribute_value
									)
									if (item?.attribute_type == "Radio Button" && item?.project_document?.length > 0) {
										form.setFieldValue([item.project_attributes_id, "value"], item?.attribute_value)
										const list = item?.project_document?.map(
											(image: { id: any; document: any }) => {
												return {
													uid: image.id,
													name: image?.document,
													status: "done",
													url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
													thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												};
											}
										)

										form.setFieldValue(
											[item.project_attributes_id, "file"],
											list
										)

									}
									else if (item?.attribute_type == "File") {
										const list = item?.project_document?.map(
											(image: { id: any; document: any }) => {
												return {
													uid: image.id,
													name: image?.document,
													status: "done",
													url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
													thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												};
											}
										)

										form.setFieldValue(
											[item.project_attributes_id, "file"],
											list
										)
									}

									else if (Array?.isArray(item)) {
										item?.length == 0 && setIsMultiple(true)
										const val: any = []
										let multiple: any
										item?.map((att: any, parentIndex: any) => {
											if (att?.attribute_type == "Multiple") {
												if (!setData[`${att?.project_attributes_id}`]) {
													setData[`${att?.project_attributes_id}`] = [];
												}
												const dd = [JSON.parse(att.attribute_value)]
												dd?.map((d: any) => {
													val.push(d)
												})

												const list = att?.project_document?.map(
													(image: { id: any; document: any }) => {
														return {
															uid: image.id,
															name: image?.document,
															status: "done",
															url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
															thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
														};
													}
												)

												const attr_json = JSON.parse(att?.data)
												dd?.map((data: any, index_att: any) => {
													Object.keys(data).map((key) => {
														form.setFieldValue([att.project_attributes_id, "value", parentIndex + 1, key], data[key])
														form.setFieldValue([att.project_attributes_id, "file", parentIndex + 1, list?.length > 0 ? 2 : key], list)
													})
													setData[`${att?.project_attributes_id}`].push({
														qa_param_id: att?.project_attributes_id,
														id: parentIndex + 1,
														att_json: attr_json,
														type: "read",
														node_id: att?.id
													})
												})
												setMultipleSection(setData)
											}

										})
									}

									else if (item && (item?.attribute_key == "section" || item?.attribute_key == "cost_sharing_agency" || item?.attribute_key == "construction_drawing_upload")) {
										const dd = [JSON.parse(item.attribute_value)]
										const attr_json = JSON.parse(item?.data)

										if (!setData[`${item?.project_attributes_id}`]) {
											setData[`${item?.project_attributes_id}`] = [];
										}
										const list = item?.project_document?.map(
											(image: { id: any; document: any }) => {
												return {
													uid: image.id,
													name: image?.document,
													status: "done",
													url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
													thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
												};
											}
										)
										dd?.map((data: any, index: any) => {
											Object.keys(data).map((key) => {
												form.setFieldValue([item.project_attributes_id, "value", index + 1, key], data[key])
												form.setFieldValue([item.project_attributes_id, "file", index + 1, list?.length > 0 ? 2 : key], list)

											})
											setData[`${item?.project_attributes_id}`].push({
												qa_param_id: item?.project_attributes_id,
												id: index + 1,
												att_json: attr_json,
												type: "read",
												node_id: item?.id
											})
										})
										// multiple?.map((multipleItem: any) => {
										// 	setData[`${item?.project_attributes_id}`].push(multipleItem)
										// })
										setMultipleSection(setData)
									}
								}
							)
							setFileListImage(readData?.data?.image_31 ?? []);
							const list = readData?.data?.image_31?.map(
								(image: { id: any; document: any }) => {
									return {
										uid: image.id,
										name: image?.document,
										status: "done",
										url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
										thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document}`,
									};
								}
							)

							form.setFieldValue(
								[readData?.data?.image_31[0]?.qa_params_value_id, "file"],
								list
							)
							// onChange()

						})
						.catch((error: any) => error)
				}
				onChange()
			})
			.catch((error: any) => error)
	}
	useEffect(() => {
		setDynamicField()
	}, [])

	useEffect(() => {
		//
	}, [PROJECT.dynamicFieldType])
	return (
		<>
			<Form form={form} onFinish={onFinish} id={formId} onChange={onChange} className="thirdStep">
				<Row gutter={10}>
					<DynamicForm
						dynamicFieldType={PROJECT.dynamicFieldType}
						onChange={onChange}
						form={form}
						formId={formId}
						imageData={fileListImage}
						multipleSection={multipleSection}
						setMultipleSection={setMultipleSection}
						isMultiple={isMultiple}
					/>
				</Row>
			</Form>
		</>
	)
}
export default ThirdStep
