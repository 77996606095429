import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const departmentColumn: ColDef = {
	field: 'department_name',
	headerName: 'Department Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.department_name,
}

const columnDefs: ColDef[] = [
	idColumn,
	departmentColumn,
	createdAtColumn,
	updatedAtColumn,
]
export default columnDefs
