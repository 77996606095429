import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'antd'
import { InputBox } from '../../../../../../../components/AntdAddons'
import TableComponent from './TableComponent'
import { rules } from './rules'
import ToolBar from '../../../../../../../components/ToolBar'
import { AddConcreteAdmixtureBreadcrumb } from '../../../../../../../config/BreadcrumbConfig'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'antd/es/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../../store/app'
import { setResetRead, read, createRecord } from '../utils/slice'
import { setTab } from '../../../../utils/slice'
import {
	dataToFormDataConverter,
	validateFields,
} from '../../../../../../../config/Global'
import { assignErrorToInput } from '../../../../../../../store/api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const AddConcreteMixer: React.FC = () => {
	const [form] = useForm()
	const { concreteAdid } = useParams()
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [date, setDate] = useState<any>()
	const [receiptDate, setReceiptDate] = useState<any>()
	const [, setState] = useState(false);
	const dispatch: AppDispatch = useDispatch()
	const navigate = useNavigate()
	const CONCRETE_ADMIXTURE = useSelector(
		(state: RootState) => state.CONCRETE_ADMIXTURE
	)

	useEffect(() => {
		if (location.pathname.includes('/view-concrete-admixture')) {
			dispatch(setResetRead())
			form.resetFields()
			dispatch(read(Number(concreteAdid)))
		}
	}, [])
	useEffect(() => {
		if (location.pathname.includes('/view-concrete-admixture')) {
			const details = CONCRETE_ADMIXTURE.read

			form.setFieldsValue({
				supplier_name: details?.supplier_name,
				invoice_number: details?.invoice_number,
				invoice_date: dayjs(details?.invoice_date),
				total_liters: details?.total_liters,
				receipt_date: dayjs(details?.receipt_date),
				document_name: details?.attached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							//uid: image.id,
							name: image?.document_name,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						}
					}
				),
				mtc_attachment: details?.mtcattached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							//uid: image.id,
							name: image?.document_name,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.document_name}`,
						}
					}
				)
			})

			details?.relation_with_concrete_admixture_test_required &&
				details?.relation_with_concrete_admixture_test_required.map(
					(field: any) => {
						form.setFieldValue(
							[
								'test_types',
								field.mst_test_type_id,
								'mst_test_type_id',
							],
							field.id
						)
						form.setFieldValue(
							[
								'test_types',
								field.mst_test_type_id,
								'test_type_value',
							],
							field.test_type_value
						)
					}
				)
			setState(prevState => !prevState);
		}
	}, [CONCRETE_ADMIXTURE.read, concreteAdid, setState])

	const onFinish = (data: any) => {
		if (data.test_types) {
			data.test_types = JSON.stringify(Object.values(data.test_types))
		}

		const payload = {
			...data,
			invoice_date: date,
			receipt_date: receiptDate,
		}
		const formData = dataToFormDataConverter(payload)

		try {
			setLoading(true)
			setDisabled(true)
			dispatch(createRecord(Number(localStorage.getItem('projectId')), formData))
				.then(() => {
					setLoading(false)
					setDisabled(false)
					dispatch(setTab(4))
					form.resetFields()
					return navigate(`/projects/raw-material`)
				})
				.catch((error: any) => {
					setLoading(false)
					setDisabled(false)
					assignErrorToInput(form, error?.STATUS)
				})
		} catch (error) {
			console.error('Error submitting data:', error)
		}
	}

	const onDateChange = (dates: any) => {
		const d = dayjs(dates).format('YYYY-MM-DD')
		setDate(d)
	}

	const onReceiptDateChange = (dates: any) => {
		const d = dayjs(dates).format('YYYY-MM-DD')
		setReceiptDate(d)
	}

	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	const handleCancel = () => {
		dispatch(setTab(4))
		navigate(`/projects/raw-material`)
	}

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes(
						`/projects/raw-material/add-concrete-admixture`
					)
						? AddConcreteAdmixtureBreadcrumb(
							'Add Concrete Admixture'
						)
						: AddConcreteAdmixtureBreadcrumb(
							'View Concrete Admixture'
						)
				}
			>
				<div className="diableBtnWrap"></div>
			</ToolBar>

			<Form
				className="formLayout"
				form={form}
				onFinish={onFinish}
				onValuesChange={validateForm}
			>
				<div className="formHeading">
					<h3>
						{location.pathname.includes('/add-concrete-admixture')
							? 'Add '
							: 'View '}{' '}
						Concrete Admixture
					</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Text
								label="Name of Supplier"
								name="supplier_name"
								rules={rules.dynamicFields()}
								disabled={concreteAdid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Invoice No."
								name="invoice_number"
								rules={rules.dynamicFields()}
								disabled={concreteAdid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								label="Invoice Date"
								name="invoice_date"
								onChange={onDateChange}
								rules={rules.dateFields()}
								disabled={concreteAdid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Total Liters"
								name="total_liters"
								rules={rules.numberFields()}
								disabled={concreteAdid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.DatePicker
								label="Date of Receipt of Admixture"
								name="receipt_date"
								onChange={onReceiptDateChange}
								rules={rules.dateFields()}
								disabled={concreteAdid ? true : false}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={true}
								label="Attach Invoice "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-concrete-admixture")
								}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="mtc_attachment"
								required={true}
								label="Attach MTC "
								fileList={form.getFieldValue('mtc_attachment')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-concrete-admixture")
								}
							/>
						</Col>
					</Row>
				</div>

				<TableComponent form={form} />

				<div className="steelBtn">
					<Button
						htmlType="submit"
						className="secondaryBtn"
						loading={loading}
						disabled={disabled}
					>
						Submit
					</Button>
					<Button
						className="secondaryBtn cancelBtn"
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</>
	)
}

export default AddConcreteMixer
