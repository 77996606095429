import { ColDef } from "ag-grid-community";
import {
	idColumn,
	nameColumn,
} from "../../../../../utils/commonColumns";

const countColumn: ColDef = {
	field: "user_count",
	headerName: "Number of Users",
	filter: "agNumberColumnFilter",
	tooltipValueGetter: (params: any) => params.data.user_count,
};

const columnDefs: ColDef[] = [
	idColumn,
	nameColumn,
	countColumn
];
export default columnDefs;
