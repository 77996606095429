import React, { useState } from "react";
import ToolBar from "../../../components/ToolBar";
import { ViewRoadReportBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import FormComponent from "./Components/Forms/FormComponent";
import Listing from "./Components/Listing/Listing";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/app";

const ViewRoadReport: React.FC = () => {
	const { ROAD_REPORT } = useSelector((state: RootState) => state)
	const [reportId, setReportId] = useState()
	const [data, setData] = useState()
	return (
		<>
			<ToolBar breadcrumbs={ViewRoadReportBreadcrumb("View Road Report")}>

			</ToolBar>
			<ContentBox>
				<div className="mainUpperHeadingContainer">
					<FormComponent 
					reportId={reportId}
					setReportId={setReportId}
					data={data}
					setData={setData}
					/>
				</div>
				<br/><br/>
				{data && <div className="mainUpperHeadingContainer">
					<Listing 
					rowData={ROAD_REPORT.rowData}
					netCount={ROAD_REPORT.netCount}
					reportId={reportId}
					/>
				</div>}
			</ContentBox>
		</>
	);
};

export default ViewRoadReport;