import React, { useState } from "react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import { changeStatus, setupGrid } from "../../utils/slice";
import columnDefs, { archiveColumnDefs } from "./columnDefs";
import ChangeStatusModal from "../../../../../components/ChangeStatus";
import { checkPrivileges } from "../../../../../config/Global";

const Listing: React.FC<any> = ({
	onRowClick,
	rowData,
	archivedList,
	moduleInfo,
	user
}) => {

	const [statusData, setStatusData] = useState<any>();

	const statusPermission = checkPrivileges(user, `${moduleInfo?.permissionPrefix}_CHANGE_STATUS`)


	return (
		<>
			<ChangeStatusModal
				titleName={moduleInfo.title}
				data={statusData}
				close={() => setStatusData(null)}
				callApi={changeStatus}
			/>
			<AgGridWrapper
				type="serverSide"
				rowData={rowData}
				columnDefs={!archivedList && statusPermission != false ? columnDefs : archiveColumnDefs}
				onGridReadyWithDispatch={setupGrid}
				onRowClicked={onRowClick}
				rowSelection={"single"}
				context={{
					setStatusData,

				}}
			/>
		</>
	);
};

export default Listing;
