import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AddClick } from "../../../../../assets/images/icon/plusIcon.svg";
import { ReactComponent as RemoveClick } from "../../../../../assets/images/icon/remove-minus.svg";
import { AppDispatch, RootState } from "../../../../../store/app";
import {
	getDepartmentList,
	setDepartmentList,
} from "../../../Project/utils/slice";
import {
	categoriesList,
	groupList,
	inputTypeList,
	readRecord,
	setCategoriesList,
	setGroupList,
	setInputType,
} from "../../utils/slice";
import { rules } from "../Forms/rules";
import { useNavigate, useParams } from "react-router-dom";
import { AddQuestionMasterBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import ToolBar from "../../../../../components/ToolBar";

interface FormComponentProps {
	form: any;
	id: string;
	handleSubmit: any;
	onValueChange: any;
	editValues?: any;
	saving?: any
	disabled?: any
	setInputData?: any;
	inputData?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	handleSubmit,
	onValueChange,
	editValues,
	saving,
	disabled,
	setInputData,
	inputData
}) => {
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate()
	const { questionId } = useParams()
	const { QUESTION_MASTER, PROJECT } = useSelector(
		(state: RootState) => state
	);
	const [name, setName] = useState("")
	const [count, setCount] = useState(1)
	const selectInputType = form.getFieldValue("input_type") && ![3, 4].includes(form.getFieldValue("input_type"))
	let random;

	const handleClose = () => {
		navigate("/master-data/question-master")
	}

	useEffect(() => {
		getDepartmentList();
	}, []);

	useEffect(() => {
		if (questionId) {
			dispatch(readRecord(Number(questionId)))
		}

	}, [])

	useEffect(() => {
		const listDataCount = QUESTION_MASTER.count.count && QUESTION_MASTER.count.count
		const local: any = localStorage.getItem("count")
		if (listDataCount <= 0) {
			localStorage.setItem("count", JSON.parse(listDataCount))
		}
		else {
			localStorage.removeItem('count')
		}
		if (local == 0) {
			form.setFieldValue('is_display_column', 1);
		} else {
			form.setFieldValue('is_display_column', 0)
		}
	}, [QUESTION_MASTER, form])

	useEffect(() => {
		if (editValues) {
			const values =
				editValues?.data != undefined ? JSON.parse(editValues?.data) : {};
			const inputOptions =
				editValues?.input_options != undefined
					? JSON.parse(editValues?.input_options)
					: [];

			dispatch(setDepartmentList([
				{
					id: editValues?.department_id,
					name: editValues?.department_name
				}
			])
			);
			dispatch(
				setCategoriesList([
					{
						id: editValues?.qa_category_id,
						name: editValues?.qa_category_name,
					},
				])
			);
			dispatch(
				setGroupList([
					{
						id: editValues?.qa_params_group_id,
						name: editValues?.qa_params_group_name,
					},
				])
			);
			let inputType;
			if (editValues?.input_type == "Drop Down") {
				inputType = 1;
			}
			if (editValues?.input_type == "Radio Button") {
				inputType = 2;
			}
			if (editValues?.input_type == "Text Box") {
				inputType = 3;
			}
			if (editValues?.input_type == "Date Picker") {
				inputType = 4;
			}
			//can't remove this code
			// if (editValues?.input_type == "Checkbox") {
			// 	inputType = 5;
			// }

			dispatch(
				setInputType([
					{ id: inputType, name: editValues?.input_type }
				])
			);
			form.setFieldsValue({
				department_id: editValues?.department_id,
				params_label_full_text: editValues?.params_label_full_text,
				short_question_id: editValues?.params_label_short_text,
				qa_category_id: editValues?.qa_category_id,
				qa_params_group_id: editValues?.qa_params_group_id,
				input_type: inputType,
				photo_required: values?.photo_required,
				is_display_column: editValues?.is_display_column,
				answer_required: values?.required,
				first_disable: values?.first_textbox_disabled,
				remark_display: values?.remark_display,
				remark_required: values?.remarks_required_text_area,
				remark_id: values?.type,
			});
			random = inputOptions.map((i: any, index: any) => ({
				id: String(index + 1),
				name: i.name,
			}));
			setCount(random.length + 1)
			setInputData(random);
		}
	}, [editValues, form]);

	//Add option
	const handleAdd = () => {
		if (name != "" && name.length <= 20) {
			setInputData([...inputData, { id: String(count), name: name }])
			form.setFieldValue("input_option", "")
			String(setCount(count + 1))
			setName("")
		}
	}

	//Remove option
	const handleRemove = (id: any) => {
		const data = inputData?.filter((d?: any) => d?.id !== id)
		setInputData(data)
	}
	return (
		<>
			<ToolBar breadcrumbs={
				location.pathname.includes("/add-question") ?
					AddQuestionMasterBreadcrumb("Add Question") : AddQuestionMasterBreadcrumb("Edit Question")}></ToolBar>
			<div className="addQuestionPage">


				<FormBox
				id={id}
				form={form}
				onChange={onValueChange}
					onFinish={handleSubmit}>

					<Row gutter={30}>
						<Col span={12} className="detailCol">
							<div className="detailPage">
								<div className="projectTitle">
									<h2>
										Details
									</h2>
								</div>
								<Row gutter={25}>
									<Col span={24}>
										<InputBox.Select
											label="Department"
											className="select"
											name="department_id"
											onFocus={() => dispatch(getDepartmentList())}
											rules={rules.selectFirstFields()}
											options={{
												list: PROJECT.departmentList,
												valueKey: "id",
												textKey: "department_name",
											}}
											onChange={() => {
												onValueChange()
											}}
										/>
									</Col>
									{/* <Form.Item
										noStyle
										shouldUpdate={(prevValues, currentValues) =>
											prevValues.department_id !== currentValues.department_id
										}
									>
										{({ getFieldValue }) => {
											const selectedDepartment = getFieldValue("department_id");
											if (selectedDepartment) {
												return ( */}
													<Col span={24}>
														<InputBox.Select
															label="Elements"
															className="select"
															name="qa_category_id"
															onFocus={() => {
																dispatch(categoriesList(form.getFieldValue("department_id")));
															}}
															rules={rules.selectFirstFields()}
															options={{
																list: QUESTION_MASTER.categoriesList,
																valueKey: "id",
																textKey: "name",
															}}
															onChange={() => {
																onValueChange()
															}}
														/>
													</Col>
												{/* );
											}
											return null;
										}}
									</Form.Item> */}
									<Col span={24}>
										<Form.Item
											noStyle
											shouldUpdate={(prevValues, currentValues) =>
												prevValues.department_id !== currentValues.department_id
											}
										>
											{() => (
												<InputBox.Select
													label="Group"
													className="select"
													name="qa_params_group_id"
													options={{
														list: QUESTION_MASTER.groupList,
														valueKey: "id",
														textKey: "name",
													}}
													rules={rules.selectFirstFields()}
													onFocus={() => {
														dispatch(groupList());
													}}
													onChange={() => {
														onValueChange()
													}}
												/>
											)}
										</Form.Item>
									</Col>

								</Row>
							</div>
						</Col>
						<Col span={12} className="detailCol">
							<div className="detailPage">
								<div className="projectTitle">
									<h2>
										Questions
									</h2>
								</div>
								<Row gutter={25}>
									<Col span={24}>
										<InputBox.Checkbox
											label="Do you want to disallow editing?"
											name={"first_disable"}
											className="checkboxWrapper"
											onChange={(e: any) =>
												form.setFieldValue("first_disable", e.target.checked ? true : false)
											}
										/>
									</Col>
									<Col span={24}>
										<InputBox.TextArea
											label="Question"
											name="params_label_full_text"
											rules={rules.dynamicFields()}
											onChange={() => {
												onValueChange()
											}}
										/>
									</Col>
									<Col span={24}>
										<InputBox.TextArea
											label="Short Question"
											name="short_question_id"
											rules={rules.dynamicFields()}
											onChange={() => {
												onValueChange()
											}}
										/>
									</Col>
								</Row>
							</div>

						</Col>
						<Col span={12} className="detailCol">
							<div className="detailPage">
								<div className="projectTitle">
									<h2>
										Options
									</h2>
								</div>
								<Row gutter={25}>
									<Col span={24}>
										<InputBox.Select
											label="Input Type"
											name="input_type"
											className="select"
											onFocus={() => dispatch(inputTypeList())}
											options={{
												list: QUESTION_MASTER.inputType,
												valueKey: "id",
												textKey: "name",
											}}
											rules={rules.selectFirstFields()}
											onChange={(data?: any) => {
												form.setFieldValue("input_type", data);
												setInputData([])
												onValueChange()
											}}
										/>
									</Col>
									{
										selectInputType && (
											<>
												<Row className="optionBox">
													<div style={{ width: "100%" }}>
														<InputBox.Text
															name={"input_option"}
															label="Options"
															onChange={(e) => setName(e.target.value)}
															rules={[
																{
																	required: inputData?.length > 0 ? false : true,
																	message: "please enter option",
																	whitespace: true,
																},
																{
																	max: 20,
																	message: "Maximum length is 20 characters",
																}]}
														/>
													</div>
													<div className="optionBtn">
														{
															inputData?.length < 10 &&
															<Button onClick={handleAdd}>
																<AddClick style={{ width: "14px", height: "14px" }} />
															</Button>
														}
													</div>
												</Row>
												{
													inputData?.map((data: any) => {
														return <>
															<Row className="optionBox" style={{ marginBottom: "15px", width: "100%" }}>
																<div style={{ width: "100%", position: "relative" }}><InputBox.Text value={data?.name} disabled /></div>
																<div className="optionBtn" style={{ top: "8px" }}><Button onClick={() => handleRemove(data?.id)}>
																	<RemoveClick style={{ width: "16px", height: "16px" }} />
																</Button></div>
															</Row>
														</>
													})
												}
											</>
										)
									}
								</Row>
							</div>
						</Col>
						<Col span={12} className="detailCol">
							<div className="detailPage">
								<div className="projectTitle">
									<h2>
										Validation
									</h2>
								</div>
								<Row>
									<Col span={12}>
										<InputBox.Checkbox
											label=" Do you want to keep the answer required for this question?"
											name={"answer_required"}
											className="checkboxWrapper"
											onChange={(e: any) =>
												form.setFieldValue(
													"answer_required", e.target.checked ? true : false,
												)
											}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Checkbox
											label="Do you want to enable the upload document feature for this question?"
											name={"photo_required"}
											className="checkboxWrapper"
											onChange={(e: any) =>
												form.setFieldValue(
													"photo_required", e.target.checked ? 1 : 0,
												)
											}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Checkbox
											label="Do you want to display this field in the listing?"
											name={"is_display_column"}
											className="checkboxWrapper"
											onChange={(e: any) =>
												form.setFieldValue(
													"is_display_column", e.target.checked ? true : false)
											}
										/>
									</Col>
									<Col span={12}>
										<InputBox.Checkbox
											label="Please select the type of data that users can input in the remark field for this question"
											name={"remark_display"}
											className="checkboxWrapper"
											onChange={(e: any) => {
												form.setFieldValue(
													"remark_display", e.target.checked ? 1 : 0,
												);
											}}
										/>
									</Col>
									<Col span={24}>
										<Row>
											<Form.Item
												noStyle
												shouldUpdate={(prevValues, currentValues) =>
													prevValues.remark_display !== currentValues.remark_display
												}
											>
												{({ getFieldValue }) => {
													const selectRemarkDisplay = getFieldValue("remark_display");
													if (selectRemarkDisplay) {
														return (
															<>
																<Col span={12}>
																	<InputBox.Checkbox
																		label="Do you want to make the remark field mandatory for this question?"
																		name={"remark_required"}
																		className="checkboxWrapper"
																		onChange={(e: any) =>
																			form.setFieldValue(
																				"remark_required", e.target.checked ? true : false,
																			)
																		}
																	/>
																</Col>
																<Col span={12}>
																	<InputBox.Radio
																		label="Please select the type of data that users can input in the remark field for this question."
																		name={"remark_id"}
																		rules={rules.radioButton()}
																		// onChange={(e: any) =>
																		//     form.setFieldValue(
																		//         "remark_id", e.target.checked ? 1 : 0,
																		//     )
																		// }			
																		options={{
																			list: [
																				{ id: "text", value: "text", name: "Text" },
																				{
																					id: "number",
																					value: "number",
																					name: "Number",
																				},
																			],
																		}}
																	/>
																</Col>
															</>
														);
													}
												}}
											</Form.Item>
										</Row>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<Row>
						<div key={1} className="bottomBtnRow">
							<Button
								className="secondaryBtn"
								htmlType="submit"
								form={id}
								disabled={disabled}
								loading={saving}
							>
								Save
							</Button>
							<Button className="cancelBtn"
								onClick={handleClose}
							>
								Cancel
							</Button>
						</div>
					</Row>



				</FormBox>





			</div>

		</>
	);
};

export default FormComponent;
