import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'antd'
import { TestReportBreadcrumb } from '../../../../config/BreadcrumbConfig'
import ToolBar from '../../../../components/ToolBar'
import { dataToFormDataConverter, structureElementBreadcrumbName, structureElementBreadcrumbUrl, validateFields, } from '../../../../config/Global'
import { AppDispatch, RootState } from '../../../../store/app'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { createTestReport, setDynamicCubeTestingData, setResetRead } from '../utils/slice'
import { assignErrorToInput } from '../../../../store/api'
import { InputBox } from '../../../../components/AntdAddons'
import dayjs from 'dayjs'
import { CONSTANT } from '../../../../config/Constant'

const TestReport: React.FC = () => {
	const dispatch: AppDispatch = useDispatch()
	const { state } = useLocation();
	const sevenDayReport = dayjs(state?.cube_casting_date).add(7, 'day').format(CONSTANT.DATE_FORMAT);
	const twentyDayReport = dayjs(state?.cube_casting_date).add(28, 'day').format(CONSTANT.DATE_FORMAT);
	const [form] = Form.useForm()
	const { CUBE_TESTING } = useSelector((state: RootState) => state)
	const pileNo = localStorage.getItem('pileNo')
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [, setState] = useState(false);
	const { structureId, mappingId, cubeTestId, mName } = useParams()
	const pro_id: any = localStorage.getItem('projectId')

	const handleSubmit = (data?: any) => {

		const payload = {
			...data,
		}
		const formData = dataToFormDataConverter(payload)
		setLoading(true)
		setDisabled(true)
		dispatch(createTestReport(cubeTestId, formData))
			.then(() => {
				setDisabled(false)
				setLoading(false)
				form.resetFields()
				navigate(
					`/projects/${structureId}/${mName}/${mappingId}/add-cubetesting`
				)
				dispatch(setDynamicCubeTestingData([]))
			})
			.catch((error: any) => {
				setLoading(false)
				setDisabled(false)
				assignErrorToInput(form, error?.STATUS)
			})
	}
	useEffect(() => {
		if (state) {
			dispatch(setResetRead())
			form.resetFields()
		}
	}, [CUBE_TESTING?.dynamicCubeTestingData])

	const handleCancel = () => {
		dispatch(setDynamicCubeTestingData([]))
		navigate(
			`/projects/${structureId}/${mName}/${mappingId}/add-cubetesting`
		)
	}
	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	useEffect(() => {

		if (state) {
			/* this is set for getting required error when field have already file */

			form.setFieldsValue({
				day_7_report: state?.sevenDayReport?.map(
					(image: { id: any; day_7_report: any }) => {
						return {
							name: image?.day_7_report,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.day_7_report}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.day_7_report}`,
						}
					}
				),
				day_28_report: state?.tweentyDayReport?.map(
					(image: { id: any; day_28_report: any }) => {
						return {
							name: image?.day_28_report,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.day_28_report}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image.day_28_report}`,
						}
					}
				)
			})
			setState(prevState => !prevState);
		}
	}, [CUBE_TESTING?.dynamicCubeTestingData, setState])
	const name = structureElementBreadcrumbName(structureId)
	const endUrl = structureElementBreadcrumbUrl(structureId)

	return (
		<>
			<ToolBar
				breadcrumbs={TestReportBreadcrumb(
					pro_id,
					structureId,
					mappingId,
					name,
					endUrl
				)}
			></ToolBar>
			<div className="formHeading">
				<h3>Test Report</h3>
			</div>
			<Form
				form={form}
				onFinish={handleSubmit}
				onValuesChange={validateForm}
				className="addReportForm"
			>
				<div>
					<Row>
						<Col span={12}>
							<span className="pileNumber">
								Pile No. - {`${pileNo}`}
							</span>
						</Col>
					</Row>
					<Row className="concreteForm cubeReportForm">
						<Col
							span={24}
							className="uploadRow deleteIconDisplay"
							style={{ marginTop: '20px' }}
						>
							<InputBox.Upload
								name="day_7_report"
								required={!(CUBE_TESTING?.dynamicCubeTestingData?.sevenDayReport &&
									CUBE_TESTING?.dynamicCubeTestingData?.sevenDayReport.length > 0)}
								label={`7-Days Report`}
								fileList={form.getFieldValue('day_7_report')}
								maxCount={10}
								disabled={false}
							/>
							<span className='reportDueDate'>(Due date - {sevenDayReport})</span>
						</Col>
						<Col
							span={24}
							className="uploadRow deleteIconDisplay"
							style={{ marginTop: '20px' }}
						>
							<InputBox.Upload
								name="day_28_report"
								required={false}
								label={`28-Days Report`}
								fileList={form.getFieldValue('day_28_report')}
								maxCount={10}
								disabled={false}
							/>
							<span className='reportDueDate'>(Due date - {twentyDayReport})</span>
						</Col>
						<div
							className="ReportBtn"
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								width: '100%',
							}}
						>
							<Button
								className="secondaryBtn"
								htmlType="submit"
								disabled={disabled}
								loading={loading}
							>
								Submit
							</Button>
							<Button
								className="secondaryBtn"
								onClick={handleCancel}
							>
								Cancel
							</Button>
						</div>
					</Row>
				</div>
			</Form>
		</>
	)
}

export default TestReport
