import React, { useEffect } from "react";
import { Row } from "antd";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { rules } from "./rules";


interface FormComponentProps {
	form: any
	id: string
	handleSubmit: any
	onValuesChange: any
	editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
	form,
	id,
	handleSubmit,
	onValuesChange,
	editValues,
}) => {

	useEffect(() => {
		if (editValues) {
			form.setFieldsValue(editValues);
		}
	}, [editValues, form]);

	const onFinish = (data: any) => {
		handleSubmit(data);
	};

	return (
		<>
			<FormBox
				form={form}
				id={id}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
			>
				<Row gutter={25}>
					<InputBox.Text
						label="Name of Laboratory"
						name="laboratory_name"
						rules={rules.dynamicFields()}
						onChange={onValuesChange}
					/>


				</Row>
				<Row gutter={25}>
					<InputBox.Radio
						name="laboratory_type"
						label="Laboratory Type"
						rules={rules.radioButton()}
						options={{
							list: [
								{
									id: 1,
									value: "NABL Lab",
									name: "NABL Lab",
								},
								{
									id: 2,
									value: "Government lab",
									name: "Government Lab",
								},
								{
									id: 3,
									value: "On-site Lab",
									name: "On-site Lab",
								},
							],
						}}
					/>
				</Row>
				<Row gutter={25}>
					<InputBox.TextArea
						label="Address of Laboratory"
						name="laboratory_address"
						rules={rules.labAddress()}
						onChange={onValuesChange}
					/>


				</Row>
			</FormBox>
		</>
	);
};

export default FormComponent;
