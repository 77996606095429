import React, { useEffect } from "react";
import ToolBar from "../../../components/ToolBar";
import {
	ProjectBreadcrumb,
	ProjectDashboardBreadcrumb,
} from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { Card, Col, Row } from "antd";
import { ReactComponent as RawMaterial } from "../../../assets/images/icon/RawMaterial.svg";
import { ReactComponent as MixDesign } from "../../../assets/images/icon/mixDesign.svg";
import { ReactComponent as Steel } from "../../../assets/images/icon/steel.svg";
import { ReactComponent as StandardElements } from "../../../assets/images/icon/standardElement.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/icon/cardArrow.svg";
import { ReactComponent as ConsolidateIcon } from "../../../assets/images/icon/consolidateIcon.svg";
import { ReactComponent as ProgressIcon } from "../../../assets/images/icon/progress.svg";
import { ReactComponent as DutyAssign } from "../../../assets/images/icon/dutyIcon.svg";
import { ReactComponent as ViewEdit } from "../../../assets/images/icon/planningViewEdit.svg";
import { ReactComponent as WorkPlant } from "../../../assets/images/icon/workPlantIcon.svg";
import { ReactComponent as QaIcon } from "../../../assets/images/QAIcon.svg";
import bgImg from "../../../assets/images/projectCardDesign.png";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTab } from "../RawMaterial/utils/slice";

const ProjectDashboard: React.FC<any> = () => {
	const dispatch: AppDispatch = useDispatch();
	const { AUTH } = useSelector((state: RootState) => state);
	const pro_name: any = localStorage.getItem('projectName')
	const deptType: any = localStorage.getItem("department_id")
	const navigate = useNavigate();
	//const isCheckList: any = localStorage.getItem("isCheckListFill")
	const workCompletedData: any = localStorage.getItem("WorkCompleted")

	const data: any = workCompletedData ? JSON.parse(workCompletedData) : []

	useEffect(() => {
		dispatch(setTab(0))
	}, [])
	const CardJson = [
		{
			id: 1,
			title: "Progress",
			icon: <ProgressIcon />,
			link: `/projects/progress`,
			name: "PROGRESS_REPORT_INDEX"
		},
		{
			id: 2,
			title: "Raw Material Testing",
			icon: <RawMaterial />,
			link: `/projects/raw-material`,
			name: "INVENTORY_INDEX"
		},
		{
			id: 3,
			title: "Mix Design",
			icon: <MixDesign />,
			link: `/projects/mix-design`,
			name: "MIXDESIGN_INDEX"
		},
		{
			id: 4,
			title: "Structural Elements",
			icon: <StandardElements />,
			link: `/projects/structure`,
			name: "STRUCTURE_ELEMENT_INDEX"
		},
		{
			id: 5,
			title: "View/Edit Project Information",
			icon: <Steel />,
			link: `/projects/read`,
			name: "PROJECTS_DETAILS"
		},
		{
			id: 6,
			title: "Consolidate Report",
			icon: <ConsolidateIcon />,
			link: `/projects/consolidate-report`,
			name: "CONSOLIDATE_REPORT_INDEX"
		}
	];

	const CardJsonPlanning = [
		{
			id: 1,
			title: "Quality Assurance Plan(QAP)",
			icon: <QaIcon />,
			//link: `/projects/progress`,
			name: "PROGRESS_REPORT_INDEX"
		},
		{
			id: 2,
			title: "Mix Design",
			icon: <MixDesign />,
			link: `/projects/mix-design`,
			name: "MIXDESIGN_INDEX"
		},
		{
			id: 3,
			title: "Raw Material Testing",
			icon: <RawMaterial />,
			link: `/projects/raw-material`,
			name: "INVENTORY_INDEX"
		},
		{
			id: 4,
			title: "View/Edit Planning Information",
			icon: <ViewEdit />,
			link: `/projects/read`,
			name: "PROJECTS_DETAILS"
		},
		{
			id: 5,
			title: "Duty Assignment",
			icon: <DutyAssign />,
			link: `/projects/duty-assignment`,
			name: "DUTY_ASSIGNMENT_INDEX"
		},

		{
			id: 6,
			title: `Work Completed at ${data?.name}`,
			icon: <WorkPlant />,
			link: `/projects/work-completed`,
			name: "WORKCOMPETED_INDEX"
		}
	];
	// const bridge = {
	//  id: 4,
	//  title: "Structural Elements",
	//  icon: <StandardElements />,
	//  link: `/projects/structure`,
	//  name: "STRUCTURE_ELEMENT_INDEX"
	// }
	// const checkList = {
	//  id: 4,
	//  title: "Check List",
	//  icon: <StandardElements />,
	//  link: isCheckList !== "No_data" ? `/projects/checklist/read` : `/projects/checklist/add`,
	//  name: "STRUCTURE_ELEMENT_INDEX"
	// }
	// const structure_ele = [
	//  ...CardJson.slice(0, 3),
	//  bridge,
	//  ...CardJson.slice(3)
	// ];
	// const check_list = [
	//  ...CardJson.slice(0, 3),
	//  checkList,
	//  ...CardJson.slice(3)
	// ];
	const final_cardJson = parseInt(deptType) == 3 ? CardJsonPlanning : CardJson
	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes("/project-dashboard")
						? ProjectDashboardBreadcrumb(pro_name)
						: ProjectBreadcrumb()
				}
			></ToolBar>
			<ContentBox>
				<Row className="cardWrapper">
					{
						AUTH?.userDetail?.userPrivileges?.includes("PROJECTS_INDEX") &&
						<>
							{final_cardJson.map((item: any, index: any) => {
								return (
									<>
										{AUTH?.userDetail?.userPrivileges?.includes(item?.name) &&
											<Col key={index} style={{ margin: "0px 0px 15px 0" }} span={6}>
												<Card className="cardWrap" onClick={() => navigate(item?.link)}>
													<div className="cardContent">
														<div className="cardIcon">{item.icon}</div>
														<h2>{item.title}</h2>
													</div>
													<img src={bgImg} />
													<div className="arrowIcon">
														<ArrowIcon />
													</div>
												</Card>
											</Col>
										}
									</>
								);
							})}
						</>
					}
				</Row>
			</ContentBox>
		</>
	);
};

export default ProjectDashboard;