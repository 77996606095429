import { RcFile } from "antd/lib/upload";
import CryptoJS from "crypto-js";
import { debounce } from "lodash";
import { CONSTANT } from "./Constant";
import moment from "moment";
import { notification } from "antd";

export const Constant = {
	API_KEY: "secret-api-key",
};

export const uploadedFileOnPreview = async (file: any) => {
	let src = file.url as string;
	if (!src) {
		src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		});
	}

	const image = new Image();
	image.src = src;
	const imgWindow: any = window.open(src);
	imgWindow.document.write(image.outerHTML);
};

const insertAt = (array: any[], index: number, ...elementsArray: any[]) => {
	array.splice(index, 0, ...elementsArray);
};

export const convertTextToID = (
	textArray: any,
	mainArray: any,
	textKey = "name",
	idKey = "id"
) => {
	const newArray: any = [];
	if (textArray && textArray.values && textArray.values.length > 0) {
		textArray.values.forEach((x: any) => {
			const temp = mainArray.find((y: any) => y[textKey] === x);
			if (x && temp) {
				newArray.push(temp[idKey]);
			} else {
				insertAt(newArray, 0, x);
			}
		});
	}

	return newArray;
};

export const copyTextToClipboard = (textToCopy: any) => {
	if (navigator.clipboard && window.isSecureContext) {
		// navigator clipboard api method'
		return navigator.clipboard.writeText(textToCopy);
	} else {
		// text area method
		const textArea = document.createElement("textarea");
		textArea.value = textToCopy;
		// make the textarea out of viewport
		textArea.style.position = "fixed";
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		return new Promise<void>((res, rej) => {
			// here the magic happens
			document.execCommand("copy") ? res() : rej();
			textArea.remove();
		});
	}
};

export const stringEncryption = (string: string) => {
	return CryptoJS.AES.encrypt(string, Constant.API_KEY)
		.toString()
		.replace(/\+/g, "xMl3Jk")
		.replace(/\//g, "Por21Ld")
		.replace(/=/g, "Ml32");
};

export const stringDecryption = (string: string) => {
	string = string
		.replace(/xMl3Jk/g, "+")
		.replace(/Por21Ld/g, "/")
		.replace(/Ml32/g, "=");
	return CryptoJS.AES.decrypt(string, Constant.API_KEY).toString(
		CryptoJS.enc.Utf8
	);
};

export const listStatus = [
	{
		id: 0,
		name: "Pending"
	},
	{
		id: 1,
		name: "Submitted"
	},

]

export const listParamsStatus: any = {
	0: "Pending",
	1: "Submitted",
}

export const typeStatus = [
	{
		id: "2",
		name: "Dry"
	},
	{
		id: "1",
		name: "Wet"
	},
]
export const typeParamsStatus: any = {
	2: "Dry",
	1: "Wet"
}


function hasBlankValue(obj: any): boolean {
    console.log(obj, "obj----->");

    if (typeof obj !== "object" || obj === null) {
        // Check if the object itself is empty, null, or undefined
        return obj === "" || obj === null || obj === undefined;
    }

    // If it is an array, check for any empty element
    if (Array.isArray(obj)) {
        for (const item of obj) {
            if (hasBlankValue(item)) {
                return true;
            }
        }
        return false;
    }

    // If it is an object, check its properties
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // Check if the current key is "value" and if it's empty
            if (key === "value" && (obj[key] === "" || obj[key] === null || obj[key] === undefined)) {
                return true;
            }
            
            // Check if the current key is "file" and if it's an empty array or has any blank file content
            if (key === "file" && Array.isArray(obj[key])) {
                if (obj[key].length === 0) {
                    return true;  // If file array is empty
                }
                for (const file of obj[key]) {
                    if (file === null || file === undefined || (file.originFileObj === null || file.originFileObj === undefined)) {
                        return true;  // If file object is null or undefined
                    }
                }
            }

            // Recursively check nested objects or arrays
            if (hasBlankValue(obj[key])) {
                return true;
            }
        }
    }

    return false;  // If no blank values found
}

function isDataEmpty(data: Record<string, any>): boolean {
	for (const key in data) {
		if (data[key] !== undefined) {
			if (data[key]?.value === null || data[key]?.value === "") {
				return true; // Found a null or blank "value"
			} else if (typeof data[key] === "object" && isDataEmpty(data[key])) {
				return true; // There's at least one blank or null "value" in this object or its nested objects
			}
		}
	}
	return false; // No null or blank "value" properties found in the entire data object
}

//check object and return true and false
export const objectBlank = (data: any) => {
	return isDataEmpty(data);
};

export const singleObjectBlank = (data: any) => {
	return hasBlankValue(data);
};

export const dataToFormDataConverter = (data: any, appendName?: any, appendFormData?: any) => {
	let formData: any;
	if (!appendFormData) {
		formData = new FormData();
	} else {
		formData = appendFormData;
	}
	for (const name in data) {
		let value = data[name];
		if (value !== undefined && value !== null) {
			if (Array.isArray(value)) {
				value.forEach((item, index) => {
					if (item?.originFileObj) {
						item = item?.originFileObj;
					}
					const fieldName = appendName ? `${appendName}[${name}][${index}]` : `${name}[${index}]`;
					formData.append(fieldName, item);
				});
			} else if (!value?.originFileObj && typeof value === "object" && value !== null) {
				if (appendName) {
					dataToFormDataConverter(value, `${appendName}[${name}]`, formData);
				} else {
					dataToFormDataConverter(value, `${name}`, formData);
				}
			} else {
				if (value.originFileObj) {
					value = value?.originFileObj;
				}
				if (appendName) {
					formData.append(`${appendName}[${name}]`, value);
				} else {
					formData.append(`${name}`, value);
				}
			}
		}
	}
	return formData;
};


export const b64toBlob = (
	b64Data: string,
	contentType = "",
	sliceSize = 512
): File => {
	const byteCharacters = Buffer.from(b64Data, "base64").toString("binary");
	const byteArrays: Uint8Array[] = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new File(byteArrays, "pot", { type: contentType });
};

export const export_PDF = (data: any) => {
	if (data && data.pdfBlob) {
		// Convert base64 to Blob
		const byteCharacters = atob(data.pdfBlob);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const file = new Blob([byteArray], { type: "application/pdf" });

		// Build a URL from the file
		const fileURL = URL.createObjectURL(file);

		// Create a link element, set the download attribute with a filename, and trigger a click
		const link = document.createElement('a');
		link.href = fileURL;
		link.download = 'road_report.pdf'; // You can name the PDF file here
		link.click();

		// Cleanup: revoke the object URL after downloading
		URL.revokeObjectURL(fileURL);
	}
};

export const validateFields = debounce((form, setDisabled) => {
	form
		.validateFields()
		.then(() => {
			setDisabled(false);
		})
		.catch(() => {
			setDisabled(true);
		});
}, 500);

export const dateFormatter = (
	date: any,
	format = CONSTANT.DATE_FORMAT
) => {
	return moment(date).format(format);
};

export const snakeCaseString = (str: any): string => {
	return (
		str &&
		str
			.match(
				/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
			)
			.map((s: any) => s.toLowerCase())
			.join("_")
	);
};

export const camelCaseString = (str: string): string => {
	str = str
		.replace(/[-_]+/g, " ")
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
			return index !== 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/ (.)/g, function ($1: any) {
			return $1.toUpperCase();
		});
	return str;
};

/**
 * We use notification from here 2 type of nofiifcation
 * Success: {placement:"bottomRight",message: "Your Success message"}
 * Error: {placement:"bottomRight",message: "Your Error Message"}
 */
export const Notification = {
	success: (data: any) => {
		notification.success({
			placement: data.placement ? data.placement : "bottomRight",
			duration: 3,
			...data,
		});
	},
	warning: (data: any) => {
		notification.warning({
			placement: data.placement ? data.placement : "bottomRight",
			duration: 3,
			...data,
		});
	},
	error: (data: any) => {
		notification.error({
			placement: data.placement ? data.placement : "bottomRight",
			duration: 3,
			...data,
		});
	},
};

export const checkPrivileges = (
	userDetail: any,
	permissionType: string
): boolean =>
	!!(
		userDetail?.userPrivileges &&
		userDetail?.userPrivileges.includes(permissionType)
	);
export const blockInvalidChar = (e?: any) => ['+', '-'].includes(e.key) && e.preventDefault();



export const isActiveStatusValue = [
	{
		id: 0,
		name: "InActive"
	},
	{
		id: 1,
		name: "Active",
	},

]

export const structureElementBreadcrumbName = (structureId: any) => {
	const name =
		structureId === "1"
			? "Pile"
			: structureId === "2"
				? "Pile Cap"
				: structureId === "3"
					? "Pier"
					: structureId === "4"
						? "Pier Cap"
						: structureId === "5"
							? "Bearing"
							: structureId === "6"
								? "Grider(PSC or Cast in situ)+Deck Slab"
								: structureId === "7"
									? "Box Girder Span/Voided Grider(Obligatory)"
									: structureId === "8"
										? "RT Wall"
										: structureId === "9"
											? "Solid Slab"
											: structureId === "13"
												? "Field Survey"
												: structureId === "14"
													? "RCC Pipe Manufacturing"
													: structureId === "15"
														? "Pipe Laying"
														: structureId === "16"
															? "Machine Hole Work"
															: structureId === "17"
																? "Ventilating Column"
																: structureId === "18"
																	? "Commission of Project"
																	: structureId === "19"
																		? "Field Survey"
																		: structureId === "20"
																			? "RCC Pipe Manufacturing"
																			: structureId === "21"
																				? "Tunneling Pit"
																				: structureId === "22"
																					? "Pipe Laying"
																					: structureId === "23"
																						? "Machine Hole Work"
																						: structureId === "24"
																							? "Ventilating Column"
																							: structureId === "25"
																								? "Commission of Project"
																								: structureId === "25"
																									? "Commission of Project"
																									: structureId === "26"
																										? "Field Survey"
																										: structureId === "27"
																											? "RCC Pipe Manufacturing"
																											: structureId === "28"
																												? "MS Pipe/DI Pipe Rising Main"
																												: structureId === "29"
																													? "Inlet Chamber"
																													: structureId === "30"
																														? "Wet Well"
																														: structureId === "31"
																															? "Pump House"
																															: structureId === "32"
																																? "Electromechanical Room"
																																: structureId === "33"
																																	? "Service Area"
																																	: structureId === "34"
																																		? "Inspection Bay"
																																		: structureId === "35"
																																			? "Discharge Line"
																																			: structureId === "36"
																																				? "Trial Run"
																																				: structureId === "37"
																																					? "Field Survey"
																																					: structureId === "38"
																																						? "RCC Pipe Manufacturing"
																																						: structureId === "39"
																																							? "MS Pipe/DI Pipe Rising Main"
																																							: structureId === "40"
																																								? "Inlet Chamber"
																																								: structureId === "41"
																																									? "Wet Well"
																																									: structureId === "42"
																																										? "Pump House"
																																										: structureId === "43"
																																											? "Electromechanical Room"
																																											: structureId === "44"
																																												? "Service Area"
																																												: structureId === "45"
																																													? "Discharge Line"
																																													: structureId === "46"
																																														? "Trial Run"
																																														: structureId === "47"
																																															? "Field Survey"
																																															: structureId === "48"
																																																? "Longitudinal Section"
																																																: structureId === "49"
																																																	? "Trial Pit"
																																																	: structureId === "50"
																																																		? "Alignment Fixing"
																																																		: structureId === "51"
																																																			? "Flow Diversion"
																																																			: structureId === "52"
																																																				? "Temporary/Permanent Dummy"
																																																				: structureId === "53"
																																																					? "Desilting Work"
																																																					: structureId === "54"
																																																						? "Rehab of Pipe"
																																																						: structureId === "55"
																																																							? "Lining Work"
																																																							: structureId === "56"
																																																								? "Inspection or post lining CCTV"
																																																								: structureId === "57"
																																																									? "Removing of Dummy"
																																																									: structureId === "58"
																																																										? "Commission of Project"
																																																										: structureId === "62"
																																																											? "Thermoplastic Paint"
																																																											: structureId === "63"
																																																												? "Strip Seal Expansion Joint"
																																																												: structureId === "64"
																																																													? "Mastic Asphalt"
																																																													: "";
	return name
}

export const structureElementBreadcrumbUrl = (structureId: any) => {
	const endUrl =
		structureId === "1"
			? "pile"
			: structureId === "2"
				? "pile-cap"
				: structureId === "3"
					? "pier"
					: structureId === "4"
						? "pier-cap"
						: structureId === "5"
							? "bearing"
							: structureId === "6"
								? "grider"
								: structureId === "7"
									? "box_girder_span"
									: structureId === "8"
										? "rt_wall"
										: structureId === "9"
											? "solid_slab"
											: structureId === "13"
												? "field_survey"
												: structureId === "14"
													? "rcc_pipe_manufacturing"
													: structureId === "15"
														? "pipe_laying"
														: structureId === "16"
															? "machine_hole_work"
															: structureId === "17"
																? "ventilating_column"
																: structureId === "18"
																	? "commission_of_project"
																	: structureId === "19"
																		? "field_survey"
																		: structureId === "20"
																			? "rcc_pipe_manufacturing"
																			: structureId === "21"
																				? "tunneling_pit"
																				: structureId === "22"
																					? "pipe_laying"
																					: structureId === "23"
																						? "machine_hole_work"
																						: structureId === "24"
																							? "ventilating_column"
																							: structureId === "25"
																								? "commission_of_project"
																								: structureId === "26"
																									? "field_survey"
																									: structureId === "27"
																										? "rcc_pipe_manufacturing"
																										: structureId === "28"
																											? "ms_pipe_di_pipe_rising_main"
																											: structureId === "29"
																												? "inlet_chamber"
																												: structureId === "30"
																													? "wet_well"
																													: structureId === "31"
																														? "pump_house"
																														: structureId === "32"
																															? "electromechanical_room"
																															: structureId === "33"
																																? "service_area"
																																: structureId === "34"
																																	? "inspection_bay"
																																	: structureId === "35"
																																		? "discharge_line"
																																		: structureId === "36"
																																			? "trial_run"
																																			: structureId === "37"
																																				? "field_survey"
																																				: structureId === "38"
																																					? "rcc_pipe_manufacturing"
																																					: structureId === "39"
																																						? "ms_pipe_di_pipe_rising_main"
																																						: structureId === "40"
																																							? "inlet_chamber"
																																							: structureId === "41"
																																								? "wet_well"
																																								: structureId === "42"
																																									? "pump_house"
																																									: structureId === "43"
																																										? "electromechanical_room"
																																										: structureId === "44"
																																											? "service_area"
																																											: structureId === "45"
																																												? "discharge_line"
																																												: structureId === "46"
																																													? "trial_run"
																																													: structureId === "47"
																																														? "field_survey"
																																														: structureId === "48"
																																															? "longitudinal_section"
																																															: structureId === "49"
																																																? "trial_pit"
																																																: structureId === "50"
																																																	? "alignment_fixing"
																																																	: structureId === "51"
																																																		? "flow_diversion"
																																																		: structureId === "52"
																																																			? "temporary_permanent_dummy"
																																																			: structureId === "53"
																																																				? "desilting_work"
																																																				: structureId === "54"
																																																					? "rehab_of_pipe"
																																																					: structureId === "55"
																																																						? "lining_work"
																																																						: structureId === "56"
																																																							? "inspection_or_post_lining_cctv"
																																																							: structureId === "57"
																																																								? "removing_of_dummy"
																																																								: structureId === "58"
																																																									? "commission_of_project"
																																																									: structureId === "62"
																																																										? "thermoplastic_paint"
																																																										: structureId === "63"
																																																											? "strip_seal_expansion_joint"
																																																											: structureId === "64"
																																																												? "mastic_asphalt"
																																																												: "";
	return endUrl
}


