import { ColDef } from 'ag-grid-community'
import {
	createdAtColumn,
	idColumn,
	updatedAtColumn,
} from '../../../../../utils/commonColumns'

const ProjectAttributeGroup: ColDef = {
	field: 'name',
	headerName: 'Name',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.name,

}
const ProjectAttributeGroupOrder: ColDef = {
	field: 'order',
	headerName: 'order',
	filter: 'agTextColumnFilter',
	tooltipValueGetter: (params: any) => params.data.order,
}
const columnDefs: ColDef[] = [
	idColumn,
	ProjectAttributeGroup,
	ProjectAttributeGroupOrder,
	createdAtColumn,
	updatedAtColumn,
]
export default columnDefs
