import { createSlice } from "@reduxjs/toolkit";
import type { Action, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../config/Config";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { convertTextToID, listStatus, typeStatus } from "../../../../config/Global";

interface InitialState {
	isLoading: boolean;
	rowData: any;
	qaParams: any;
	read: any;
	count: any;
	perPage: any;
	agGrid: any;
	filter: any;
	projectId: any;
	selectedData: any;
	dynamicReinforcement: any;

}

const initialState = {
	isLoading: false,
	rowData: null,
	qaParams: null,
	read: null,
	count: 0,
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	agGrid: null,
	filter: null,
	projectId: null,
	selectedData: null,
	dynamicReinforcement: null

} as InitialState;

const PileCapSlice = createSlice({
	name: "PILE_CAP",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload;
		},
		setCount: (state, action: PayloadAction<any>) => {
			state.count = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action.payload
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},

		setQaParams: (state, action: PayloadAction<any>) => {
			state.qaParams = action?.payload;
		},
		// Add a reducer to set the filter criteria
		setFilter: (state, action: PayloadAction<any>) => {
			state.filter = action.payload;
		},
		setProjectId: (state, action: PayloadAction<any>) => {
			state.projectId = action?.payload;
		},
		// Add a reducer to clear the filter criteria
		clearFilter: (state) => {
			state.filter = null;
		},
		setSelectedData: (state, action: PayloadAction<any>) => {
			state.selectedData = action?.payload;
		},
		setDynamicReinforcement: (state, action: PayloadAction<any>) => {
			state.dynamicReinforcement = action?.payload;
		},
	},
});

export const {
	start,
	success,
	failure,
	setRead,
	setRowData,
	setQaParams,
	setCount,
	setGrid,
	setPerPage,
	setFilter, clearFilter, setProjectId,
	setSelectedData,
	setDynamicReinforcement
} = PileCapSlice.actions;

export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().PILE_CAP.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				dispatch(setPerPage(getState().PILE_CAP.perPage));
				const dataSource = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
			} catch (error: any) {
				//
			}
		};


const changeFilterAndSort = (params: any) => {
	params;
	if (params.filterModel && params.filterModel["is_submit"]) {
		params.filterModel["is_submit"].values = convertTextToID(
			params.filterModel["is_submit"], listStatus, 'name', 'id'
		);
	}
	if (params.filterModel && params.filterModel["type_of_pile_value"]) {
		params.filterModel["type_of_pile_value"].values = convertTextToID(
			params.filterModel["type_of_pile_value"], typeStatus, 'name', 'id'
		);
	}
};

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
	return await api.post(apiUrls.list, payload).then(({ data }) => {
		return data;
	});
};

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions,
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request);
			const payload = getServerListPayload(params);
			const data = {
				project_id: getState().PILE_CAP.projectId.project_id,
				qa_category_id: getState().PILE_CAP.projectId.qa_category_id,
				department_id:localStorage.getItem("department_id"),
				...payload
			}
			dispatch(setSelectedData(null))
			fetchList(data).then((data) => {
				dispatch(setCount(data));
				params.success({ rowData: data?.rows, rowCount: data?.count });
				dispatch(setRowData({ rowData: data?.rows, rowCount: data?.count,dynamicColumns:data?.dynamicColumns }))
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			});
		},
	};
};

export const getQaParams =
	(action?: any): AppThunk<any> =>
		async (dispatch) => {
			try {

				const response = await api.post(
					apiUrls.qaParams,
					action
				);
				dispatch(setQaParams(response.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const createRecord =
	(action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create, action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().PILE_CAP.projectId));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const updateRecord =
	(action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(
					apiUrls.update(action.qa_params_value_mapping_id),
					action
				);
				dispatch(success(response.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};
export const readRecord =
	(action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(
					apiUrls.read(action.qa_params_value_mapping_id),
					action
				);
				dispatch(setRead(response.data.resultData));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const imageDelete =
	(id?: any): AppThunk<any> =>
		async () => {
			try {
				const response = await api.post(
					apiUrls.removeImage, { "id": id }
				);
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const nodeDelete =
	(payload?: any): AppThunk<any> =>
		async () => {
			try {
				const response = await api.post(
					apiUrls.node_delete, payload
				);
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const ChangeStatus =
	(id?: any, data?: any): AppThunk<any> => async (dispatch, getState) => {
		try {
			const response = await api.post(
				apiUrls.change_status(id), data
			);
			dispatch(setupGrid(getState().PILE_CAP.agGrid));
			return Promise.resolve(response.data);
		} catch (error: any) {
			return Promise.reject(error.data);
		}
	};

const PileCapSliceReducer = PileCapSlice.reducer;
export default PileCapSliceReducer;
