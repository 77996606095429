import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InputBox } from "../../../../../../components/AntdAddons";
import { AppDispatch, RootState } from "../../../../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentList, getDepartmentTypeList, getRoadPlanningWardList, getUserList } from "../../../utils/slice";
import { rules } from "../rules";
import moment from "moment";
import { getDesignConsultantList } from "../../../../DesignConsultant/utils/slice";
import { getPMCList } from "../../../../Pmc/utils/slice";
import { getContractorList } from "../../../../Contractor/utils/slice";
import { getDesignCodeList } from "../../../../DesignCode/utils/slice";

interface ComponentProps {
	onSubmit: any
	form: any
	formId: string
	onChange: any
	isShow: any
	setIsShow: any
}
const FirstStep: React.FC<ComponentProps> = ({ onSubmit, form, formId, onChange, isShow, setIsShow }: ComponentProps) => {
	const dispatch: AppDispatch = useDispatch();
	const { PROJECT, DESIGN_CONSULTANT, AUTH, CONTRACTOR, DESIGN_CODE, PMC } = useSelector((state: RootState) => state)
	const tab: any = localStorage.getItem("tab");
	const [designConsultant, setDesignConsultant] = useState(false)
	const [plantSite, setPlantSite] = useState(null);
	const [paverSite, setPaverSite] = useState(null);

	const disabledDate = (current: any) => {
		return current && current > moment().endOf('day')
	};

	useEffect(() => {
		if (AUTH?.userDetail?.firm_type == 4) {
			dispatch(getDesignConsultantList())
			setDesignConsultant(true)
			form.setFieldValue("design_consultant_id", AUTH?.userDetail?.firm_name)
			form.setFields([
				{
					name: 'design_consultant_id',
					errors: [],
				},
			])
		}
	}, [!designConsultant, AUTH?.userDetail?.firm_type == 4])

	return (
		<>
			{
				tab == 2 && <div className="formHeading">
					<h3>{location.pathname.includes("/projects/read") ? "View - Edit " : "Add "}Planning</h3>
				</div>
			}
			<Form
				form={form}
				onFinish={onSubmit}
				id={formId}
				onValuesChange={onChange}
			>
				<Row gutter={25}>
					{
						tab != 2 ?
							<>
								<Col span={12}>
									<InputBox.Select
										label="Name of Department"
										name="department_id"
										disabled={
											location.pathname.includes('/read')
										}
										required
										onFocus={() => dispatch(getDepartmentList())}
										options={{
											list: PROJECT.departmentList,
											valueKey: "id",
											textKey: "department_name",
										}}
										rules={rules.selectFirstFields()}
										onChange={(depId) => {
											localStorage.setItem("depId", depId)
											form.setFieldValue("department_type_id", null);
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										label="Type"
										disabled={
											location.pathname.includes('/read')
										}
										name="department_type_id"
										onFocus={() => dispatch(getDepartmentTypeList(form.getFieldValue("department_id")))}
										options={{
											list: PROJECT.departmentTypeList,
											valueKey: "id",
											textKey: "type_name",
										}}
										rules={rules.selectFirstFields()}
										onChange={() => {
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Name of Work"
										name="project_name"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
										onChange={() => {
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										label="Design Consultant"
										// disabled={
										// 	location.pathname.includes('/read') || (location.pathname.includes('/create') && designConsultant)
										// }
										name="design_consultant_id"
										onFocus={() => dispatch(getDesignConsultantList())}
										options={{
											list: DESIGN_CONSULTANT.designConsultantList,
											valueKey: "id",
											textKey: "name",
										}}
										maxLength={200}
										rules={rules.selectFirstFields()}
										onChange={() => {
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										label="Design Code"
										name="design_code_id"
										onFocus={() => dispatch(getDesignCodeList())}
										options={{
											list: DESIGN_CODE.designCodeList,
											valueKey: "id",
											textKey: "name"
										}}
										rules={rules.selectFirstFields()}
										maxLength={200}
										onChange={() => {
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										label="PMC"
										name="pmc_id"
										required
										onFocus={() => dispatch(getPMCList())}
										options={{
											list: PMC.pmcList,
											valueKey: "id",
											textKey: "name",
										}}
										rules={rules.selectFirstFields()}
										onChange={() => {
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										label="Contractor"
										name="contractor_id"
										required
										onFocus={() => dispatch(getContractorList())}
										options={{
											list: CONTRACTOR.contractorList,
											valueKey: "id",
											textKey: "name",
										}}
										rules={rules.selectFirstFields()}
										onChange={() => {
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.DatePicker
										label={"Work Order Date"}
										name={"work_order_date"}
										rules={rules.dateFields()}
										disabledDate={disabledDate}
										onChange={() => {
											onChange()
										}}
										className={'field-required'}
										initialValue={null}
									/>
								</Col>
								<Col span={12}>
									<InputBox.DatePicker
										label={"Actual Start Date"}
										name={"actual_start_date"}
										rules={rules.dateFields()}
										onChange={() => {
											onChange()
										}}
										className={'field-required'}
										initialValue={null}
									/>
								</Col>
							</>
							: <>
								<Col span={12}>
									<InputBox.Select
										className="projectList"
										label="Select Zone"
										name="zone_id"
										rules={rules.selectFirstFields()}
										options={{
											list: [
												{
													id: 1,
													value: "nz",
													name: "NZ",
												},
												{
													id: 2,
													value: "wz",
													name: "WZ",
												},
												{
													id: 3,
													value: "sz",
													name: "SZ",
												},
												{
													id: 4,
													value: "ez",
													name: "EZ",
												},
												{
													id: 5,
													value: "nwz",
													name: "NWZ",
												},
												{
													id: 6,
													value: "swz",
													name: "SWZ",
												},
												{
													id: 7,
													value: "cz",
													name: "CZ",
												},
											],
										}}
										onChange={() => {
											form.setFieldsValue({ ward_id: "" })
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										className="projectList"
										label="Select Ward"
										name="ward_id"
										rules={rules.selectFirstFields()}
										onFocus={() => dispatch(getRoadPlanningWardList(form.getFieldValue("zone_id")))}
										options={{
											list: PROJECT.roadPlanningWardList,
											valueKey: "id",
											textKey: "ward_name",
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Type of Project"
										name="type_of_project"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.DatePicker
										label="Date"
										name={"planning_date"}
										className={'field-required'}
										initialValue={null}
										rules={rules.dateFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Radio
										name="roaster"
										label="Roster"
										options={{
											list: [
												{
													id: 1,
													value: "day",
													name: "Day",
												},
												{
													id: 2,
													value: "night",
													name: "Night",
												},
											],
										}}

										rules={rules?.selectFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										className="projectList"
										label="Name of Agency"
										name="contractor_id"
										onFocus={() => dispatch(getContractorList())}
										options={{
											list: CONTRACTOR.contractorList,
											valueKey: "id",
											textKey: "name",
										}}
										rules={rules.selectFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Plant Location"
										name="plant_location"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Name of Road"
										name="name_of_road"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										className="projectList"
										name="type_of_material"
										label="Type of Material"
										options={{
											list: [
												{
													id: 1,
													value: "subgrade",
													name: "Subgrade",
												},
												{
													id: 2,
													value: "gsb",
													name: "GSB",
												},
												{
													id: 3,
													value: "wmm",
													name: "WMM",
												},
												{
													id: 4,
													value: "dbm",
													name: "DBM",
												},
												{
													id: 5,
													value: "bc",
													name: "BC",
												},
											],
										}}
										onChange={(e) => {
											setIsShow(e)
											form.setFieldsValue({ planned_quantity: "" })
										}}
										rules={rules?.selectFirstFields()}
									/>
								</Col>
								{
									(isShow == 4 || isShow == 5) && <Col span={12}>
										<InputBox.Text
											label="Planned Quantity (MT)"
											name="planned_quantity"
											maxLength={100}
											rules={rules.dynamicNumericField()}
										/>
									</Col>
								}
								<Col span={12}>
									<InputBox.Text
										label="Name of PMC"
										name="name_of_pmc"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Name of Engineer(PMC) at plant Site"
										name="name_of_engg_pmc_plan_site"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Name of Engineer(PMC) at paver Site"
										name="name_of_engg_pmc_paver_site"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										className="projectList"
										disabled={
											location.pathname.includes('/read')
										}
										label="Name of Engineer(AMC)at plant site"
										name="name_of_engg_amc_plan_site"
										onFocus={() => dispatch(getUserList())}
										options={{
											list: PROJECT?.userPlantList?.filter((user: any) => user?.id !== paverSite),
											valueKey: "id",
											textKey: "full_name"
										}}
										rules={rules.selectFirstFields()}
										maxLength={200}
										onChange={(e) => {
											setPlantSite(e)
											onChange()
										}}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Designation of engineer(AMC) at plant site"
										name="designation_of_engg_amc_plan_site"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Mobile Number(AMC) at plant site"
										name="mobile_number_amc_plant_site"
										rules={rules.mobileNumber()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Select
										className="projectList"
										label="Name of Engineer(AMC)at paver site"
										name="name_of_engg_amc_paver_site"
										onFocus={() => dispatch(getUserList())}
										options={{
											list: PROJECT?.userPaverList?.filter((user: any) => user?.id !== plantSite),
											valueKey: "id",
											textKey: "full_name"
										}}
										rules={rules.selectFirstFields()}
										maxLength={200}
										onChange={(e) => {
											setPaverSite(e)
											onChange()
										}}
										disabled={
											location.pathname.includes('/read')
										}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Designation of engineer(AMC) at paver site"
										name="designation_of_engg_amc_paver_site"
										maxLength={100}
										rules={rules.dynamicFirstFields()}
									/>
								</Col>
								<Col span={12}>
									<InputBox.Text
										label="Mobile Number(AMC) at paver site"
										name="mobile_number_amc_paver_site"
										rules={rules.mobileNumber()}
									/>
								</Col>
							</>
					}

				</Row>
			</Form>
		</>
	);
};
export default FirstStep;
