import React from 'react'
import AgGridWrapper from '../../../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs from './columnDefs'
import { setUpGrid } from '../utils/slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/app'

const Listing: React.FC<any> = ({
	rowData,
	onRowClick

}) => {
	const POLYMER_SLURRY_TAB = useSelector(
		(state: RootState) => state.POLYMER_SLURRY_TAB
	)
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setUpGrid}
				onRowClicked={onRowClick}
				paginationPageSize={POLYMER_SLURRY_TAB.perPageSize}
				cacheBlockSize={POLYMER_SLURRY_TAB.perPageSize}
				rowSelection={"single"}
			/>
		</>
	)
}

export default Listing
