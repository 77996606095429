import { createSlice } from "@reduxjs/toolkit";
import type { Action, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../store/api";
import { AppThunk, RootState } from "../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../config/Config";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { Dispatch } from "react";

interface InitialState {
	qa_category_id: any;
	isLoading: boolean;
	rowData: any;
	perPage: any;
	agGrid: any;
	details: any;
	projectId: any;
	selectedData: any;
	groupList: any;
	categoriesList: any;
	read: any;
	tab: any;
	inputType: any;
	advance_filter: any;
	elementId: any;
	count: any;
	statusSwitch: boolean;
	questionSequence: any;
}


const initialState = {
	isLoading: false,
	perPage: Config.grid.local.gridOptions?.paginationPageSize,
	rowData: null,
	details: null,
	projectId: null,
	agGrid: null,
	selectedData: null,
	categoriesList: null,
	groupList: null,
	tab: 1,
	inputType: null,
	advance_filter: null,
	elementId: 1,
	statusSwitch: true,
	count: 0,
	questionSequence: null
} as InitialState;

const QuestionMasterSlice = createSlice({
	name: "QuestionMaster",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setCount: (state, action: PayloadAction<any>) => {
			state.count = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action.payload
		},
		setDetails: (state, action: PayloadAction<any>) => {
			state.details = action?.payload;
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload;
		},
		setCategoriesList: (state, action: PayloadAction<any>) => {
			state.categoriesList = action?.payload;
		},
		setGroupList: (state, action: PayloadAction<any>) => {
			state.groupList = action?.payload;
		},
		setInputType: (state, action: PayloadAction<any>) => {
			state.inputType = action?.payload;
		},
		setSelectedData: (state, action: PayloadAction<any>) => {
			state.selectedData = action?.payload;
		},
		setTab: (state, action: PayloadAction<any>) => {
			state.tab = action?.payload
		},
		setElementId: (state, action: PayloadAction<any>) => {
			state.elementId = action?.payload
		},
		setAdvanceFilterValues: (state, action: PayloadAction<any>) => {
			state.advance_filter = action?.payload;
		},
		setStatusSwitch: (state, action: PayloadAction<boolean>) => {
			state.statusSwitch = action.payload;
		},
		setQuestionSequence: (state, action: PayloadAction<boolean>) => {
			state.questionSequence = action.payload;
		},
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
	setRead,
	setCategoriesList,
	setGroupList,
	setPerPage,
	setDetails,
	setGrid,
	setTab,
	setCount,
	setInputType,
	setSelectedData,
	setAdvanceFilterValues,
	setElementId,
	setStatusSwitch,
	setQuestionSequence
} = QuestionMasterSlice.actions;



// set form values to  customefilter
export const setAdvanceFilter =
	(): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			const setAdvanceFilter: any = {};
			try {
				if (getState().QUESTION_MASTER.advance_filter) {
					if ('department' in getState().QUESTION_MASTER.advance_filter) {
						setAdvanceFilter.department_id = getState().QUESTION_MASTER.advance_filter.department.id;
					}
				}
			} catch (error: any) {
				//
			}
		}

export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().QUESTION_MASTER.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				dispatch(setPerPage(getState().QUESTION_MASTER.perPage));
				const dataSource = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
			} catch (error: any) {
				//
			}
		};


const changeFilterAndSort = (params: any, dispatch?: any) => {
	dispatch(setSelectedData(null))
	params;
	// if (params.filterModel && params.filterModel["is_active"]) {
	// 	params.filterModel["is_active"].values = convertTextToID(
	// 		params.filterModel["is_active"],
	// 		this.commonStore.statusFilter
	// 	);
	// }
};

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
	return await api.post(apiUrls.list, payload).then(({ data }) => {
		return data;
	});
};

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch);
			const payload = getServerListPayload(params);
			const data = {
				department_id: getState().QUESTION_MASTER?.advance_filter?.department.id,
				qa_category_id: getState().QUESTION_MASTER.elementId,
				...payload
			}
			dispatch(setSelectedData(null))
			fetchList(data).then((data: any) => {
				const gridData = data?.rows?.filter((item: any) => item?.qa_category_id == getState().QUESTION_MASTER.elementId)
				dispatch(setCount(data));
				params.success({ rowData: gridData, rowCount: data?.count });
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			});
		},
	};
};

export const categoriesList =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.categoriesList(id));
				dispatch(success(response.data));
				dispatch(setCategoriesList(response.data?.data))
				// dispatch(fetchList());
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const createRecord =
	(data: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create, data);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().QUESTION_MASTER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

// export const readRecord =
// 	(action: any): AppThunk<any> =>
// 		async (dispatch) => {
// 			try {
// 				const response = await api.get(apiUrls.read(action), action);
// 				dispatch(setRead(response.data.data));
// 				return Promise.resolve(response.data);
// 			} catch (error: any) {
// 				return Promise.reject(error.data);
// 			}
// 		};
export const readRecord =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.get(apiUrls.read(id));
				dispatch(setRead(response.data.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
export const updateRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.update(id), action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().QUESTION_MASTER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};


export const groupList =
	(): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.get(apiUrls.groupList);
				dispatch(success(response.data));
				dispatch(setGroupList(response.data?.rows))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const inputTypeList =
	(): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.get(apiUrls.inputList);
				dispatch(success(response.data));
				dispatch(setInputType(response.data.data))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data);
			}
		};

export const getSequence =
	(): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.sequence);
				dispatch(success(response.data));
				dispatch(setQuestionSequence(response.data?.rows))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const changeSequence =
	(action: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.changeSequence, action);
				dispatch(success(response.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const changeStatus =
	(id: number, status: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				const response = await api.post(apiUrls.changeStatus
					, { id: id, status: status });
				dispatch(setupGrid(getState().QUESTION_MASTER.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
const QuestionMasterSliceReducer = QuestionMasterSlice.reducer;
export default QuestionMasterSliceReducer;
