import { validations } from "../../../../config/validations/validations";

export const rules = {
	selectFirstFields: (field?: string) => [validations.required.select(field)],
	dynamicFields: (field?: string) => [
		validations.required.text(field),
		validations.minMax()
	],
	dynamicNumericField: (field?: any) => [
		validations.required.other(field),
		validations.pattern.numeric(),
	],
	dynamicNumericField1: (field?: any) => [
		validations.required.other(field),
		validations.pattern.numeric(),
	],
};
