/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormInstance } from "antd";
import { validations } from "../../../../../config/validations/validations";

export const rules = {
	dynamicFirstFields: (field?: string) => [validations.required.text(field),
	validations.minMax()
	],
	dynamicFields: (field?: string, dynamicFieldType?: any, item?: any, form?: FormInstance) => {

		const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
		let requiredValidation: any
		if (attributeJson?.required_if && attributeJson?.required_if == "name_of_cost_sharing_agency") {
			requiredValidation = {}
			let requiredField;
			let filterData;
			if (form?.getFieldValue('19')) {
				const data = dynamicFieldType?.filter((item: any) => item.id === 19)
				filterData = JSON.parse(data[0].data)?.filter((item: any) => item.name === attributeJson?.required_if)
			}

			if (form?.getFieldValue('19') === filterData?.[0]?.id && attributeJson?.required_if) {
				const key = Object.keys(attributeJson?.required_if)
				requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === key[0])
			} else {
				requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === attributeJson?.required_if)
			}
			if (requiredField) {
				if (form?.getFieldValue([requiredField[0]?.id, "value"]) && requiredField[0]?.id && form?.getFieldValue(requiredField[0]?.id) && form?.getFieldValue(requiredField[0]?.id) !== '') {
					requiredValidation = validations.required.text(field)
				} else {
					requiredValidation = {}
					form?.setFieldValue(["20", "value"], "")
				}
			}
		} else {
			requiredValidation = attributeJson.required ? validations.required.text(field) : {};
		}

		if (attributeJson?.required_if && attributeJson?.required_if?.type_of_encroachment) {
			requiredValidation = {}
			let requiredField;
			let filterData;
			if (form?.getFieldValue('36')) {
				const data = dynamicFieldType.filter((item: any) => item.id === 36)
				filterData = JSON.parse(data[0]?.data)?.filter((item: any) => item?.name === attributeJson?.required_if?.type_of_encroachment)
			}

			if (attributeJson?.required_if?.type_of_encroachment) {
				const key = Object.keys(attributeJson?.required_if)
				requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === key[0])
			} else {
				requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === attributeJson?.required_if)
			}
			if (requiredField) {
				if (form?.getFieldValue([requiredField[0]?.id, "value"]) == 6 && requiredField[0]?.id && form?.getFieldValue(requiredField[0]?.id) && form?.getFieldValue(requiredField[0]?.id) !== '') {
					requiredValidation = validations.required.text(field)
				} else {
					requiredValidation = {}
					//form?.setFieldValue(["36","value"],"")
				}
			}
		}


		if (attributeJson?.required_if && attributeJson?.required_if?.type_of_ownership) {
			requiredValidation = {}
			let requiredField;
			let filterData;

			if (form?.getFieldValue('42')) {
				const data = dynamicFieldType.filter((item: any) => item.id === 42)
				filterData = JSON.parse(data[0]?.data)?.filter((item: any) => item?.name === attributeJson?.required_if?.type_of_ownership)
			}

			if (attributeJson?.required_if?.type_of_ownership) {
				const key = Object.keys(attributeJson?.required_if)
				requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === key[0])
			} else {
				requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === attributeJson?.required_if)
			}
			if (requiredField) {
				if (form?.getFieldValue([requiredField[0]?.id, "value"]) == 3 && requiredField[0]?.id && form?.getFieldValue(requiredField[0]?.id) && form?.getFieldValue(requiredField[0]?.id) !== '') {
					requiredValidation = validations.required.text(field)
				} else {
					requiredValidation = {}
					//form?.setFieldValue(["42","value"],"")
				}
			}
		}

		return [
			requiredValidation,
			validations.minMax()
		]
	},
	dynamicNumericField: (field?: any) => [
		validations.required.other(field),
		validations.pattern.numeric(),
		validations.minMax()
	],
	selectFirstFields: (field?: any) => [
		validations.required.select(field),
	],
	dateFirstFields: (field?: any) => [
		validations.required.other(field),
	],
	selectFields: (field?: string, dynamicFieldType?: any, item?: any, form?: FormInstance) => {
		const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
		const requiredValidation: any = attributeJson.required ? validations.required.select(field) : {};

		// if (attributeJson?.required_if) {
		// 	requiredValidation = {}

		// 	const	requiredField = dynamicFieldType?.filter((item: any) => item.attribute_key === attributeJson?.required_if)
		// 	if (requiredField){
		// 		if (requiredField[0]?.id && form?.getFieldValue(requiredField[0]?.id) && form?.getFieldValue(requiredField[0]?.id) !=='') {
		// 			requiredValidation = validations.required.text(field)
		// 		} else {
		// 			requiredValidation = {}
		// 		}
		// 	}
		// }
		return [
			requiredValidation,
		]
	},
	multipleField: (field?: string, dynamicFieldType?: any, item?: any, form?: FormInstance) => {
		const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
		const requiredValidation: any = item.required ? validations.required.text(field) : {};
		return [
			requiredValidation,
			//validations.minMax()
		]
	},
	multipleNumberField: (field?: string, dynamicFieldType?: any, item?: any, form?: FormInstance) => {
		const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
		const requiredValidation: any = item.required ? validations.required.text(field) : {};
		return [
			requiredValidation,
			validations.pattern.numeric(),
		]
	},
	dynamicDigitField: (field?: any) => [
		validations.required.text(field),
		validations.pattern.numeric(),
		{
			min: 1,
			message: "Minimum 1 digit allowed",

		},
		{
			max: 3,
			message: "Maximum 3 digit allowed"
		}
	],
	dateFields: (field?: string) => [
		validations.required.select(field),
	],
	fileUploadFields: (field?: string, dynamicFieldType?: any, item?: any, form?: FormInstance) => {
		const attributeJson: any = item?.attribute_json ? JSON.parse(item?.attribute_json) : {};
		const requiredValidation: any = attributeJson.required ? validations.required.other(field) : {};
		return [
			requiredValidation,
		]
	},
	minMaxValidation: (field?: any) => [
		validations.minMax()
	],
	mobileNumber: (field?: any) => [
		validations.required.text(field),
		validations.pattern.phone
	]
};
