import { Button, Segmented, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import BridgeDepartment from "./elements/BridgeDepartment";
import { ReactComponent as ProjectDashboardIcon } from "../../../../assets/images/icon/ProjectDashboard.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/icon/deleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/app";
import BuildingDepartment from "./elements/BuildingDepartment";
import RoadDepartment from "./elements/RoadDepartment";
import WaterDepartment from "./elements/WaterDepartment";
import DrainageDepartment from "./elements/DrainageDepartment ";
import PageSpinner from "../../../../components/PageSpinner/PageSpinner";
import { deleteRecord, setPageNumber, setTab } from "../utils/slice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { CONSTANT } from "../../../../config/Constant";
import { ReactComponent as DataNotFound } from "../../../../assets/images/icon/datanotFound.svg";
import { checkPrivileges } from "../../../../config/Global";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { ReactComponent as ArrowIcon } from '../../../../assets/images/icon/arrowIcon.svg';

const DepartmentTabs: React.FC<any> = ({
	perPage,
	saving,
	moduleInfo,
	finalData,
	setFinalData,
	tab,
	setSaving,
	restoreClick,
	form1

}) => {
	const navigate = useNavigate();
	const dispatch: AppDispatch = useDispatch();
	const { AUTH, CREATE_DASHBOARD_PROJECT } = useSelector((state: RootState) => state);
	const [projectId, setProjectId] = useState<any>();
	const [deleteModal, setDeleteModal] = useState<any>();
	// const depCurrentPage = localStorage.getItem('depCurrentPage')
	// const depName = localStorage.getItem('depName')
	const depProjects = CREATE_DASHBOARD_PROJECT.rowData;
	const pageNumber = CREATE_DASHBOARD_PROJECT.pageNumber;

	const items = [
		{
			value: 0,
			label: <span>Bridge Department</span>,
		},
		{
			value: 1,
			label: <span>Building Department</span>,
		},
		{
			value: 2,
			label: <span>Road Department</span>,
		},
		{
			value: 3,
			label: <span>Water Department</span>,
		},
		{
			value: 4,
			label: <span>Drainage Department</span>,
		},
	];

	useEffect(()=>{
		dispatch(setTab(Number(CREATE_DASHBOARD_PROJECT?.tab)))
		localStorage.setItem("tab","0")
	},[])

	const closeDeleteModal = () => {
		setDeleteModal(false);
	};

	const handlePageChange = (pageNumber: number) => {
		setSaving(false)
		setFinalData(null)
		dispatch(setPageNumber(pageNumber));
	};

	const handleProjectDashboard = (data: any) => {
		localStorage.setItem("projectId", tab == 2 ? data?.planning_id : data.projectId);
		// const d =
		// 	depProjects?.recordData?.Project_details?.filter(
		// 		(item?: any) => {
		// 			return item?.project_id == data.projectId;
		// 		}
		// 	);
		if (AUTH.userDetail?.id == data?.name_of_engg_amc_plan_site) {
			localStorage.setItem("WorkCompleted", JSON.stringify({ name: "Plant", id: data?.name_of_engg_amc_plan_site, type: 1 }))
		} else if (AUTH?.userDetail?.id == data?.name_of_engg_amc_paver_site) {
			localStorage.setItem("WorkCompleted", JSON.stringify({ name: "Paver", id: data?.name_of_engg_amc_paver_site, type: 2 }))
		} else {
			localStorage.setItem("WorkCompleted", JSON.stringify({ name: "Plant/Paver", id: null, type: null }))
		}
		localStorage.setItem("projectName", data?.title);
		localStorage.setItem(
			"startDate",
			dayjs(data?.actual_start_date)?.format(CONSTANT.DATE_FORMAT)
		);
		localStorage.setItem(
			"start_date",
			dayjs(data?.actual_start_date).format(CONSTANT.POST_DATE_FORMAT)
		);
		localStorage.setItem("department_id", data?.department_id);
		localStorage.setItem("department_type_id", data?.department_type_id);
		localStorage.setItem("isCheckListFill", parseInt(data?.read_count) ? data?.read_count : "No_data")
		setTimeout(() => {
			navigate(`/projects/project-dashboard`, {
				state: {
					project_name: data.project_name,
				},
			});
		}, 500);
	};

	const firstColData = [
		{
			title:
				tab == 0
					? "Bridge Name"
					: tab == 1
						? "Building Name"
						: tab == 2
							? "Road Name"
							: tab == 3
								? "Water"
								: tab == 4
									? "Drainage Name"
									: "",
			width: 200,
			dataIndex: "first_col",
			key: "first_col",
			fixed: "left",
		},
	];
	const colData = finalData?.length && finalData.map((projectDetail: any) => {
		const str =
			tab == 2 ? projectDetail?.name_of_road : projectDetail?.project_name;
		const uniqueKey = str?.replace(/ /g, "_");
		//setProjectId(projectDetail.project_id)
		return {
			department_id: projectDetail?.department_id,
			department_type_id: projectDetail?.department_type_id,
			title:
				tab == 2 ? projectDetail?.name_of_road : projectDetail?.project_name,
			width: 200,
			dataIndex: uniqueKey,
			key: uniqueKey,
			projectId: projectDetail.project_id,
			id: projectDetail.project_id,
			planning_id: projectDetail?.id,
			read_count: projectDetail?.readCount?.qa_params_value_mapping_id,
			actual_start_date: projectDetail?.actual_start_date,
			name_of_engg_amc_paver_site: projectDetail?.name_of_engg_amc_paver_site,
			name_of_engg_amc_plan_site: projectDetail?.name_of_engg_amc_plan_site
			//fixed: 'left',
		};
	});

	const finalCol = colData ? [...firstColData, ...colData] : []; ``


	const uniqueKeys =
		tab != 2 ? [
			"department_type_name",
			"design_consultant",
			"design_code",
			"contractor_name",
			"pmc_name",
			"work_order_date",
			"actual_start_date",
		] : [];
	if ((tab == 0)) {
		uniqueKeys.push(
			"pile",
			"total_no",
			"submitted_in_state_rmb_design_circle_no",
			"approved_by_state_rmb_design_circle_no",
			"approval_pending_by_state_rmb_design_circle_no",
			"submission_pending_by_design_consultant_no",
			"work_completed_on_site",
			"pileCap",
			"pileCap_total_no",
			"pileCap_submitted_in_state_rmb_design_circle_no",
			"pileCap_approved_by_state_rmb_design_circle_no",
			"pileCap_approval_pending_by_state_rmb_design_circle_no",
			"pileCap_submission_pending_by_design_consultant_no",
			"pileCap_work_completed_on_site",
			"pier",
			"pier_total_no",
			"pier_submitted_in_state_rmb_design_circle_no",
			"pier_approved_by_state_rmb_design_circle_no",
			"pier_approval_pending_by_state_rmb_design_circle_no",
			"pier_submission_pending_by_design_consultant_no",
			"pier_work_completed_on_site",
			"pierCap",
			"pierCap_total_no",
			"pierCap_submitted_in_state_rmb_design_circle_no",
			"pierCap_approved_by_state_rmb_design_circle_no",
			"pierCap_approval_pending_by_state_rmb_design_circle_no",
			"pierCap_submission_pending_by_design_consultant_no",
			"pierCap_work_completed_on_site",
			"bearing",
			"bearing_total_no",
			"bearing_submitted_in_state_rmb_design_circle_no",
			"bearing_approved_by_state_rmb_design_circle_no",
			"bearing_approval_pending_by_state_rmb_design_circle_no",
			"bearing_submission_pending_by_design_consultant_no",
			"bearing_work_completed_on_site",
			"grider",
			"grider_total_no",
			"grider_submitted_in_state_rmb_design_circle_no",
			"grider_approved_by_state_rmb_design_circle_no",
			"grider_approval_pending_by_state_rmb_design_circle_no",
			"grider_submission_pending_by_design_consultant_no",
			"grider_work_completed_on_site",
			"boxGrider",
			"boxGrider_total_no",
			"boxGrider_submitted_in_state_rmb_design_circle_no",
			"boxGrider_approved_by_state_rmb_design_circle_no",
			"boxGrider_approval_pending_by_state_rmb_design_circle_no",
			"boxGrider_submission_pending_by_design_consultant_no",
			"boxGrider_work_completed_on_site",
			"RtWall",
			"RtWall_total_no",
			"RtWall_submitted_in_state_rmb_design_circle_no",
			"RtWall_approved_by_state_rmb_design_circle_no",
			"RtWall_approval_pending_by_state_rmb_design_circle_no",
			"RtWall_submission_pending_by_design_consultant_no",
			"RtWall_work_completed_on_site",
			"solidslab",
			"solidslab_total_no",
			"solidslab_submitted_in_state_rmb_design_circle_no",
			"solidslab_approved_by_state_rmb_design_circle_no",
			"solidslab_approval_pending_by_state_rmb_design_circle_no",
			"solidslab_submission_pending_by_design_consultant_no",
			"solidslab_work_completed_on_site",
		);
	}
	else if (tab == 2) {
		uniqueKeys.push(
			"zone_id",
			"ward_name",
			"type_of_project",
			"planning_date",
			"roaster",
			"name_of_agency",
			"plant_location",
			"planned_quantity",
			"type_of_material",
			"name_of_pmc",
			"name_of_engg_pmc_plan_site",
			"name_of_engg_pmc_paver_site",
			"amc_username_at_plan_site_full_name",
			"amc_username_at_paver_site_full_name",
			"mobile_number_amc_plant_site",
			"designation_of_engg_amc_plan_site",
			"designation_of_engg_amc_paver_site",
			"mobile_number_amc_paver_site"

		)
	}


	function capitalize(str: string): string {
		const submitted = "submitted_in_state_rmb_design_circle_no";
		const approved = "approved_by_state_rmb_design_circle_no";
		const approvedPend = "approval_pending_by_state_rmb_design_circle_no";
		const submittedPend = "submission_pending_by_design_consultant_no";
		if (str == "department_type_name") {
			return tab == 0
				? "Bridge Type"
				: tab == 1
					? "Building Type"
					: tab == 2
						? "Name Of Road"
						: tab == 3
							? "Water"
							: tab == 4
								? "Drainage Type"
								: ""
		} else if (str == "zone_id") {
			return "Zone";
		} else if (str == "ward_name") {
			return "Ward"
		} else if (str == "type_of_project") {
			return "Type of Project";
		} else if (str == "planning_date") {
			return "Planning Date";
		} else if (str == "roaster") {
			return "Roster ";
		}
		else if (str == "name_of_agency") {
			return "Name of Agency";
		}
		else if (str == "plant_location") {
			return "Plant Location";
		}
		else if (str == "planned_quantity") {
			return "Planned Quantity(MT)";
		} else if (str == "type_of_material") {
			return "Type of Material";
		}
		else if (str == "name_of_pmc") {
			return "Name of PMC";
		}
		else if (str == "name_of_engg_pmc_plan_site") {
			return "Name of Engineer(PMC) at plant Site";
		}
		else if (str == "name_of_engg_pmc_paver_site") {
			return "Name of Engineer(PMC) at paver Site";
		}
		else if (str == "amc_username_at_plan_site_full_name") {
			return "Name of Engineer(AMC)at plant site";
		}
		else if (str == "amc_username_at_paver_site_full_name") {
			return "Name of Engineer(AMC)at paver site";
		}
		else if (str == "mobile_number_amc_plant_site") {
			return "Mobile Number(AMC) at plant site";
		} else if (str == "designation_of_engg_amc_plan_site") {
			return "Designation of engineer(AMC) at plant site";
		} else if (str == "designation_of_engg_amc_paver_site") {
			return "Designation of engineer(AMC) at paver site";
		} else if (str == "mobile_number_amc_paver_site") {
			return "Mobile Number(AMC) at paver site";
		}

		else if (str == "design_consultant") {
			return "Design Consultant";
		} else if (str == "design_code") {
			return "Design Code";
		} else if (str == "contractor_name") {
			return "Contractor";
		} else if (str == "pmc_name") {
			return "PMC";
		} else if (str == "work_order_date") {
			return "Work Order Date";
		} else if (str == "actual_start_date") {
			return "Actual Start Date";
		} else if (str == "pile") {
			return "Pile";
		} else if (str == "pileCap") {
			return "Pile Cap";
		} else if (str == "pier") {
			return "Pier";
		} else if (str == "pierCap") {
			return "Pier Cap";
		} else if (str == "bearing") {
			return "Bearing"
		} else if (str == "grider") {
			return "Grider"
		} else if (str == "boxGrider") {
			return "Box Grider"
		} else if (str == "RtWall") {
			return "Rt Wall"
		} else if (str == "solidslab") {
			return "Solid Slab"
		} else if (
			str == "total_no" ||
			str == "pileCap_total_no" ||
			str == "pier_total_no" ||
			str == "pierCap_total_no" ||
			str == "bearing_total_no" ||
			str == "grider_total_no" ||
			str == "boxGrider_total_no" ||
			str == "RtWall_total_no" ||
			str == "solidslab_total_no"
		) {
			return "Total Nos.";
		} else if (
			str == submitted ||
			str == "pileCap_" + submitted ||
			str == "pier_" + submitted ||
			str == "pierCap_" + submitted ||
			str == "bearing_" + submitted ||
			str == "grider_" + submitted ||
			str == "boxGrider_" + submitted ||
			str == "RtWall_" + submitted ||
			str == "solidslab_" + submitted
		) {
			return "Submitted in R&B Design (No.)";
		} else if (
			str == approved ||
			str == "pileCap_" + approved ||
			str == "pier_" + approved ||
			str == "pierCap_" + approved ||
			str == "bearing_" + approved ||
			str == "grider_" + approved ||
			str == "boxGrider_" + approved ||
			str == "RtWall_" + approved ||
			str == "solidslab_" + approved
		) {
			return "Approved By Design Circle (No.)";
		} else if (
			str == approvedPend ||
			str == "pileCap_" + approvedPend ||
			str == "pier_" + approvedPend ||
			str == "pierCap_" + approvedPend ||
			str == "bearing_" + approvedPend ||
			str == "grider_" + approvedPend ||
			str == "boxGrider_" + approvedPend ||
			str == "RtWall_" + approvedPend ||
			str == "solidslab_" + approvedPend
		) {
			return "Approval Pending in Design Circle (No.)";
		} else if (
			str == submittedPend ||
			str == "pileCap_" + submittedPend ||
			str == "pier_" + submittedPend ||
			str == "pierCap_" + submittedPend ||
			str == "bearing_" + submittedPend ||
			str == "grider_" + submittedPend ||
			str == "boxGrider_" + submittedPend ||
			str == "RtWall_" + submittedPend ||
			str == "solidslab_" + submittedPend
		) {
			return "Submission Pending By Design Consultant (No.)";
		} else if (
			str == "work_completed_on_site" ||
			str == "pileCap_work_completed_on_site" ||
			str == "pier_work_completed_on_site" ||
			str == "pierCap_work_completed_on_site" ||
			str == "bearing_work_completed_on_site" ||
			str == "grider_work_completed_on_site" ||
			str == "boxGrider_work_completed_on_site" ||
			str == "RtWall_work_completed_on_site" ||
			str == "solidslab_work_completed_on_site"
		) {
			return "Work Completed On Site";
		} else {
			return "";
		}
	}
	const transformedData: any = [];

	uniqueKeys && uniqueKeys?.forEach((key) => {
		const newData: any = {
			first_col: capitalize(key),
		};

		finalData && finalData?.forEach((item: any) => {
			const str =
				tab == 2 ? item?.name_of_road : item?.project_name;
			const uniqueKey = str?.replace(/ /g, "_");
			const currentItem = item[key];
			// item.actual_start_date =dayjs(item?.actual_start_date.date).format(CONSTANT.DATE_FORMAT)
			// item.work_order_date =  dayjs(item?.work_order_date.date).format(CONSTANT.DATE_FORMAT)

			if (typeof currentItem != "object") {
				newData[uniqueKey] = item[key];

				if (key == "actual_start_date" && item[key] != "") {
					newData[uniqueKey] = dayjs(item[key]).format(CONSTANT.DATE_FORMAT);
				}

				if (key == "work_order_date" && item[key] != "") {
					newData[uniqueKey] = dayjs(item[key]).format(CONSTANT.DATE_FORMAT);
				}
				if (key == "zone_id" && item[key] != "") {
					if (item?.zone_id == 1) {
						newData[uniqueKey] = "North Zone(NZ)"
					} else if (item?.zone_id == 2) {
						newData[uniqueKey] = "West Zone(WZ)"
					} else if (item?.zone_id == 3) {
						newData[uniqueKey] = "South Zone(SZ)"
					} else if (item?.zone_id == 4) {
						newData[uniqueKey] = "East Zone(EZ)"
					} else if (item?.zone_id == 5) {
						newData[uniqueKey] = "North West Zone(NWZ)"
					} else if (item?.zone_id == 6) {
						newData[uniqueKey] = "South West Zone(SWZ)"
					} else if (item?.zone_id == 7) {
						newData[uniqueKey] = "Central Zone(CZ)"
					}
					//newData[uniqueKey]=item?.zone_id
				} if (key == "planning_date" && item[key] != "") {
					newData[uniqueKey] = dayjs(item[key]).format(CONSTANT.DATE_FORMAT)
				} if (key == "roaster" && item[key] != "") {
					if (item?.roaster == 1) {
						newData[uniqueKey] = "Day"
					} else if (item?.roaster == 2) {
						newData[uniqueKey] = "Night"
					}
				} if (key == "name_of_agency" && item[key] != "") {
					newData[uniqueKey] = item?.contractor_name?.name
				} if (key == "type_of_material" && item[key] != "") {
					if (item?.type_of_material == 1) {
						newData[uniqueKey] = "Subgrade"
					} else if (item?.type_of_material == 2) {
						newData[uniqueKey] = "GSB"
					} else if (item?.type_of_material == 3) {
						newData[uniqueKey] = "WMM"
					} else if (item?.type_of_material == 4) {
						newData[uniqueKey] = "DBM"
					} else if (item?.type_of_material == 5) {
						newData[uniqueKey] = "BC"
					}
				}
				if (key == "amc_username_at_plan_site_full_name") {
					newData[uniqueKey] = item?.amc_username_at_plan_site?.full_name
				}
				if (key == "amc_username_at_paver_site_full_name") {
					newData[uniqueKey] = item?.amc_username_at_paver_site?.full_name
				}
				if (tab != 2 || tab != 4) {
					//pile data
					if (key == "total_no") {
						newData[uniqueKey] = item?.pile?.[0]?.data?.[0]?.total_no;
					}
					if (key == "submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pile?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no;
					}
					if (key == "approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pile?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no;
					}
					if (key == "approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pile?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no;
					}
					if (key == "submission_pending_by_design_consultant_no") {
						newData[uniqueKey] =
							item?.pile?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no;
					}
					if (key == "work_completed_on_site") {
						newData[uniqueKey] = item?.pile?.[0]?.work_completed_on_site;
					}

					//pile cap data
					if (key == "pileCap_total_no") {
						newData[uniqueKey] = item?.pileCap?.[0]?.data?.[0]?.total_no;
					}
					if (key == "pileCap_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pileCap?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no;
					}
					if (key == "pileCap_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pileCap?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no;
					}
					if (key == "pileCap_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pileCap?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no;
					}
					if (key == "pileCap_submission_pending_by_design_consultant_no") {
						newData[uniqueKey] =
							item?.pileCap?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no;
					}
					if (key == "pileCap_work_completed_on_site") {
						newData[uniqueKey] = item?.pileCap?.[0]?.work_completed_on_site;
					}

					//pier data
					if (key == "pier_total_no") {
						newData[uniqueKey] = item?.pier?.[0]?.data?.[0]?.total_no;
					}
					if (key == "pier_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pier?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no;
					}
					if (key == "pier_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pier?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no;
					}
					if (key == "pier_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pier?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no;
					}
					if (key == "pier_submission_pending_by_design_consultant_no") {
						``;
						newData[uniqueKey] =
							item?.pier?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no;
					}
					if (key == "pier_work_completed_on_site") {
						newData[uniqueKey] = item?.pier?.[0]?.work_completed_on_site;
					}
					//pier cap data
					if (key == "pierCap_total_no") {
						newData[uniqueKey] = item?.pierCap?.[0]?.data?.[0]?.total_no;
					}
					if (key == "pierCap_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pierCap?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no;
					}
					if (key == "pierCap_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pierCap?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no;
					}
					if (key == "pierCap_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.pierCap?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no;
					}
					if (key == "pierCap_submission_pending_by_design_consultant_no") {
						newData[uniqueKey] =
							item?.pierCap?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no;
					}
					if (key == "pierCap_work_completed_on_site") {
						newData[uniqueKey] = item?.pierCap?.[0]?.work_completed_on_site;
					}
					//bearing data
					if (key == "bearing_total_no") {
						newData[uniqueKey] = item?.bearing?.[0]?.data?.[0]?.total_no
					}
					if (key == "bearing_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.bearing?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no
					}
					if (key == "bearing_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.bearing?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no
					}
					if (key == "bearing_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.bearing?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no
					}
					if (key == "bearing_submission_pending_by_design_consultant_no") {
						item?.bearing?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no
					}
					if (key == "bearing_work_completed_on_site") {
						item?.bearing?.[0]?.work_completed_on_site
					}
					//grider data
					if (key == "grider_total_no") {
						newData[uniqueKey] = item?.grider?.[0]?.data?.[0]?.total_no
					}
					if (key == "grider_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.grider?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no
					}
					if (key == "grider_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.grider?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no
					}
					if (key == "grider_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.grider?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no
					}
					if (key == "grider_submission_pending_by_design_consultant_no") {
						newData[uniqueKey] =
							item?.grider?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no
					}
					if (key == "grider_work_completed_on_site") {
						newData[uniqueKey] =
							item?.grider?.[0]?.work_completed_on_site
					}
					//boxGrider data
					if (key == "boxGrider_total_no") {
						newData[uniqueKey] = item?.boxgrider?.[0]?.data?.[0]?.total_no
					}
					if (key == "boxGrider_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.boxgrider?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no
					}
					if (key == "boxGrider_approved_by_state_rmb_design_circle_no") {
						item?.boxgrider?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no
					}
					if (key == "boxGrider_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.boxgrider?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no
					}
					if (key == "boxGrider_submission_pending_by_design_consultant_no") {
						item?.boxgrider?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no
					}
					if (key == "boxGrider_work_completed_on_site") {
						item?.boxGrider?.[0]?.work_completed_on_site
					}
					//Rt Wall data
					if (key == "RtWall_total_no") {
						newData[uniqueKey] = item?.RtWall?.[0]?.data?.[0]?.total_no
					}
					if (key == "RtWall_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] = item?.RtWall?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no
					}
					if (key == "RtWall_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.RtWall?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no
					}
					if (key == "RtWall_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.RtWall?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no
					}
					if (key == "RtWall_submission_pending_by_design_consultant_no") {
						newData[uniqueKey] =
							item?.RtWall?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no
					}
					if (key == "RtWall_work_completed_on_site") {
						newData[uniqueKey] =
							item?.RtWall?.[0]?.work_completed_on_site
					}
					//solidSlab
					if (key == "solidslab_total_no") {
						newData[uniqueKey] = item?.solidslab?.[0]?.data?.[0]?.total_no
					}
					if (key == "solidslab_submitted_in_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.solidslab?.[0]?.data?.[0]?.submitted_in_state_rmb_design_circle_no
					}
					if (key == "solidslab_approved_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.solidslab?.[0]?.data?.[0]?.approved_by_state_rmb_design_circle_no
					}
					if (key == "solidslab_approval_pending_by_state_rmb_design_circle_no") {
						newData[uniqueKey] =
							item?.solidslab?.[0]?.data?.[0]?.approval_pending_by_state_rmb_design_circle_no
					}
					if (key == "solidslab_submission_pending_by_design_consultant_no") {
						newData[uniqueKey] =
							item?.solidslab?.[0]?.data?.[0]?.submission_pending_by_design_consultant_no
					}
					if (key == "solidslab_work_completed_on_site") {
						newData[uniqueKey] =
							item?.solidslab?.[0]?.work_completed_on_site
					}
				}
			} else {
				newData[uniqueKey] = "";
			}
		});
		transformedData.push(newData);
	});

	const handleProjectDelete = (data: any) => {
		setProjectId(data);
		setDeleteModal(true);

	};

	const columns1 = finalCol?.map((key?: any, index?: number) => ({
		title: (
			<div className="departmentTableTitle" key={key.dataIndex}>
				<span>{key?.title}</span>
				{index != 0 ? (
					<div className="iconCol">
						{(checkPrivileges(
							AUTH.userDetail,
							`${moduleInfo.permissionPrefix}_DETAILS`
						) ||
							checkPrivileges(AUTH.userDetail, `MIXDESIGN_INDEX`) ||
							checkPrivileges(AUTH.userDetail, `INVENTORY_INDEX`) ||
							checkPrivileges(AUTH.userDetail, `PROGRESS_REPORT_INDEX`) ||
							checkPrivileges(AUTH.userDetail, `CONSOLIDATE_REPORT_INDEX`) ||
							checkPrivileges(AUTH.userDetail, `STRUCTURE_ELEMENT_INDEX`)) && (
								<Tooltip placement="top" title="Project Dashboard">
									<Button
										className="deparmentDashboardIcon"
										onClick={() => handleProjectDashboard(key)}
									>
										<ProjectDashboardIcon />
									</Button>
								</Tooltip>
							)}
						{tab != 2 && checkPrivileges(
							AUTH.userDetail,
							`${moduleInfo.permissionPrefix}_DELETE`
						) && (
								<Tooltip placement="bottom" title="Delete">
									<Button
										className="deparmentDeleteIcon"
										onClick={() => {
											handleProjectDelete(key);
										}}
									>
										<DeleteIcon />
									</Button>
								</Tooltip>
							)}
					</div>
				) : (
					""
				)}
			</div>
		),
		dataIndex: key.dataIndex,
		key: key.dataIndex,
		fixed: key.fixed,
	}));

	const contentWrapper = useRef<HTMLDivElement | null>(null);
	const [total, setTotal] = useState(0)
	const sideScroll = (
		element: HTMLDivElement,
		speed: number,
		distance: number,
		step: number
	) => {
		let scrollAmount = 0;
		const slideTimer = setInterval(() => {
			element.scrollLeft += step;
			scrollAmount += Math.abs(step);
			if (scrollAmount >= distance) {
				clearInterval(slideTimer);
			}
		}, speed);
	};

	const handlePrev = () => {
		dispatch(setTab((Number(CREATE_DASHBOARD_PROJECT?.tab) - 1)))
		setFinalData(null)
		form1.setFieldValue("search", "")
		if (contentWrapper.current) {
			sideScroll(contentWrapper.current, 20, 90, -10);
		}

	}
	const handleNext = () => {
		setTotal((prev: any) => Number(prev))
		setFinalData(null)
		form1.setFieldValue("search", "")
		if (items.length === total + 1) {
			dispatch(setTab((Number(0))))
		}
		else {
			dispatch(setTab((Number(CREATE_DASHBOARD_PROJECT?.tab) + 1)))
		}
		if (contentWrapper.current) {
			sideScroll(contentWrapper.current, 20, 90, 10);
		}

	}

	return (
		<div className="deparmentTabs">
			<DeleteModal
				title="project"
				name={projectId?.title}
				deleteValues={projectId}
				callApi={deleteRecord}
				close={() => closeDeleteModal()}
				open={deleteModal}
				restoreClick={restoreClick}
			/>
			<div className="headerTab">
				<Button onClick={handlePrev} className='leftArrow' disabled={CREATE_DASHBOARD_PROJECT?.tab === 0}><ArrowIcon /></Button>
				<Segmented
					options={items}
					value={tab}
					onChange={(tabValue: any) => {
						dispatch(setTab(tabValue));
						localStorage.setItem("tab", (tabValue))
						setFinalData(null)
						setSaving(false)
						setPageNumber(1);
						form1.setFieldValue("search", "")
					}}
					ref={contentWrapper}
				/>
				<Button onClick={handleNext} className='rightArrow' disabled={CREATE_DASHBOARD_PROJECT?.tab === items?.length - 1}><ArrowIcon /></Button>
			</div>
			{
				saving == true ? (
					<PageSpinner />) :

					(finalData != null ?
						<div className="tabContent">
							{tab == 0 && (
								<>
									{depProjects && (
										<div className="arrow">
											<span></span>
											<span></span>
											<span></span>
										</div>
									)}
									<BridgeDepartment
										perPage={perPage}
										currentPage={pageNumber}
										handlePageChange={handlePageChange}
										columns1={columns1}
										transformedData={transformedData}
										rowData={depProjects}
										finalData={finalData}
									/>
								</>
							)}
							{tab == 1 && <BuildingDepartment />}
							{tab == 2 && <RoadDepartment perPage={perPage}
								currentPage={pageNumber}
								handlePageChange={handlePageChange}
								columns1={columns1}
								transformedData={transformedData}
								rowData={depProjects}
								finalData={finalData} />}
							{tab == 3 && <WaterDepartment
								perPage={perPage}
								currentPage={pageNumber}
								handlePageChange={handlePageChange}
								columns1={columns1}
								transformedData={transformedData}
								rowData={depProjects}
								finalData={finalData}
							/>}
							{tab == 4 && (
								<DrainageDepartment
									perPage={perPage}
									currentPage={pageNumber}
									handlePageChange={handlePageChange}
									columns1={columns1}
									transformedData={transformedData}
									rowData={depProjects}
									finalData={finalData}
								/>
							)}
						</div> :
						<div className="noDataBox">
							<span>
								<DataNotFound />
							</span>
							<p>No data found please sync button click</p>
						</div>
					)}
		</div>
	);
};
export default DepartmentTabs;
