import { createSlice } from '@reduxjs/toolkit'
import type { Action, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import api from '../../../../../../../store/api'
import { AppThunk, RootState } from '../../../../../../../store/app'
import { apiUrls } from './apiUrls'
import Config, {
	getServerListPayload,
} from '../../../../../../../config/Config'
import {
	GridReadyEvent,
	GridOptions,
	IServerSideGetRowsParams,
} from 'ag-grid-community'

interface InitialState {
	isLoading: boolean
	rowData: any
	read: any
	perPageSize: any
	grid: any
	location: any
	projectId: any
	polymerId: any
}

const initialState = {
	isLoading: false,
	rowData: null,
	read: null,
	perPageSize: Config.grid.local.gridOptions?.paginationPageSize,
	grid: null,
	location: null,
	projectId: null,
	polymerId: null
} as InitialState

const PolymerSlurryTab = createSlice({
	name: 'Polymer Slurry Tab',
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true
		},
		success: (state) => {
			state.isLoading = false
		},
		failure: (state) => {
			state.isLoading = false
		},

		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload
		},
		setPerPageSize: (state, action: PayloadAction<any>) => {
			state.perPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.perPageSize))
				state.grid.api.setCacheBlockSize(state.perPageSize)
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.grid = action.payload
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload
		},
		setResetRead: (state) => {
			state.read = initialState
		},
		setLocation: (state, action: PayloadAction<any>) => {
			state.location = action?.payload
		},
		setProjectId: (state, action: PayloadAction<any>) => {
			state.projectId = action?.payload
		},
		setPolymerId: (state, action: PayloadAction<any>) => {
			state.polymerId = action?.payload
		},
	},
})

export const {
	start,
	success,
	failure,
	setRowData,
	setRead,
	setResetRead,
	setPerPageSize,
	setGrid,
	setProjectId,
	setLocation,
	setPolymerId
} = PolymerSlurryTab.actions

export const setPerPage =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPageSize(size))
			dispatch(setUpGrid(getState().CONCRETE_ADMIXTURE.grid))
		}

const fetchList = async (id: any, payload?: any): Promise<any> => {
	return await api.post(apiUrls.list(id), payload).then(({ data }) => {
		return data
	})
}
export const createRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.create(id), action)
				dispatch(success(response.data))
				dispatch(setUpGrid(getState().POLYMER_SLURRY_TAB.grid))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}
export const setUpGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params))
				const data = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				)
				params.api.setServerSideDatasource(data)
			} catch (error: any) {
				//
			}
		}

const changeFilterAndSort = (params: any, dispatch: any) => {
	params
	dispatch(setPolymerId(null))
}

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch)
			const payload = getServerListPayload(params)

			fetchList(getState().POLYMER_SLURRY_TAB.projectId, payload).then((data) => {
				params.success({
					rowData: data?.rows,
					rowCount: data?.count,
				})
				if (data.count <= 0) {
					params.api.showNoRowsOverlay()
				} else {
					params.api.hideOverlay()
				}
			})
		},
	}
}
export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.read(id))
				dispatch(setRead(response.data.data[0]))
				return Promise.resolve(response.data)
			} catch (error: any) {
				return Promise.reject(error.data)
			}
		}

export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.delete, { id: id, project_id: getState().POLYMER_SLURRY_TAB.projectId, type: parseInt(getState().RAWMATERIAL.tab)+1 });
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().POLYMER_SLURRY_TAB.grid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

const PolymerSlurryTabReducer = PolymerSlurryTab.reducer
export default PolymerSlurryTabReducer
