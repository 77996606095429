import { createSlice } from "@reduxjs/toolkit";
import type { Action, PayloadAction } from "@reduxjs/toolkit";
import { apiUrls } from "./apiUrls";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { Dispatch } from "react";
import Config, { getServerListPayload } from "../../../../config/Config";
import { AppThunk, RootState } from "../../../../store/app";
import api from "../../../../store/api";


interface InitialState {
	isLoading: boolean;
	rowData: any;
	read: any;
	perPage: any;
	agGrid: any;
	planningId: any;
	workPlanId: any
}

const initialState = {
	isLoading: false,
	rowData: null,
	read: null,
	perPage: Config.grid.server.gridOptions?.paginationPageSize,
	agGrid: null,
	planningId: null,
	workPlanId: null
} as InitialState;

const WorkCompleteSlice = createSlice({
	name: "WORK_COMPLETE",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},

		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload;
		},
		setPerPage: (state, action: PayloadAction<any>) => {
			state.perPage = action.payload;
			if (state.agGrid) {
				state.agGrid.api.paginationSetPageSize(Number(state.perPage));
				state.agGrid.api.setCacheBlockSize(state.perPage);
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.agGrid = action.payload;
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload;
		},
		setPlanningId: (state, action: PayloadAction<any>) => {
			state.planningId = action?.payload
		},
		setWorkPlanId: (state, action: PayloadAction<any>) => {
			state.workPlanId = action?.payload;
		},
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
	setRead,
	setPerPage,
	setGrid,
	setPlanningId,
	setWorkPlanId,
} = WorkCompleteSlice.actions;


export const setPerPageSize =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			dispatch(setPerPage(size));
			dispatch(setupGrid(getState().WORK_COMPLETED.agGrid));
		};

export const setupGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params));
				dispatch(setPerPage(getState().WORK_COMPLETED.perPage));
				const dataSource = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				);
				params.api.setServerSideDatasource(dataSource);
				var gridApi = params.api;
				gridApi.deselectAll();
			} catch (error: any) {
				//
			}
		};

const changeFilterAndSort = (params: any, dispatch?: any) => {
	dispatch(setWorkPlanId(null))
	params;
};

/** For Listing:Start */
const fetchList = async (payload?: any): Promise<any> => {
	return await api.post(apiUrls.list, payload).then(({ data }) => {
		return data;
	});
};

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch);
			const payload = getServerListPayload(params);
			const workCompletedData: any = localStorage.getItem("WorkCompleted")

			const workData: any = workCompletedData ? JSON.parse(workCompletedData) : []
			const data = {
				planning_id: getState().WORK_COMPLETED.planningId,
				type: workData?.type,
				user_id: workData?.id,
				...payload
			}
			dispatch(setWorkPlanId(null))
			fetchList(data).then((data) => {
				params.success({ rowData: data?.rows, rowCount: data?.count });
				if (data.count <= 0) {
					params.api.showNoRowsOverlay();
				} else {
					params.api.hideOverlay();
				}
			})
		},
	};
};

export const createRecord =
	(action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create, action);
				dispatch(success(response.data));
				dispatch(setupGrid(getState().WORK_COMPLETED.agGrid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}

		};

export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.get(apiUrls.read(id));
				dispatch(setRead(response.data.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

export const resetRead = (): AppThunk<any> => (dispatch) => {
	try {
		dispatch(start());
		dispatch(setRead(null));
		dispatch(success());
		return Promise.resolve();
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

const WorkCompleteSliceReducer = WorkCompleteSlice.reducer;
export default WorkCompleteSliceReducer;
