import React from 'react'
import AgGridWrapper from '../../../../../../../components/AgGridWrapper/AgGridWrapper'
import columnDefs from './columnDefs'
import { setUpGrid } from '../utils/slice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store/app'

const Listing: React.FC<any> = ({
	rowData,
	onRowClick,
}) => {
	const { CONCRETE_ADMIXTURE } = useSelector((state: RootState) => state)

	return (
		<>
			<AgGridWrapper
				type="serverSide"
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setUpGrid}
				onRowClicked={onRowClick}
				paginationPageSize={CONCRETE_ADMIXTURE.perPageSize}
				cacheBlockSize={CONCRETE_ADMIXTURE.perPageSize}
				rowSelection={"single"}
			/>
		</>
	)
}

export default Listing
