import React, { useEffect, useState } from "react";
import ToolBar from "../../../components/ToolBar";
import {
	ProjectBreadcrumb, ViewProjectBreadcrumb
} from "../../../config/BreadcrumbConfig";
import { AppDispatch, RootState } from "../../../store/app";
import { useDispatch, useSelector } from "react-redux";
import RecordPerPage from "../../../components/ToolBar/Dropdown/RecordPerPage";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { Button } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormComponent from "./components/Form/FormComponent";
import { setPerPageSize, setSelectedData, setSelectedProjectData } from "./utils/slice";
import SVGIcon from "../../../utils/SVGIcon";
import { ReactComponent as ProjectIcon } from "../../../assets/images/icon/ProjectDashboard.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/icon/plusIcon.svg";


const Projects: React.FC<any> = () => {
	const dispatch: AppDispatch = useDispatch();
	const PROJECT = useSelector((state: RootState) => state.PROJECT);
	const location = useLocation();
	const [formVisible, setFormVisible] = useState<any>(false);
	const navigate = useNavigate();
	const { id, step } = useParams();
	const tab:any=localStorage.getItem("tab")
	const title = tab!=2?"Project":"Planning"

	const handleClick = () => {
		navigate("/projects/create");
	};

	const getDetails = (data: any) => {
		data;
		dispatch(setSelectedData(null))
	};

	const handleProjectDashboard = (data: any) => {
		dispatch(setSelectedProjectData(data));
		navigate(`${data.id}/project-dashboard`, {
			state: {
				project_name: data.project_name
			}
		});
		dispatch(setSelectedData(null))
	};


	useEffect(() => {
		location.pathname.includes("/projects/create") ||
			location.pathname.includes("/edit") ||
			location.pathname.includes("/read")
			? setFormVisible(true)
			: setFormVisible(false);
	}, [location.pathname]);



	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes("/create") ? ProjectBreadcrumb(id, `Add ${title}`)
						: location.pathname == `/projects/read/${step}` ?
							ViewProjectBreadcrumb(`View - Edit ${title}`) : location.pathname == `/projects/read` ?
								ViewProjectBreadcrumb(`View - Edit ${title}`) : ProjectBreadcrumb(id, "Projects")
				}
			>
				{formVisible ? null : (
					<>
						<Button className="secondaryBtn" onClick={handleClick}>
							Add
							<span className="btnIcon">
								<PlusIcon />
							</span>
						</Button>
						<RecordPerPage
							defaultValue={PROJECT.perPage + " per page"}
							onChange={(perPageSize: number) => {
								dispatch(setPerPageSize(perPageSize));
							}}
						/>{" "}
					</>
				)}
			</ToolBar>
			<ContentBox>
				<div className="mainUpperHeadingContainer">
				<FormComponent />
				</div>
				{!formVisible ? (
					<div className="actionRow">
						<div className="actionBtn">
							<Button onClick={() => { getDetails(PROJECT.selectedData) }} className={PROJECT.selectedData ? "activeBtn deleteBtn" : "disableBtn deleteBtn"} disabled={PROJECT.selectedData ? false : true}>
								Delete <SVGIcon icon="delete" />
							</Button>
							<Button onClick={() => handleProjectDashboard(PROJECT.selectedData)} disabled={PROJECT.selectedData ? false : true} className={PROJECT.selectedData ? "activeBtn projectBtn" : "disableBtn projectBtn"}>
								Project Dashboard   <ProjectIcon height={24} width={24} className="	" />
							</Button>
						</div>
					</div>) : null}
			</ContentBox>
		</>
	);
};

export default Projects;
