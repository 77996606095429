const baseUrl = "/v1/admin/project";
const baseUrlPlanning = "/v1/admin/planning"

export const apiUrls = {
	list: `${baseUrl}`,
	create: `${baseUrl}/create`,
	update: (id: number): string => `${baseUrl}/${id}/edit`,
	dynamicFieldUpdate: `${baseUrl}/dynamic-field-update`,
	delete: (id: number): string => `${baseUrl}/${id}/delete`,
	read: (id: number): string => `${baseUrl}/${id}/read`,
	changeStatus: (id: number): string => `${baseUrl}/${id}/change-status`,
	dynamicFieldType: `${baseUrl}/dynamic-field-type`,
	departmentList: `${baseUrl}/department`,
	pmcList: `v1/admin/pmc/pmclist`,
	departmentTypeList: (departmentId: number): string => `${baseUrl}/${departmentId}/department-type`,
	dynamicFieldSave: `${baseUrl}/dynamic-field-save`,
	removeImage: `${baseUrl}/remove-document`,
	createPlanning: `${baseUrlPlanning}/create`,
	listPlanning: `${baseUrlPlanning}`,
	readPlanning: (id: number): string => `${baseUrlPlanning}/${id}/read`,
	updatePlanning: (id: number): string => `${baseUrlPlanning}/${id}/edit`,
	userList: "/v1/admin/user/list",
	wardList: (id: number): string => `${baseUrlPlanning}/${id}/getWards`,
};
