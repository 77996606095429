import { validations } from '../../../../../config/validations/validations'

export const rules = {
	dynamicFields: (field?: string) => [validations.required.text(field),
	validations.minMax(1, 200)
	],
	optionFields: [
		{
			required: true,
			whitespace: true,
			message: 'Please enter an option',
		},
		{
			max: 20,
			message: "Maximum length is 20 characters",
		}

	],
	selectFirstFields: (field?: string) => [validations.required.select(field)],
	NumbricField: (field?: any) => [
		validations.required.text(field),
		validations.pattern.numeric(),
	],
	radioButton: (field?: any) => [
		validations.required.other(field),
	],
}
