import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../../../store/api'
import { AppThunk } from '../../../../store/app'
import { apiUrls } from './apiUrls'

interface InitialState {
	isLoading: boolean
	rowData: any
	read: any
	dynamicFieldType: any
	gradeOfconcreteList: any
	cementGrageList: any
	departmentTypeList: any
	viewData: any
	tab: any
	cementName: any
	laboratoryName: any
	projectId: any
	categoryData: any;
	formData: any;
	isDisabled: any
}

const initialState = {
	isLoading: false,
	rowData: null,
	read: null,
	dynamicFieldType: null,
	gradeOfconcreteList: null,
	departmentTypeList: null,
	viewData: null,
	cementGrageList: null,
	tab: 0,
	cementName: null,
	laboratoryName: null,
	projectId: null,
	formData: null,
	isDisabled: null
} as InitialState

const RawMaterialSlice = createSlice({
	name: 'RAWMATERIAL',
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true
		},
		success: (state) => {
			state.isLoading = false
		},
		failure: (state) => {
			state.isLoading = false
		},
		setDynamicFieldType: (state, action: PayloadAction<any>) => {
			state.dynamicFieldType = action?.payload
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload
		},
		setGradeOfconcreteList: (state, action: PayloadAction<any>) => {
			state.gradeOfconcreteList = action?.payload
		},
		setViewData: (state, action: PayloadAction<any>) => {
			state.viewData = action?.payload
		},
		setCementGrageList: (state, action: PayloadAction<any>) => {
			state.cementGrageList = action?.payload
		},
		setTab: (state, action: PayloadAction<any>) => {
			state.tab = action?.payload
		},
		setCementName: (state, action: PayloadAction<any>) => {
			state.cementName = action?.payload
		},
		setLaboratoryName: (state, action: PayloadAction<any>) => {
			state.laboratoryName = action?.payload
		},
		setProjectId: (state, action: PayloadAction<any>) => {
			state.projectId = action?.payload
		},
		setCategoryData: (state, action: PayloadAction<any>) => {
			state.categoryData = action?.payload;
		},
		setFormData: (state, action: PayloadAction<any>) => {
			state.formData = action?.payload;
		},
		setIsDisabled: (state, action: PayloadAction<any>) => {
			state.isDisabled = action?.payload;
		},
	},
})

export const {
	start,
	success,
	failure,
	setDynamicFieldType,
	setRowData,
	setRead,
	setGradeOfconcreteList,
	setViewData,
	setCementGrageList,
	setTab,
	setCementName,
	setLaboratoryName,
	setProjectId,
	setCategoryData,
	setFormData,
	setIsDisabled
} = RawMaterialSlice.actions


/** For Listing:Start */
export const getGradeOfconcreteList = (id?: any, data?: any): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.post(apiUrls.gradeOfconcreteList(id), data);
		if (response?.data?.data[0]?.type == 1 || data?.mix_design == 1) {
			dispatch(setGradeOfconcreteList(response.data.data));
		} else {
			dispatch(setCementGrageList(response.data.data));
		}
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
export const getCementBrandName = (): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.cementBrand);
		dispatch(setCementName(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const getLaboratoryBrandName = (): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.laboratory);
		dispatch(setLaboratoryName(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const getEditGradeOfconcreteList = (id?: any, mix_design_id?: any, action?: any): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.post(apiUrls.editGradeOfconcreteList(id, mix_design_id), action);
		dispatch(setGradeOfconcreteList(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
export const fetchList = (id?: any): AppThunk<any> => async (dispatch) => {
	dispatch(start())
	try {
		dispatch(start())
		const response = await api.post(apiUrls.list(id))
		dispatch(setRowData(response.data.rows))
		dispatch(setIsDisabled(response.data.isButtonDisplay))
		dispatch(success())
		return Promise.resolve(response.data)
	} catch (error: any) {
		dispatch(failure())

		return Promise.reject(error.data)
	}
}

export const createRecord =
	(id: any, data?: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.create(id), data)
				dispatch(success(response.data))
				dispatch(fetchList(id))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}
export const createCement =
	(data?: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.addCement, data)
				dispatch(success(response.data))
				dispatch(getCementBrandName())
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}
export const addApproval =
	(id: any, data?: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.approval(id), data)
				dispatch(success(response.data))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}

export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.get(apiUrls.read(id))
				dispatch(setRead(response.data.data))
				return Promise.resolve(response.data)
			} catch (error: any) {
				return Promise.reject(error.data)
			}
		}

export const updateRecord =
	(id: any, data?: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.update(id), data)
				dispatch(success(response.data))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}

export const deleteRecord =
	(id: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.delete, { id: id, project_id: getState().RAWMATERIAL.projectId })
				dispatch(success(response.data))
				dispatch(fetchList(getState().RAWMATERIAL.projectId))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}
export const qacategory =
	(payload?: any): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.qaCategory, payload);
				dispatch(success(response.data));
				dispatch(setCategoryData(response.data.data))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};


export const imageDelete =
	(id?: any): AppThunk<any> =>
		async () => {
			try {
				const response = await api.post(
					apiUrls.removeImage, { "id": id }
				);
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
const RawMateriaSliceReducer = RawMaterialSlice.reducer
export default RawMateriaSliceReducer
