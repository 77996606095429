import { createSlice } from '@reduxjs/toolkit'
import type { Action, PayloadAction } from '@reduxjs/toolkit'
import api from '../../../../../../../store/api'
import { AppThunk, RootState } from '../../../../../../../store/app'
import { apiUrls } from './apiUrls'
import Config, {
	getServerListPayload
} from '../../../../../../../config/Config'
import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from 'ag-grid-community'
import { Dispatch } from 'react'

interface InitialState {
	isLoading: boolean
	rowData: any
	reportRowData: any
	read: any
	readReport: any
	testTypeList: any
	perPageSize: any
	reportPerPageSize: any
	grid: any
	reportId: any
	location: any
	projectId: any
	fineAgId: any
	FineAg: any
	fineAgReport: any
	nameLaboratoryList: any
	dateRange:any
}

const initialState = {
	isLoading: false,
	rowData: null,
	reportRowData: null,
	read: null,
	readReport: null,
	testTypeList: null,
	perPageSize: Config.grid.server.gridOptions?.paginationPageSize,
	reportPerPageSize: Config.grid.server.gridOptions?.paginationPageSize,
	grid: null,
	reportId: null,
	location: null,
	projectId: null,
	fineAgId: null,
	fineAgReport: null,
	nameLaboratoryList: null,
	dateRange:null
} as InitialState

const FineAggregate = createSlice({
	name: 'Fine Aggregate',
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true
		},
		success: (state) => {
			state.isLoading = false
		},
		failure: (state) => {
			state.isLoading = false
		},
		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload
		},
		setPerPageSize: (state, action: PayloadAction<any>) => {
			state.perPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.perPageSize))
				state.grid.api.setCacheBlockSize(state.perPageSize)
			}
		},
		setReportPerPageSize: (state, action: PayloadAction<any>) => {
			state.reportPerPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(
					Number(state.reportPerPageSize)
				)
				state.grid.api.setCacheBlockSize(state.reportPerPageSize)
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.grid = action.payload
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload
		},
		setResetRead: (state) => {
			state.read = initialState
		},
		setTestTypeList: (state, action: PayloadAction<any>) => {
			state.testTypeList = action?.payload
		},
		setReportRowData: (state, action: PayloadAction<any>) => {
			state.reportRowData = action?.payload
		},
		setReadReport: (state, action: PayloadAction<any>) => {
			state.readReport = action.payload
		},
		setResetReportRead: (state) => {
			state.readReport = initialState
		},
		setReportId: (state, action: PayloadAction<any>) => {
			state.reportId = action?.payload
		},
		setLocation: (state, action: PayloadAction<any>) => {
			state.location = action?.payload
		},
		setProjectId: (state, action: PayloadAction<any>) => {
			state.projectId = action?.payload
		},
		setFineAgId: (state, action: PayloadAction<any>) => {
			state.fineAgId = action?.payload
		},
		setFineAgReport: (state, action: PayloadAction<any>) => {
			state.fineAgReport = action?.payload
		},
		setNameLaboratoryList: (state, action: PayloadAction<any>) => {
			state.nameLaboratoryList = action?.payload
		},
		setDateRange: (state, action: PayloadAction<any>) => {
			state.dateRange = action?.payload
		}
	},
})

export const {
	start,
	success,
	failure,
	setRowData,
	setResetRead,
	setPerPageSize,
	setReportPerPageSize,
	setGrid,
	setTestTypeList,
	setReportRowData,
	setReadReport,
	setResetReportRead,
	setReportId,
	setLocation,
	setRead,
	setProjectId,
	setFineAgId,
	setFineAgReport,
	setNameLaboratoryList,
	setDateRange
} = FineAggregate.actions

export const setPerPage =
	(size: number): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			getState().FINE_AGGREGATE.location != '/fine-aggregate-report'
				? dispatch(setPerPageSize(size))
				: dispatch(setReportPerPageSize(size))
			dispatch(setUpGrid(getState().FINE_AGGREGATE.grid))
		}

const fetchList = async (id: any, payload?: any): Promise<any> => {
	return await api.post(apiUrls.list(id), payload).then(({ data }) => {
		return data
	})
}

export const setUpGrid =
	(params: GridReadyEvent): AppThunk<any> =>
		async (dispatch, getState: () => RootState) => {
			try {
				dispatch(setGrid(params))
				const data = await createDataSource(
					dispatch,
					getState,
					Config.grid.server.gridOptions
				)
				params.api.setServerSideDatasource(data)
			} catch (error: any) {
				//
			}
		}

export const createRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.create(id), action)
				dispatch(success(response.data))
				dispatch(setUpGrid(getState().FINE_AGGREGATE.grid))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}

export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.read(id))
				dispatch(setRead(response.data.data[0]))
				return Promise.resolve(response.data)
			} catch (error: any) {
				return Promise.reject(error.data)
			}
		}

const changeFilterAndSort = (params: any, dispatch: any, getState: any) => {
	params
	getState().FINE_AGGREGATE.location != '/fine-aggregate-report' ?
		dispatch(setFineAgId(null))
		: dispatch(setFineAgReport(null))
}

const createDataSource = (
	dispatch: Dispatch<Action>,
	getState: () => RootState,
	gridOptions?: GridOptions
) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch, getState)
			const payload = getServerListPayload(params)
			const reportPayload = {
				...payload,
				start_date: getState().FINE_AGGREGATE.dateRange?.start_date,
				end_date:  getState().FINE_AGGREGATE.dateRange?.end_date ,
			}
			getState().FINE_AGGREGATE.location != '/fine-aggregate-report'
				? fetchList(getState().FINE_AGGREGATE.projectId, payload).then(
					(data) => {
						params.success({
							rowData: data?.rows,
							rowCount: data?.count,
						})
						if (data.count <= 0) {
							params.api.showNoRowsOverlay()
						} else {
							params.api.hideOverlay()
						}
					}
				)
				: fetchReportList(
					getState().FINE_AGGREGATE?.reportId,
					reportPayload
				).then((data) => {
					params.success({
						rowData: data?.rows,
						rowCount: data?.count,
					})
					if (data.count <= 0) {
						params.api.showNoRowsOverlay()
					} else {
						params.api.hideOverlay()
					}
				})
		},
	}
}


const fetchReportList = async (id?: any, payload?: any): Promise<any> => {
	const f_payload = { ...payload, ...id }
	return await api.post(apiUrls.reportList, f_payload).then(({ data }) => {
		return data
	})
}

export const createReport =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.createReport(id), action)
				dispatch(success(response.data))
				dispatch(setUpGrid(getState().FINE_AGGREGATE.grid))
				return Promise.resolve(response.data)
			} catch (error: any) {
				dispatch(failure(error.data))
				return Promise.reject(error.data)
			}
		}

export const resetRead = (): AppThunk<any> => (dispatch) => {
	try {
		dispatch(start())
		dispatch(setRead(null))
		dispatch(success())
		return Promise.resolve()
	} catch (error: any) {
		dispatch(failure())
		return Promise.reject(error.data)
	}
}

export const readReport =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.readReport(id))
				dispatch(setReadReport(response.data.data[0]))
				return Promise.resolve(response.data)
			} catch (error: any) {
				return Promise.reject(error.data)
			}
		}

export const getFineAggregateType =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.getTestType(id))
				dispatch(setTestTypeList(response?.data?.data))
				dispatch(success())
				return Promise.resolve(response.data.data)
			} catch (error: any) {
				dispatch(failure())
				return Promise.reject(error.data)
			}
		}

export const getNameOfLaboratory = (labId:any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.get(apiUrls.nameOfLaboratory)
			const laboratoryName = response.data.data?.filter((item?: any) => {
				if(item?.laboratory_type == labId){
					return item
				}
			});
			dispatch(setNameLaboratoryList(laboratoryName))
			dispatch(success())
			return Promise.resolve(response.data.data)
		} catch (error: any) {
			dispatch(failure())
			return Promise.reject(error)
		}
	}

export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.delete, { id: id, project_id: getState().FINE_AGGREGATE.projectId,type:parseInt(getState().RAWMATERIAL.tab)+1 });
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().FINE_AGGREGATE.grid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

		export const deleteReport =
		(id: number): AppThunk<any> =>
			async (dispatch, getState) => {
				try {
					dispatch(start());
					const response = await api.post(apiUrls.deleteReport, { id: id, project_id: getState().FINE_AGGREGATE.projectId,type:parseInt(getState().RAWMATERIAL.tab)+1 });
					dispatch(success(response.data));
					dispatch(setUpGrid(getState().FINE_AGGREGATE.grid));
					return Promise.resolve(response.data);
				} catch (error: any) {
					dispatch(failure(error.data));
					return Promise.reject(error.data);
				}
			};
			export const getDateRangeData = (): AppThunk<any> =>
			async (dispatch, getState) => {
		
				dispatch(setUpGrid(getState().FINE_AGGREGATE.grid))
			}

const FineAggregateSliceReducer = FineAggregate.reducer
export default FineAggregateSliceReducer
