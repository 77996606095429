import React, { useEffect } from 'react'
import { Row } from 'antd'
import { FormBox, InputBox } from '../../../../../components/AntdAddons'

import { useDispatch} from 'react-redux'
import { AppDispatch} from '../../../../../store/app'
import { rules } from './rules'
import { fetchList } from "../../utils/slice";

interface FormComponentProps {
    form: any
    id: string
    handleSubmit: any
    onValuesChange: any
    editValues?: any
}

const FormComponent: React.FC<FormComponentProps> = ({
    form,
    id,
    handleSubmit,
    onValuesChange,
    editValues,
}) => {
    const dispatch: AppDispatch = useDispatch()


    useEffect(() => {
        dispatch(fetchList())
    }, [])

    useEffect(() => {
        if (editValues) {
            form.setFieldsValue(editValues)
        }
    }, [editValues, form])

    const onFinish = (data: any) => {
        handleSubmit(data)
    }

    return (
        <>
            <FormBox
                form={form}
                id={id}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={25}>
                    <InputBox.Text
                        label="Name"
                        name="name"
                        rules={rules.dynamicFields()}
                        onChange={onValuesChange}
                    />
                    <InputBox.Text
                        label="Order"
                        name="order"
                        rules={rules.dynamicNumericField()}
                        onChange={onValuesChange}
                    />
                </Row>
            </FormBox>
        </>
    )
}

export default FormComponent
