import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'antd'
import { InputBox } from '../../../../../../../components/AntdAddons'
import TableComponent from './TableComponent'
import { rules } from './rules'
import ToolBar from '../../../../../../../components/ToolBar'
import { AddFineAggregateBreadcrumb } from '../../../../../../../config/BreadcrumbConfig'
import { useNavigate, useParams } from 'react-router-dom'
import {
	dataToFormDataConverter,
	validateFields,
} from '../../../../../../../config/Global'
import { createRecord, read, setResetRead } from '../utils/slice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../../../../../store/app'
import { useForm } from 'antd/es/form/Form'
import { assignErrorToInput } from '../../../../../../../store/api'
import { setTab } from '../../../../utils/slice'

const AddFine: React.FC = () => {
	const [form] = useForm()
	const { fineAgid } = useParams()
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [, setState] = useState(false);
	const dispatch: AppDispatch = useDispatch()
	const navigate = useNavigate()
	const FINE_AGGREGATE = useSelector((state: RootState) => state.FINE_AGGREGATE)

	useEffect(() => {
		if (location.pathname.includes('/view')) {
			dispatch(setResetRead())
			form.resetFields()
			dispatch(read(Number(fineAgid)))
		}
	}, [])

	useEffect(() => {
		if (location.pathname.includes('/view-fine-aggregate')) {
			const details = FINE_AGGREGATE.read
			form.setFieldsValue({
				source: details?.source,
				total_cmt: details?.total_cmt,
				document_name: details?.attached_documnent?.map(
					(image: { id: any; document_name: any }) => {
						return {
							//uid: image.id,
							name: image?.document_name,
							status: 'done',
							url: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image?.document_name}`,
							thumbUrl: `${process.env.REACT_APP_API_URL}storage/projectdocument/${image?.document_name}`,
						}
					}
				),
			})
			details?.relation_with_fine_aggregate_test_required &&
				details?.relation_with_fine_aggregate_test_required.map(
					(field: any) => {
						form.setFieldValue(
							[
								'test_types',
								field.mst_test_type_id,
								'mst_test_type_id',
							],
							field.id
						)
						form.setFieldValue(
							[
								'test_types',
								field.mst_test_type_id,
								'test_type_value',
							],
							field.test_type_value
						)
					}
				)
			setState(prevState => !prevState);
		}
	}, [FINE_AGGREGATE.read, fineAgid, setState])

	const onFinish = (data: any) => {

		if (data.test_types) {
			data.test_types = JSON.stringify(Object.values(data.test_types));
		}

		const formData = dataToFormDataConverter(data)
		try {
			setLoading(true)
			setDisabled(true)
			dispatch(createRecord(Number(localStorage.getItem('projectId')), formData))
				.then(() => {
					setLoading(false)
					setDisabled(false)
					dispatch(setTab(3))
					form.resetFields()
					return navigate(`/projects/raw-material`)
				})
				.catch((error: any) => {
					setLoading(false)
					setDisabled(false)
					assignErrorToInput(form, error?.STATUS)
				})
		} catch (error) {
			console.error('Error submitting data:', error)
		}
	}

	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	const handleCancel = () => {
		dispatch(setTab(3))
		navigate(`/projects/raw-material`)
	}

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname.includes(
						`/projects/raw-material/add-fine-aggregate`
					)
						? AddFineAggregateBreadcrumb(
							'Add Fine Aggregate'
						)
						: AddFineAggregateBreadcrumb(
							'View Fine Aggregate'
						)
				}
			></ToolBar>

			<Form
				form={form}
				onFinish={onFinish}
				onValuesChange={validateForm}
				className="formLayout"
			>
				<div className="formHeading">
					<h3>
						{' '}
						{location.pathname.includes(
							`/projects/raw-material/add-fine-aggregate`
						)
							? 'Add Fine Aggregate'
							: 'View Fine Aggregate'}
					</h3>
				</div>
				<div className="addSteelForm">
					<Row gutter={40}>
						<Col span={12}>
							<InputBox.Text
								label="Source"
								required={true}
								rules={rules.dynamicFields()}
								name="source"
								disabled={fineAgid ? true : false}
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								label="Total CMT"
								rules={rules.numberFields()}
								name="total_cmt"
								disabled={fineAgid ? true : false}
							/>
						</Col>
						<Col span={12} className="uploadRow">
							<InputBox.Upload
								name="document_name"
								required={false}
								label="Royalty Pass "
								fileList={form.getFieldValue('document_name')}
								maxCount={10}
								disabled={
									location.pathname.includes("/view-fine-aggregate")
								}
							/>
						</Col>
					</Row>
				</div>

				<TableComponent form={form} />

				<div className="steelBtn">
					<Button
						htmlType="submit"
						loading={loading}
						disabled={disabled}
						className="secondaryBtn"
					>
						Submit
					</Button>
					<Button
						className="secondaryBtn cancelBtn"
						onClick={handleCancel}
					>
						Cancel
					</Button>
				</div>
			</Form>
		</>
	)
}

export default AddFine
