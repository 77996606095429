import React from 'react'
import AgGridWrapper from '../../../../../../../components/AgGridWrapper/AgGridWrapper'
import { setUpGrid } from "../utils/slice";
import columnDefs from './columnDefs'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/app';

const Listing: React.FC<any> = ({
	rowData,
	onRowClick
}) => {
	const { BINDING_WIRE } = useSelector((state: RootState) => state);
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onRowClicked={onRowClick}
				rowData={rowData}
				columnDefs={columnDefs}
				onGridReadyWithDispatch={setUpGrid}
				paginationPageSize={BINDING_WIRE.perPageSize}
				cacheBlockSize={BINDING_WIRE.perPageSize}
				rowSelection={"single"}
			/>
		</>
	)
}

export default Listing
