import { createSlice } from "@reduxjs/toolkit";
import type { Action, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../../../../../store/api";
import { AppThunk, RootState } from "../../../../../../../store/app";
import { apiUrls } from "./apiUrls";
import Config, { getServerListPayload } from "../../../../../../../config/Config";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { Dispatch } from "react";

interface InitialState {
	getBindingWireDropdown: any;
	isLoading: boolean
	rowData: any
	reportRowData: any
	read: any
	readReport: any
	testTypeList: any
	perPageSize: any
	reportPerPageSize: any
	bindingWireTestingNameList: any
	readResetBindingWire: any
	grid: any,
	reportId: any
	location: any
	bindingReport: any
	id: any,
	bindingId: any
	nameLaboratoryList: any
	dateRange:any

}

const initialState = {
	isLoading: false,
	rowData: null,
	reportRowData: null,
	read: null,
	readReport: null,
	testTypeList: null,
	perPageSize: Config.grid.server.gridOptions?.paginationPageSize,
	reportPerPageSize: Config.grid.server.gridOptions?.paginationPageSize,
	bindingWireTestingNameList: null,
	readResetBindingWire: null,
	grid: null,
	reportId: null,
	location: null,
	bindingReport: null,
	id: null,
	bindingId: null,
	nameLaboratoryList: null,
	dateRange:null
} as InitialState

const BindingWire = createSlice({
	name: "BINDING_WIRE",
	initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		success: (state) => {
			state.isLoading = false;
		},
		failure: (state) => {
			state.isLoading = false;
		},

		setRowData: (state, action: PayloadAction<any>) => {
			state.rowData = action?.payload
		},
		setPerPageSize: (state, action: PayloadAction<any>) => {
			state.perPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.perPageSize))
				state.grid.api.setCacheBlockSize(state.perPageSize)
			}
		},
		setReportPerPageSize: (state, action: PayloadAction<any>) => {
			state.reportPerPageSize = action.payload
			if (state.grid) {
				state.grid.api.paginationSetPageSize(Number(state.reportPerPageSize))
				state.grid.api.setCacheBlockSize(state.reportPerPageSize)
			}
		},
		setGrid: (state, action: PayloadAction<any>) => {
			state.grid = action.payload
		},
		setRead: (state, action: PayloadAction<any>) => {
			state.read = action?.payload
		},
		setTestTypeList: (state, action: PayloadAction<any>) => {
			state.testTypeList = action?.payload
		},
		setReportRowData: (state, action: PayloadAction<any>) => {
			state.reportRowData = action?.payload;
		},
		setReadReport: (state, action: PayloadAction<any>) => {
			state.readReport = action.payload
		},
		setBindingWireTestingNameList: (state, action: PayloadAction<any>) => {
			state.bindingWireTestingNameList = action?.payload;
		},
		setResetReadReport: (state) => {
			state.readReport = initialState
		},
		setReadResetBindingWire: (state) => {
			state.read = initialState
		},
		setReportId: (state, action: PayloadAction<any>) => {
			state.reportId = action?.payload
		},
		setLocation: (state, action: PayloadAction<any>) => {
			state.location = action?.payload
		},
		setBindingReportId: (state, action: PayloadAction<any>) => {
			state.bindingReport = action?.payload
		},
		setId: (state, action: PayloadAction<any>) => {
			state.id = action?.payload
		},
		setBindingId: (state, action: PayloadAction<any>) => {
			state.bindingId = action?.payload
		},
		setNameLaboratoryList: (state, action: PayloadAction<any>) => {
			state.nameLaboratoryList = action?.payload
		},
		setDateRange: (state, action: PayloadAction<any>) => {
			state.dateRange = action?.payload
		}
	},
});

export const {
	start,
	success,
	failure,
	setRowData,
	setRead,
	setPerPageSize,
	setReportPerPageSize,
	setGrid,
	setTestTypeList,
	setReportRowData,
	setReadReport,
	setBindingWireTestingNameList,
	setResetReadReport,
	setReadResetBindingWire,
	setReportId,
	setLocation,
	setBindingReportId,
	setId,
	setBindingId,
	setNameLaboratoryList,
	setDateRange,
} = BindingWire.actions


export const setPerPage = (size: number): AppThunk<any> =>
	async (dispatch, getState: () => RootState) => {
		getState().BINDING_WIRE.location != '/binding-wire-report' ?
			dispatch(setPerPageSize(size)) :
			dispatch(setReportPerPageSize(size))
		dispatch(setUpGrid(getState().BINDING_WIRE.grid))
	}

const fetchList = async (id: any, payload?: any): Promise<any> => {
	return await api.post(apiUrls.list(id), payload).then(({ data }) => {
		return data;
	});
};

export const setUpGrid = (params: GridReadyEvent): AppThunk<any> =>
	async (dispatch, getState: () => RootState) => {
		try {
			dispatch(setGrid(params))
			const data = await createDataSource(dispatch, getState, Config.grid.server.gridOptions)
			params.api.setServerSideDatasource(data)
		}
		catch (error: any) {
			//
		}
	}

const changeFilterAndSort = (params: any, dispatch: any, getState: () => RootState) => {
	params
	getState().BINDING_WIRE.location != '/binding-wire-report' ?
		dispatch(setBindingId(null)) :
		dispatch(setBindingReportId(null))

}

const createDataSource = (dispatch: Dispatch<Action>, getState: () => RootState,
	gridOptions?: GridOptions) => {
	return {
		gridOptions,
		getRows: (params: IServerSideGetRowsParams) => {
			changeFilterAndSort(params.request, dispatch, getState)
			const payload = getServerListPayload(params)
			const reportPayload = {
				...payload,
				start_date: getState().BINDING_WIRE.dateRange?.start_date,
				end_date:  getState().BINDING_WIRE.dateRange?.end_date ,
			}
			getState().BINDING_WIRE.location != '/binding-wire-report' ?
				fetchList(getState().BINDING_WIRE.id, payload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count })
					if (data.count <= 0) {
						params.api.showNoRowsOverlay()
					}
					else {
						params.api.hideOverlay()
					}
				}) : fetchReportList(getState().BINDING_WIRE.reportId, reportPayload).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count })
					if (data.count <= 0) {
						params.api.showNoRowsOverlay()
					}
					else {
						params.api.hideOverlay()
					}
				})
		}
	}
}

export const createRecord =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.create(id), action);
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().BINDING_WIRE.grid))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		}
export const read =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.read(id));
				dispatch(setRead(response.data.data));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};

const fetchReportList = async (id: any, payload?: any): Promise<any> => {
	const f_payload = { ...payload, ...id }
	return await api.post(apiUrls.reportList, f_payload).then(({ data }) => {
		return data
	})
}

export const createReport =
	(id: number, action: any): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.createReport(id), action);
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().BINDING_WIRE.grid))
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};
export const resetRead = (): AppThunk<any> => (dispatch) => {
	try {
		dispatch(start());
		dispatch(setRead(null));
		dispatch(success());
		return Promise.resolve();
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};
export const readReport =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				const response = await api.post(apiUrls.readReport(id));
				dispatch(setReadReport(response.data.data[0]));
				return Promise.resolve(response.data);
			} catch (error: any) {
				return Promise.reject(error.data);
			}
		};
export const getBindingWireType =
	(id: number): AppThunk<any> =>
		async (dispatch) => {
			try {
				dispatch(start())
				const response = await api.post(apiUrls.testType(id))
				dispatch(setTestTypeList(response?.data?.data))
				dispatch(success())
				return Promise.resolve(response.data.data)
			}
			catch (error: any) {
				dispatch(failure()
				)
				return Promise.reject(error.data)
			}
		};


export const getBindingWireDropdown = (): AppThunk<any> => async (dispatch) => {
	try {
		dispatch(start());
		const response = await api.get(apiUrls.getBindingWireDropdown);
		dispatch(setBindingWireTestingNameList(response.data.data));
		dispatch(success());
		return Promise.resolve(response.data);
	} catch (error: any) {
		dispatch(failure());
		return Promise.reject(error.data);
	}
};

export const getNameOfLaboratory = (labId:any): AppThunk<any> =>
	async (dispatch) => {
		try {
			dispatch(start())
			const response = await api.get(apiUrls.nameOfLaboratory)
			const laboratoryName = response.data.data?.filter((item?: any) => {
				if(item?.laboratory_type == labId){
					return item
				}
			});
			dispatch(setNameLaboratoryList(laboratoryName))
			dispatch(success())
			return Promise.resolve(response.data.data)
		} catch (error: any) {
			dispatch(failure())
			return Promise.reject(error)
		}
	}

export const deleteRecord =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.delete, { id: id, project_id: getState().BINDING_WIRE.id, type: parseInt(getState().RAWMATERIAL.tab) + 1 });
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().BINDING_WIRE.grid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

export const deleteReport =
	(id: number): AppThunk<any> =>
		async (dispatch, getState) => {
			try {
				dispatch(start());
				const response = await api.post(apiUrls.deleteReport, { id: id, project_id: getState().BINDING_WIRE.id, type: parseInt(getState().RAWMATERIAL.tab) + 1 });
				dispatch(success(response.data));
				dispatch(setUpGrid(getState().BINDING_WIRE.grid));
				return Promise.resolve(response.data);
			} catch (error: any) {
				dispatch(failure(error.data));
				return Promise.reject(error.data);
			}
		};

		export const getDateRangeData = (): AppThunk<any> =>
	async (dispatch, getState) => {

		dispatch(setUpGrid(getState().BINDING_WIRE.grid))
	}

const BindingWireSliceReducer = BindingWire.reducer;
export default BindingWireSliceReducer;
