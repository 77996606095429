import React, { useEffect } from 'react'
import { Form, Button, Row, Col } from 'antd'
import { InputBox } from '../../../../components/AntdAddons'
import { PolymerSlurry } from '../../../../config/BreadcrumbConfig'
import ToolBar from '../../../../components/ToolBar'
import { useNavigate, useParams } from 'react-router-dom'
import { rules } from './rules'
import { createRecord, getDynamicPolymerSlurryData, setDynamicPolymerSlurryData } from '../utils/slice'
import { useDispatch, useSelector } from 'react-redux'
import { assignErrorToInput } from '../../../../store/api'
import { AppDispatch, RootState } from '../../../../store/app'
import { structureElementBreadcrumbName, structureElementBreadcrumbUrl, validateFields } from '../../../../config/Global'

const AddPolymerSlurry: React.FC<any> = () => {
	const [form] = Form.useForm()
	const dispatch: AppDispatch = useDispatch()
	const { POLYMER_SLURRY } = useSelector((state: RootState) => state)
	const navigate = useNavigate()
	const { mappingId, structureId, mName } = useParams()
	const pileNo = localStorage.getItem('pileNo');
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const name =structureElementBreadcrumbName(structureId)
	const endUrl =structureElementBreadcrumbUrl(structureId)
	const pro_id: any = localStorage.getItem('projectId')

	const handleCancel = () => {
		navigate(`/projects/${structureId}/${mName}`)
		dispatch(setDynamicPolymerSlurryData(null))
	}

	const validateForm = () => {
		validateFields(form, setDisabled)
	}

	const handleSubmit = (data?: any) => {
		delete data?.expected_value
		const payload = {
			observed_data: Object.values(data?.observed_data),
			project_id: pro_id,
		}
		dispatch(createRecord(parseInt(mappingId ?? ''), payload))
			.then(() => {
				setDisabled(true)
				dispatch(getDynamicPolymerSlurryData(mappingId))
			})
			.catch((error: any) => {
				assignErrorToInput(form, error?.STATUS)
				setDisabled(false)
			})
	}

	useEffect(() => {
		dispatch(getDynamicPolymerSlurryData(mappingId))
	}, [getDynamicPolymerSlurryData, mappingId])

	useEffect(() => {
		const data = POLYMER_SLURRY?.dynamicPolymerSlurryData?.observed_data
		if (data) {
			data && data?.map((item: any) => {
				form.setFieldValue([`observed_data`, `${item?.id}`, "test_type_id"], item?.id)
				form.setFieldValue([`expected_value`, `${item?.id}`], item?.expected_value)
				form.setFieldValue([`observed_data`, `${item?.id}`, "observed_value"], item?.observed_value ? item?.observed_value : "")
			})
		}
	}, [POLYMER_SLURRY?.dynamicPolymerSlurryData])

	return (
		<>
			<ToolBar
				breadcrumbs={
					location.pathname ==
						`/projects/${structureId}/${endUrl}`
						? PolymerSlurry(pro_id, structureId, name, endUrl)
						: PolymerSlurry(pro_id, structureId, name, endUrl)
				}
			>
                     <div className="diableBtnWrap"></div>
            </ToolBar>
			<div className="formHeading">
				<h3>Polymer Slurry</h3>
			</div>
			<Form
				form={form}
				className="addReportForm cubeForm"
				onFinish={handleSubmit}
				onValuesChange={validateForm}
			>
				<Row style={{ marginBottom: "1rem" }}>
					<Col span={12}>
						<span className="pileNumber">
							Pile No. - {`${pileNo}`}
						</span>
					</Col>
				</Row>
				<Row>
					<table className='polymerTable'>
						<thead>
							<tr className='headRow'>
								<th className='headCol'></th>
								<th className='headRight'></th>
								<th className='headCol'><h2>Observed Value</h2></th>
								<th className='headRight'></th>
								<th className='headCol'><h2>Expected Value</h2></th>
							</tr>
						</thead>
						<tbody>
							{
								POLYMER_SLURRY?.dynamicPolymerSlurryData?.observed_data && POLYMER_SLURRY?.dynamicPolymerSlurryData?.observed_data?.map((item: any) => {
									form.setFieldValue([`expected_value`, `${item?.id}`], item?.expected_value ? item?.expected_value : "N/A")
									form.setFieldValue([`observed_data`, `${item?.id}`, "test_type_id"], item?.id)

									return <>
										<tr>
											<td className='tableLabel' data-label="pH Value"><h4>{item?.name}</h4></td>
											<td className='headRight'></td>
											<InputBox.Text
												name={[`observed_data`, `${item?.id}`, "test_type_id"]}
												hidden
											/>
											<td className='paddingRow'>
												<InputBox.Text
													name={[`observed_data`, `${item?.id}`, "observed_value"]}
													rules={rules.dynamicFields()}
													disabled={item?.observed_value?true:false}
												/></td>
											<td className='headRight'></td>
											<td className='paddingRow'>
												<InputBox.Text
													name={[`expected_value`, `${item?.id}`]}
													disabled={true}
													value={item?.expected_value}
												/>
											</td>

										</tr>
									</>
								})
							}
						</tbody>
					</table>
				</Row>
				<Row justify="end" style={{ margin: "5rem 10rem" }}>
					<div
						className="ReportBtn"
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						{!POLYMER_SLURRY?.dynamicPolymerSlurryData?.data && (
							<Button
								className="secondaryBtn"
								htmlType="submit"
								disabled={disabled}
							>
								Submit
							</Button>
						)}

						<Button
							className="secondaryBtn cancelBtn"
							htmlType="submit"
							onClick={handleCancel}
						>
							Cancel
						</Button>
					</div>
				</Row>
			</Form>
		</>
	)
}

export default AddPolymerSlurry
